/** @format */
import { LoadingIndicator } from 'blackbird/components/common/loading-indicator/LoadingIndicator';
import classNames from 'classnames';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import * as React from 'react';
import {
  findKey,
  findLastIndex,
  isNumber,
  last,
  omit,
  throttle,
} from 'underscore';
const logger = RequestErrorHandler('image');

interface Props extends React.ImgHTMLAttributes<unknown> {
  containerClasses?: string;
  placeholder?: string;
  small?: boolean;
  log?: boolean;
  onError?: (error: any) => void;
}

export const Img = React.memo<Props>(
  ({ small, containerClasses, placeholder, log, ...props }) => {
    const isCached = React.useMemo(() => {
      if (!props.src) {
        return false;
      } else {
        const imageCache = new Image();
        imageCache.src = props.src;
        return imageCache.complete;
      }
    }, [props.src]);

    const [isLoaded, setIsLoaded] = React.useState(isCached);
    const [isError, setIsError] = React.useState(false);

    const handleLoad = React.useCallback(
      async (e) => {
        const img = e.target;

        try {
          if (img.decode) {
            await img.decode();
          }
          setIsLoaded(true);
        } catch (decodeError) {
          setIsError(true);
          if (log)
            logger(
              {
                severity: 'info',
                messageKey: null,
                extraData: { src: e.currentTarget.src },
              },
              props.onError,
            )(decodeError);
        }
      },
      [log, props.onError],
    );

    const handleError = React.useCallback<
      React.ReactEventHandler<HTMLImageElement>
    >(
      (e) => {
        if (log && navigator.onLine) {
          logger(
            {
              severity: 'info',
              messageKey: null,
              rollbarMessage: 'could not load image',
            },
            props.onError,
          )({
            url: e.currentTarget.src,
          });
        }
        setIsError(true);
      },
      [log, props.onError],
    );

    return (
      <div className={classNames(containerClasses, 'block w-full relative')}>
        {isError ? (
          placeholder && <img {...props} src={placeholder} />
        ) : (
          <img
            data-hj-suppress={true}
            onError={handleError}
            onLoad={handleLoad}
            {...props}
          />
        )}
        <LoadingIndicator
          bg
          fill
          show={!isLoaded && !isError}
          className={classNames(small ? 'w-4' : 'w-10')}
        />
      </div>
    );
  },
);

Img.displayName = 'Img';
export default Img;

export interface AdaptiveImageSources {
  0: string;
  [minWidth: number]: string;
}
export const SmartImg: React.FC<
  {
    sources: AdaptiveImageSources;
    width: number;
    height: number;
    loading: 'eager' | 'lazy';
    estimatedWidth?: number;
  } & Props
> = ({ containerClasses, placeholder, log, ...props }) => {
  const saveData: boolean = (navigator as any).connection?.saveData ?? false;
  const [sources, setSources] = React.useState(() => {
    const encountered: string[] = [];
    return omit(props.sources, (i) => {
      if (encountered.indexOf(i) >= 0) return true;
      encountered.push(i);
      return false;
    });
  });

  /** Allow shortcuts when we already know the dimensions */
  const getSourceForSize = React.useCallback(
    (size?: number, from = sources): string | undefined => {
      if (!size) return;
      const breakpoints = Object.keys(sources);
      const index = saveData
        ? 0
        : findLastIndex(breakpoints, (i) => size > parseInt(i));

      return from[String(index)] ?? last(Object.values(from));
    },
    [saveData, sources],
  );

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [currentSource, setCurrentSource] = React.useState<string | undefined>(
    props.loading === 'eager' || saveData
      ? sources[0]
      : getSourceForSize(props.estimatedWidth),
  );
  const ref = React.useRef<HTMLImageElement>(null);

  const handleResize = React.useCallback(
    throttle(
      (tries = 0) => {
        if (!ref.current) return;
        const hadNoWidth =
          !ref.current.clientWidth && !ref.current?.style.width;

        if (hadNoWidth) ref.current!.style.width = '100%';
        const width = (ref.current?.clientWidth ?? 0) * window.devicePixelRatio;

        setCurrentSource(getSourceForSize(width, sources));
        if (hadNoWidth) ref.current.style.removeProperty('width');
      },
      200,
      { leading: true, trailing: true },
    ),
    [sources, saveData],
  );

  React.useEffect(() => {
    if (isNumber(props.estimatedWidth)) {
      const newSource = getSourceForSize(props.estimatedWidth);
      setCurrentSource(newSource);
      return;
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [getSourceForSize, handleResize, props.estimatedWidth, sources]);

  const handleError = React.useCallback(
    (e) => {
      const breakpointKey = findKey(sources, (i) => {
        return (
          i?.replace(/\/$/, '') === e.currentTarget.src?.replace(/\/$/, '')
        );
      });
      props.onError?.(e);
      if (!breakpointKey || Object.keys(sources).length === 1) {
        logger({
          messageKey: null,

          rollbarMessage: 'ran out of viable sources while loading image',
          extraData: e.currentTarget.src,
        })(e);
        setCurrentSource(placeholder);
      } else {
        const newSources = omit(sources, breakpointKey);
        logger({
          messageKey: null,
          rollbarMessage: 'Error loading source, removing trying another',
          severity: 'warn',
          extraData: e.currentTarget.src,
        })(e);
        setSources(newSources as any);
      }
    },
    [placeholder, props, sources],
  );

  const handleLoad = React.useCallback(
    async (e) => {
      const img = e.target;

      try {
        if (img.decode) {
          await img.decode();
        }
        setIsLoaded(true);
      } catch (decodeError) {
        setIsError(true);
        if (log)
          logger(
            {
              severity: 'info',
              messageKey: null,
              extraData: { src: e.currentTarget.src },
            },
            props.onError,
          )(decodeError);
      }
    },
    [log, props.onError],
  );

  const propsToUse = omit(props, 'sources', 'sizes', 'src', 'estimatedWidth');
  return (
    <div className={classNames(containerClasses, 'block w-full relative')}>
      {isError ? (
        placeholder && <img {...propsToUse} src={placeholder} />
      ) : (
        <img
          {...propsToUse}
          ref={ref}
          src={currentSource}
          data-hj-suppress={true}
          onError={handleError}
          onLoad={handleLoad}
        />
      )}
      <LoadingIndicator
        bg
        fill
        show={!isLoaded && !isError}
        // className={classNames(small ? 'w-4' : 'w-10')}
      />
    </div>
  );
};
