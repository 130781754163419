/** @format */
import React, { useContext } from 'react';
import Select from 'blackbird/components/form/select/Select';
import { BoordsAiContext } from 'blackbird/helpers/contexts/BoordsAiContext';
import { type Option } from '../common/types';

export const GeneratorImageCount: React.FC = () => {
  const { imageCount, setImageCount } = useContext(BoordsAiContext);

  const isTrialing =
    BoordsConfig.StoryboardTeamPlanSlug === 'trialing' ||
    (BoordsConfig.PlanSlug === 'trialing' &&
      !BoordsConfig.StoryboardTeamPlanSlug);

  const maxFreeImages = 2;

  const baseOptions: Option[] = [
    {
      label: 'None',
      value: 0,
      disabled: false,
    },
  ];

  const countOptions: Option[] = Array.from({ length: 6 }, (_, i) => {
    const count = i + 1;
    const isDisabled =
      i > maxFreeImages - 1 && isTrialing && !BoordsConfig.HasPurchasedCredits;
    return {
      label: count,
      value: count,
      disabled: isDisabled,
    };
  });

  const options = [...baseOptions, ...countOptions];

  return (
    <Select
      disableInput
      size="md"
      label={`Images to Generate`}
      selectContainerClassName="w-full"
      options={options}
      value={imageCount as any}
      onChange={(value: string) => {
        setImageCount(parseInt(value, 10));
      }}
    />
  );
};
