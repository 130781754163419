/** @prettier */
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const Container = require('../shared/Container').default;
const HoverControls = require('../shared/HoverControls');
const { FramePanelBar } = require('../shared/FramePanelBar');
const monitorScreenSize = require('../shared/screenSizeMonitorHOC');
const ShareableMobileLayout = require('./ShareableMobileLayout.react');
const { FrameViewer } = require('../player/FrameViewer');
const loadScreenfull = require('../../helpers/load-screenfull');
const preloadAllImages = require('../../helpers/preload-frame-images');
const navigateToRoute = require('../../helpers/router/navigate-to-route.js');
const { ShareableGrid } = require('./frames/ShareableGrid');
const { PanelBarLayout } = require('../shared/PanelBarLayout');
const { PlayerActions } = require('../../flux/actions/player');
const { FramePanelBarNavigator } = require('../shared/FramePanelBarNavigator');
const Player = require('../player/Container');
const {
  AnnotationOverlay,
} = require('blackbird/components/comments/annotations/AnnotationOverlay');
import { CloseButton } from 'blackbird/components/common/CloseButton';
import { idleTimeout } from 'javascripts/helpers/idle-timeout';
import { AutoSizedFrame } from 'javascripts/components/AutoSizedFrame';

const {
  PresentationHeader,
} = require('blackbird/components/headers/presentation/PresentationHeader');

window.ShareableContainer = monitorScreenSize(
  'shareable',
  980,
  'fullUI',
)(
  Container(['shareable', 'frameStore'], (storeData, ownProps) => ({
    fetch: () => {
      ShareableActions.fetch.defer({
        hashid: ownProps.storyboardHashid,
        storyboard_id: ownProps.storyboard_id,
      });
      idleTimeout(() => {
        CoverpageActions.fetchIfNecessary.defer(ownProps.storyboard_id);
      });
    },
    onChangeView: ShareableActions.updateView,
    onSetIndex: (index) => {
      ShareableActions.setActiveIndex(index);
      if (storeData.shareable.view === 'zoom') {
        navigateToRoute(
          storeData.shareable.view,
          { slug: ownProps.storyboardHashid, frameIndex: index + 1 },
          true,
        );
      }
    },
    onSetFullscreen: ShareableActions.setFullscreen,
    onClosePlayer: () => {
      ShareableActions.updateView('grid');
      navigateToRoute('shareable', { slug: ownProps.storyboardHashid });
    },
    onSetFrame: (frame) =>
      ShareableActions.setActiveIndex(
        storeData.frameStore.frames.indexOf(frame),
      ),
  }))(
    createReactClass({
      displayName: 'ShareableContainer',

      propTypes: {
        fetch: PropTypes.func.isRequired,
        onChangeView: PropTypes.func.isRequired,
        onSetIndex: PropTypes.func.isRequired,
        onSetFrame: PropTypes.func.isRequired,
        canManage: PropTypes.bool.isRequired,
        fullUI: PropTypes.bool.isRequired,
        onSetFullscreen: PropTypes.func.isRequired,
        onClosePlayer: PropTypes.func.isRequired,

        frameStore: PropTypes.shape({
          is_saving: PropTypes.bool.isRequired,
          frames: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
            .isRequired,
        }),

        shareable: PropTypes.shape({
          storyboard: PropTypes.object,
          activeIndex: PropTypes.number.isRequired,
          fetched: PropTypes.bool.isRequired,
          view: PropTypes.string.isRequired,
          isFullscreen: PropTypes.bool.isRequired,
        }).isRequired,
      },
      moveToShareableView() {
        navigateToRoute('shareable', {
          slug: this.props.shareable.storyboard.permaslug,
        });
      },
      componentDidMount() {
        this.props.fetch();

        window.Mousetrap.bind('s', () => {
          if (this.props.shareable.view === 'grid') {
            navigateToRoute('zoom', {
              frameIndex: this.props.shareable.activeIndex,
              slug: this.props.shareable.storyboard.permaslug,
            });
          } else {
            navigateToRoute('shareable', {
              slug: this.props.shareable.storyboard.permaslug,
            });
          }
        })
          .bind('left', this.handlePreviousFrame)
          .bind('right', this.handleNextFrame)
          .bind('esc', this.moveToShareableView)
          .bind('f', () => {
            this.toggleFullscreen(!this.props.shareable.isFullscreen);
          });

        loadScreenfull(() => {
          screenfull.onchange(() => {
            // If we're in the player view, we don't want to manage fullscreen
            // it's own view of responsible for that
            if (this.props.shareable.view !== 'player') {
              this.props.onSetFullscreen(screenfull.isFullscreen);
            }
          });
        });
      },

      has_set_page_title: false,

      toggleFullscreen(isFullscreen) {
        const screenfullIsLoaded = typeof screenfull !== 'undefined';
        const currentStateMatchesNew = screenfullIsLoaded
          ? screenfull.isFullscreen === isFullscreen
          : this.props.shareable.isFullscreen === isFullscreen;

        if (currentStateMatchesNew) return;

        // Check if screenfull is present, because it's not supported on iPad
        if (screenfullIsLoaded) {
          if (isFullscreen) {
            screenfull.request(this.refs.fullScreen);
          } else {
            screenfull.exit();
          }
        } else {
          this.props.onSetFullscreen(isFullscreen);
        }
      },
      handleClose() {
        this.moveToShareableView();
      },
      componentDidUpdate(prevProps) {
        const storyboard = this.props.shareable.storyboard;
        const isFullscreen = this.props.shareable.isFullscreen;
        // Set the page title for those coming in via a password
        if (!this.has_set_page_title && storyboard) {
          this.has_set_page_title = true;
          document.title = storyboard.document_name + ' · Boords';
        }

        if (!prevProps.shareable.isFullscreen && isFullscreen) {
          this.toggleFullscreen(isFullscreen);
        }

        if (
          !prevProps.frameStore.frames &&
          this.props.frameStore.frames &&
          this.props.fullUI
        ) {
          preloadAllImages(this.props.frameStore.frames);
        }

        if (
          prevProps.shareable.activeIndex !==
            this.props.shareable.activeIndex &&
          this.props.shareable.view === 'player'
        ) {
          PlayerActions.goToFrame.defer(
            this.props.frameStore.frames[this.props.shareable.activeIndex],
          );
        }
      },
      handleRemoveBranding() {
        CoverpageActions.updateValue({
          attr: 'theme.has_watermark',
          value: false,
        });
        CoverpageActions.save.defer();
      },

      handleNextFrame() {
        this.props.onSetIndex(this.props.shareable.activeIndex + 1);
      },

      handlePreviousFrame() {
        this.props.onSetIndex(this.props.shareable.activeIndex - 1);
      },

      render: function () {
        const { storyboard, activeIndex, fetched } = this.props.shareable;
        const view = this.props.fullUI ? this.props.shareable.view : 'mobile';
        const hasBanner = document.body.className.indexOf('has--banner') > 0;
        const activeFrame = this.props.frameStore.frames[activeIndex];
        let showSidebar;
        let mainComponent;

        if (this.props.shareable.isFullscreen) {
          return (
            <div
              className="relative flex flex-col w-100 vh100 py-9"
              style={{ background: 'black' }}
              ref="fullScreen"
            >
              <div className="relative flex-auto">
                <FrameViewer
                  frame={activeFrame}
                  frameAspectRatio={storyboard.frame_aspect_ratio}
                  onClick={this.handleNextFrame}
                  isSubtitlesEnabled={false}
                  framesAreSaving={this.props.frameStore.is_saving}
                  subtitleSource={storyboard.preferences.subtitles_from}
                />
              </div>

              <HoverControls className="fixed top-0 left-0 p-4">
                <CloseButton
                  customBackground
                  className="hover:bg-white bg-white/60"
                  onHandleCancel={() => this.toggleFullscreen(false)}
                />
              </HoverControls>
            </div>
          );
        }

        if (!fetched) {
          return <FrameOverlayMessage title="Fetching your storyboard..." />;
        }

        if (storyboard.frames.length === 0) {
          mainComponent = (
            <div className="flex items-center justify-center w-full min-h-vp">
              <div className="max-w-lg text-center text-type-subdued font-base">
                This storyboard doesn't have any frames yet
              </div>
            </div>
          );
        } else if (
          !this.props.shareable.fetched ||
          !this.props.frameStore.frames
        ) {
          mainComponent = (
            <FrameOverlayMessage title="Loading your storyboard frames..." />
          );
        } else if (view === 'grid') {
          const zoomType = storyboard.preferences.share_as_animatic
            ? 'player'
            : 'zoom';

          mainComponent = (
            <ShareableGrid
              zoomType={zoomType}
              storyboard={storyboard}
              frames={this.props.frameStore.frames}
            />
          );
        } else if (view === 'zoom' && activeFrame) {
          mainComponent = (
            <div className="relative flex flex-col flex-auto p-6 bg-surface">
              <CloseButton
                customBackground
                className="bg-white hover:bg-white/60"
                onHandleCancel={this.handleClose}
              />

              <div className="flex-auto flex items-center justify-center my-8 h-full">
                <AutoSizedFrame
                  frameAspectRatio={storyboard.frame_aspect_ratio}
                  border
                >
                  {(sizes) => (
                    <>
                      <FrameViewer
                        frame={activeFrame}
                        frameAspectRatio={storyboard.frame_aspect_ratio}
                        onClick={this.handleNextFrame}
                        isSubtitlesEnabled={false}
                        framesAreSaving={this.props.frameStore.is_saving}
                        subtitleSource={storyboard.preferences.subtitles_from}
                        size={sizes}
                        border={false}
                      />

                      <AnnotationOverlay
                        key={activeFrame.id}
                        currentFrameId={activeFrame.id}
                        frameAspectRatio={storyboard.frame_aspect_ratio}
                        sizes={sizes}
                        passThrough
                      />
                    </>
                  )}
                </AutoSizedFrame>
              </div>
            </div>
          );
          showSidebar = true;
        } else if (view === 'player') {
          return (
            <div className="relative bg-surface">
              <Player
                onChangeView={this.props.onChangeView}
                onSetIndex={this.props.onSetIndex}
                onSetFrame={this.props.onSetFrame}
                hasSidebar={true}
                onTriggerClose={this.props.onClosePlayer}
                framesAreSaving={this.props.frameStore.is_saving}
                canManage={this.props.canManage}
                theme="dark"
              />
            </div>
          );
        } else if (view === 'mobile') {
          return (
            <ShareableMobileLayout
              storyboard={this.props.shareable.storyboard}
              frames={this.props.frameStore.frames}
              onSetIndex={this.props.onSetIndex}
              activeIndex={activeIndex}
              canManage={this.props.canManage}
              hasBanner={hasBanner}
            />
          );
        }

        return storyboard ? (
          <PanelBarLayout
            mainComponent={mainComponent}
            headerPadding={null}
            headerComponent={
              view !== 'player' && (
                <PresentationHeader
                  onDisableMadeWithBoords={this.handleRemoveBranding}
                  storyboard={storyboard}
                  canManage={this.props.canManage}
                  onFullscreenClick={this.props.onSetFullscreen}
                  showCount={
                    storyboard.preferences.share_with_word_count ?? false
                  }
                />
              )
            }
            sidebarComponent={
              showSidebar && (
                <div className="flex flex-col flex-auto">
                  <div className="h-[70px] p-6 border-b border-b-border z-50 sticky top-0 bg-white">
                    <FramePanelBarNavigator
                      frames={this.props.frameStore.frames}
                      onSetIndex={this.props.onSetIndex}
                      activeIndex={activeIndex}
                      showFrameStatus={true}
                    />
                  </div>

                  <div className="flex flex-col flex-auto pt-8">
                    <FramePanelBar
                      storyboard={this.props.shareable.storyboard}
                      frames={this.props.frameStore.frames}
                      onSetIndex={this.props.onSetIndex}
                      activeIndex={activeIndex}
                      canManage={this.props.canManage}
                      showFrameStatus={
                        !!storyboard.preferences.share_with_frame_status
                      }
                      restrictWidth
                      scroll
                    />
                  </div>
                </div>
              )
            }
          />
        ) : null;
      },
    }),
  ),
);
