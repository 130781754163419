/** @format */
import * as React from 'react';
import { omit } from 'underscore';
import TextInput from '../form/text-input/TextInput';

const PasswordField = React.lazy(() => import('./PasswordField'));

(window as any).PasswordFieldGlobal = (props: any) => (
  <React.Suspense
    fallback={<TextInput {...omit(props, 'showCriteriaOnLoad')} disabled />}
  >
    <PasswordField {...props} />
  </React.Suspense>
);
