/** @prettier */
import React, { useRef, useState, useEffect } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import { type OnboardingProps, OnboardingType } from './Onboarding';
import { Trans, useTranslation } from 'react-i18next';
import { type SubmitHandler, useForm } from 'react-hook-form';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import LogoGoogle from 'blackbird/images/logo/google-vector.svg';
import { SignupLogos } from './SignupLogos';
import { OnboardingFormWrapper } from './OnboardingFormWrapper';
import classNames from 'classnames';
import { TrialPlansWrapped } from 'blackbird/components/trial/TrialPlans';
import { CookieDeleteService } from 'javascripts/helpers/cookie-service';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';
import { Timeline } from 'blackbird/components/trial/TrialStart';
import { checkPassword } from 'blackbird/components/common/PasswordField';

const PasswordField = React.lazy(
  () => import('blackbird/components/common/PasswordField'),
);

export interface SignUpInput {
  user: {
    name: string;
    email: string;
    password: string;
    terms_of_service: string;
    is_marketing_opt_in?: string;
    token?: string;
  };
  authenticity_token: string;
  inviteToken?: string;
}
const SignupScreen: React.FC<OnboardingProps> = (props) => {
  const {
    register,
    formState,
    handleSubmit: handleValidation,
  } = useForm<SignUpInput>({
    defaultValues: {
      user: {
        email: props.invitedEmail ?? '',
      },
    },
  });
  const errors = formState.errors?.user;
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [loginUrl, setLoginUrl] = useState(`/login`);
  const { t } = useTranslation();
  const [notification, setNotification] = useState(props.notification);

  const toggleToEmailScreen = React.useCallback(() => {
    props.onToggleScreen && props.onToggleScreen(OnboardingType.SIGN_UP_EMAIL);
  }, [props]);

  const handleSubmit: SubmitHandler<SignUpInput> = async (data) => {
    const name = data.user.name;
    const email = data.user.email;
    const password = data.user.password;
    if (props.onSignUp) {
      props.onSignUp({ email, password, name });
    } else if (!props.authenticityToken || !props.formAction) {
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
    setIsBusy(true);
    try {
      const response = await fetch(props.formAction!, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();

      if (response.ok) {
        location.href = jsonResponse.next_url;
      } else {
        return setNotification({
          kind: 'warning',
          message: jsonResponse.error,
        });
      }
    } catch (err) {
      logger.error('Failed to sign up', err);
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    if (formState.touchedFields.user?.email) {
      setNotification(undefined);
    }
  }, [formState]);

  useEffect(() => {
    if (!notification) return;
    if (notification.kind === 'error' || notification.kind === 'warning') {
      RequestActions.error.defer(notification.message);
    } else {
      RequestActions.success.defer(notification.message);
    }
  }, [notification]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nextUrl = urlSearchParams.get('next_url');
    if (nextUrl) {
      setLoginUrl(`/login?next_url=${nextUrl}`);
    }
  }, []);

  const fromPaid =
    new URLSearchParams(window.location.search).get('fpr') === 'aura';
  const directToEmail =
    new URLSearchParams(window.location.search).get('auth') === 'email';
  const hasEmailValue = Boolean(
    new URLSearchParams(window.location.search).get('email'),
  );

  const nextUrl = new URLSearchParams(window.location.search).get('next_url');
  const isCheckoutMonthlyYearly = nextUrl
    ? /^\/checkout\/.*\/(monthly|yearly)$/.test(nextUrl)
    : false;

  // If a user has an email addres (i.e. from the marketing site)
  // just show the direct signup form
  useEffect(() => {
    if (directToEmail) {
      toggleToEmailScreen();
    }
  }, [directToEmail, toggleToEmailScreen]);

  const showCCForm =
    !props.inviteToken &&
    String(BoordsConfig.CheckoutSignup) === 'true' &&
    !isCheckoutMonthlyYearly &&
    !fromPaid;

  useEffect(() => {
    if (!showCCForm) {
      CookieDeleteService('require_payment_method');
      CookieDeleteService('trial_puffin_plan');
    }
  }, [showCCForm]);

  const [showPricingTable, setShowPricingTable] = useState(false);
  const [hasShownPricingTable, setHasShownPricingTable] = useState(false);

  const variant = 'test';
  // const variant = useFeatureFlagVariantKey('appSignupPricingTable');

  // posthog.featureFlags.override({
  //   appSignupPricingTable: 'test',
  // });

  useEffect(() => {
    if (variant === 'test' && showCCForm && !directToEmail) {
      setShowPricingTable(true);
      setHasShownPricingTable(true);
    }
  }, [variant, showCCForm, directToEmail]);

  const handleContinueTrial = () => {
    setShowPricingTable(false);
    Track.event.defer('trial_checkout_plan_select', {
      category: 'Checkout',
    });
  };

  const SignupBottomLink: React.FC = () => (
    <span className="inline-flex justify-center w-full mt-6 text-sm">
      <span className="mr-1 text-type-subdued">{`Already have an account?`}</span>
      <a
        className="no-underline hover:underline text-type-primary"
        href={'/login'}
      >{`Sign in here`}</a>
    </span>
  );

  if (showPricingTable) {
    return (
      <OnboardingFormWrapper
        title={`Choose your free trial`}
        subtitle={`Get a free 7-day trial and say goodbye to pre-production chaos. Cancel anytime in 1 click.`}
        size="xl"
        hideBorder
        subtitleClassNames="!max-w-[30rem]"
        bottomLink={<SignupBottomLink />}
      >
        <div className="flex flex-col gap-6">
          <div className="order-last md:order-first">
            <TrialPlansWrapped showFeatures />
          </div>
          <div className="order-first md:order-last grid md:grid-cols-3">
            <div />
            <Button
              rounded
              size="lg"
              type="pink"
              className="w-full"
              onClick={handleContinueTrial}
            >{`Continue setting up trial →`}</Button>

            <div />
          </div>
        </div>
      </OnboardingFormWrapper>
    );
  }

  return (
    <OnboardingFormWrapper
      title={
        props.inviteToken
          ? `Accept Invite`
          : isCheckoutMonthlyYearly
          ? `Create account`
          : hasShownPricingTable
          ? `Almost there...`
          : `Sign Up`
      }
      subtitleClassNames={`!w-full`}
      subtitle={
        props.inviteToken ? (
          ``
        ) : hasShownPricingTable &&
          props.type !== OnboardingType.SIGN_UP_EMAIL ? (
          <div className="pt-8 mt-2 mb-2 text-left border-t text-type-primary border-border">
            <Timeline />
          </div>
        ) : hasShownPricingTable &&
          props.type === OnboardingType.SIGN_UP_EMAIL ? (
          <></>
        ) : showCCForm ? (
          `Try free for 7 days. Cancel in 1 click.`
        ) : isCheckoutMonthlyYearly ? (
          ``
        ) : (
          `Try for free. No credit card required.`
        )
      }
      bottomLink={
        <>
          {hasShownPricingTable && (
            <div className="flex justify-center mt-8">
              <SecondaryLink
                onClick={() => setShowPricingTable(true)}
              >{`← Back to Plans`}</SecondaryLink>
            </div>
          )}

          <SignupBottomLink />
        </>
      }
    >
      <form
        ref={formRef}
        onSubmit={handleValidation(handleSubmit)}
        className="flex items-center flex-grow"
        method="post"
        action={props.formAction}
      >
        <input name="utf8" type="hidden" value="✓" />
        {props.authenticityToken && (
          <input
            type="hidden"
            {...register('authenticity_token')}
            value={props.authenticityToken}
          />
        )}
        <fieldset className="flex flex-col flex-grow space-y-8">
          {showCCForm &&
            props.type !== OnboardingType.SIGN_UP_EMAIL &&
            !hasShownPricingTable && <TrialPlansWrapped />}
          {props.inviteToken && (
            <input
              type="hidden"
              {...register('user.token')}
              id="user_token"
              value={props.inviteToken}
            />
          )}
          {props.type === OnboardingType.SIGN_UP_EMAIL ? (
            <div className="flex flex-col gap-6">
              <div className="space-y-2">
                <TextInput
                  {...register('user.name', {
                    required: 'Name is required.',
                  })}
                  placeholder={`Enter your name`}
                  label={t('signup.name')}
                  autoComplete="name"
                  className="flex-grow-0"
                  error={errors?.name?.message}
                />

                <TextInput
                  {...register('user.email', {
                    required: 'Email is required.',
                  })}
                  placeholder={`Your work email`}
                  label={t('signup.email')}
                  type="email"
                  className={classNames(
                    'flex-grow-0',
                    directToEmail && hasEmailValue && 'hidden',
                  )}
                  error={errors?.email?.message}
                />

                <PasswordField
                  placeholder={`Choose a password`}
                  label={t('signup.password')}
                  {...register('user.password', {
                    required: 'Password is required.',
                    validate: {
                      schema: checkPassword,
                    },
                  })}
                  className="flex-grow-0"
                  error={errors?.password?.message}
                />
                <div className="flex items-center justify-between pt-2">
                  <Checkbox
                    {...register('user.terms_of_service', {
                      validate: (v) =>
                        Boolean(v) || 'Please accept terms of service.',
                    })}
                    label={
                      <span className="inline-flex pb-1 -ml-1 text-sm text-type-subdued">
                        <Trans
                          t={t}
                          i18nKey="signup.terms"
                          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                          components={{
                            terms: (
                              <a
                                className="no-underline hover:underline !text-type-primary ml-1"
                                href="https://boords.com/page/terms-of-service"
                              >
                                terms and conditions
                              </a>
                            ),
                          }}
                        />
                      </span>
                    }
                    controlled={false}
                    htmlValue="1"
                    error={errors?.terms_of_service?.message}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <Button
                  loading={formState.isSubmitting || isBusy}
                  disabled={formState.isSubmitting || isBusy}
                  rounded
                  htmlType="submit"
                  size="lg"
                >
                  {hasShownPricingTable ? `Continue →` : t('signup.button')}
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div
                className={classNames(
                  'flex flex-col w-full gap-4',
                  showPricingTable ? 'max-w-sm' : '',
                )}
              >
                <Button
                  size="lg"
                  type="outline"
                  rounded
                  link={props.googleSigninPath}
                >
                  <div className="relative w-full">
                    <div className="absolute -left-1 top-[0.2rem]">
                      <LogoGoogle
                        width="1.2rem"
                        height="1.2rem"
                        alt="Google Logo"
                      />
                    </div>
                    <div className="w-full pl-2 text-center">{`Continue with Google`}</div>
                  </div>
                </Button>
                <Button
                  rounded
                  size="lg"
                  type="secondary"
                  onClick={toggleToEmailScreen}
                >
                  <div className="relative w-full">
                    <EnvelopeIcon className="w-5 absolute -left-1 top-[0.2rem]" />
                    <div>{`Continue with email`}</div>
                  </div>
                </Button>
              </div>
            </div>
          )}

          {props.type !== OnboardingType.SIGN_UP_EMAIL && (
            <div className="space-y-4">
              <div className="text-sm text-center space-x-1">
                <span className="text-type-subdued ">{`By signing up you agree to our`}</span>
                <a
                  className="no-underline hover:underline"
                  href="https://boords.com/page/terms-of-service"
                  target="_blank"
                  rel="noreferrer noopener"
                >{`Terms`}</a>
                <span className="text-type-subdued ">{`and`}</span>
                <a
                  className="no-underline hover:underline"
                  href="https://boords.com/legal/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >{`Privacy Policy`}</a>
              </div>
            </div>
          )}

          <div className="sm:hidden">
            <div className="mt-6 mb-4 text-sm text-center text-type-disabled">
              {`Trusted by creative teams worldwide:`}
            </div>
            <SignupLogos />
          </div>
        </fieldset>
      </form>
    </OnboardingFormWrapper>
  );
};
export default SignupScreen;
