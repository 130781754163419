/** @format */

import React, { useContext, useEffect } from 'react';
import { GeneratorContext } from 'blackbird/components/generator/GeneratorContext';
import { GeneratorForm } from 'blackbird/components/generator/GeneratorForm';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { GeneratorTokenCount } from 'blackbird/components/generator/GeneratorTokenCount';
import classNames from 'classnames';
const { default: Dialog } = require('blackbird/components/dialog/Dialog');

export const FlyoverImageGenerator: React.FC = () => {
  const {
    TrackGeneratorEvent,
    isGenerating,
    hasCredits,
    setIsOpen,
    definedStyle,
    definedTokenCount,
    setGeneratorPreview,
    editPromptWithSeed,
    seedImageUrl,
    seed,
    isOpen,
    frame,
    setFrame,
    layout,
    storyboard,
    setIsFlyoverGrid,
    isFlyoverGrid,
    isPanelbar,
  } = useContext(GeneratorContext);

  useEffect(() => {
    if (isOpen && definedStyle && definedTokenCount && !isGenerating) {
      TrackGeneratorEvent({ event_name: 'generator_open' });
    }
  }, [
    isOpen,
    definedStyle,
    definedTokenCount,
    TrackGeneratorEvent,
    isGenerating,
  ]);

  useEffect(() => {
    if (
      seed &&
      seedImageUrl &&
      editPromptWithSeed &&
      !isGenerating &&
      !isPanelbar
    ) {
      setIsFlyoverGrid(true);
    } else {
      setIsFlyoverGrid(false);
    }
  }, [isPanelbar, seed, seedImageUrl, editPromptWithSeed, isGenerating]);

  return !frame || !storyboard || !isOpen ? null : (
    <Dialog
      hideActions
      title={`Image Generator`}
      subtitle={`${storyboard.document_name} • Frame ${frame.number}`}
      subtitleIndicator={<GeneratorTokenCount />}
      size={isFlyoverGrid ? 'xl' : layout === 'singleColumn' ? 'normal' : 'md'}
      isOpen
      isDark
      isBlur
      keepOpen
      hideClose={isGenerating}
      onCloseBtnClick={() => {
        setIsOpen(false);
        setGeneratorPreview(undefined);
        setFrame(undefined);
        FlyoverActions.close();
        ToursActions.cancelTour.defer('generator');
      }}
    >
      <GeneratorForm />
    </Dialog>
  );
};

(window as any).FlyoverImageGenerator = FlyoverImageGenerator;
