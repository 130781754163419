/** @prettier */
/* tslint:disable no-var-keyword variable-name triple-equals */
import * as React from 'react';
import * as shallowEqual from 'shallowequal';
import type { DetailedFrame, IFrame } from '../../types/frame';
import { FrameActions } from '../../flux/actions/frame';
import type { IStoryboard } from '../../types/storyboard';
import {
  getUserFrameLimit,
  cannotCreateNewFrame,
  isUserSubjectToFreeLimit,
} from '../../helpers/can-create-new-frame';
import AddIcon from 'blackbird/images/icons/add-small.svg';
import { TourHintable } from '../tours/TourHintable';
import type { allTourSteps } from '../../tours/tourData';
import { CommentActions } from '../../flux/actions/comment';
import Icon from 'blackbird/components/icon/Icon';
import { focusModeLocalState } from 'javascripts/helpers/local-state';
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import { PopTransition } from 'blackbird/components/common/Transitions';
import { FrameCommentCount } from './FrameCommentCount.react';
import { PanelbarActions } from 'javascripts/flux/actions/panelbar';
import { any, last, throttle } from 'underscore';
import { FrameInfo } from './editor/FrameInfo';
import { FrameImageStatus } from './editor/FrameImageStatus';
import navigateToRoute from 'javascripts/helpers/router/navigate-to-route.js';
import { GridSize } from './FrameGrid';
import classNames from 'classnames';

const BEMHelper = require('react-bem-helper');
const FrameStatusIndicator = require('./editor/FrameStatusIndicator');
const bemHelper = new BEMHelper({
  name: 'grouped-frame',
  outputIsString: true,
});

const hintableSteps: allTourSteps[] = ['openFrame'];

/**
 * Explicitly requiring some global files, so that tests can load this file
 * without errors
 */
require('./editor/FrameImage.react');
require('./FrameCommentCount.react');
require('../../flux/stores/frame');
require('../../flux/stores/panelbar');

type GroupedState = true | false | 'start' | 'end';

interface IProps {
  frame: IFrame;
  index: string | number;
  isMultipleSelected: boolean;
  onHandleClick?: (frame: IFrame) => void;
  commentCount: number;
  storyboard: IStoryboard;
  grouped: GroupedState;
  unpaidFrameCountLimit: number;
  paidFrameCountLimit: number;
  isMinimal: boolean;
  gridSize: GridSize;
  estimatedWidth?: number;
}

interface IState {
  frame: DetailedFrame;
  [key: string]: any;
}

// prettier-ignore
const animationDelays = ['', 'delay-75', 'delay-100','delay-150','delay-200','delay-400','delay-500', 'delay-700', 'delay-1000']

export class FrameContainer extends React.Component<IProps, IState> {
  /** Props to compare in `shouldComponentUpdate` when receiving new props */
  propsToCompare: Array<keyof IProps> = [
    'frame',
    'commentCount',
    'grouped',
    'frame',
    'estimatedWidth',
  ];

  listEl: React.RefObject<HTMLDivElement>;
  frameEl: React.RefObject<HTMLDivElement>;
  insertEl: React.RefObject<HTMLButtonElement>;

  /**
   * A timer so that the add button animations don't happen
   * straight away as this was creating a lot of unnecessary animation
   */
  private addButtonTimeout: number;

  constructor(props) {
    super(props);
    this.state = {
      animated: false,
      frame: props.frame,
      insertAfterActive: false,
      insertBeforeActive: false,
      isDragging: false,
      isCommenting: false,
      isActiveCommentFrame: false,
      groupedFrameCount: null,
    };

    this.listEl = React.createRef();
    this.frameEl = React.createRef();
    this.insertEl = React.createRef();
  }
  calcHandleStyles() {
    if (this.props.grouped === 'end') {
      let count = 0;
      let listRef = this.listEl.current;
      while (
        listRef?.previousElementSibling !== null &&
        listRef?.previousElementSibling !== undefined
      ) {
        count++;
        if (
          listRef.previousElementSibling.classList.contains(
            'grouped-frame--start',
          )
        ) {
          break;
        }
        listRef = listRef.previousElementSibling as HTMLDivElement;
      }
      this.setState({ groupedFrameCount: count });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.grouped !== this.props.grouped) {
      this.calcHandleStyles();
    }
  }
  componentDidMount() {
    FrameStore.listen(this._onChange);
    CommentStore.listen(this._onCommentChange);
    this.calcHandleStyles();
  }

  handleStyle(count): React.CSSProperties {
    const right = (100 * (count + 1)) / 2;
    return {
      right,
      transform: 'translateX(50%)',
      top: -5,
      position: 'absolute',
    };
  }
  componentWillUnmount() {
    FrameStore.unlisten(this._onChange);
    CommentStore.unlisten(this._onCommentChange);
  }

  _onChange = (state) => {
    let animated = this.state.animated;
    const frame = state.frames.find((f) => {
      if (!f) return;
      return f.id === -1
        ? f.tmp_id === this.props.frame.tmp_id
        : f.id === this.props.frame.id;
    });

    // It's possible that an update was triggered but this frame was deleted.
    // we can ignore this for now
    if (!frame) return;

    if (state.frame_left == this.props.frame.sort_order) {
      animated = true;
      this._animateLeft();
    } else if (state.frame_right == this.props.frame.sort_order) {
      animated = true;
      this._animateRight();
    } else if (this.state.animated) {
      this._animateBack();
    }

    if (this.state.animated !== animated || this.state.frame !== frame) {
      this.setState({
        animated: animated,
        frame: frame,
      });
    }
  };

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return (
      shallowEqual(nextState, this.state) ||
      any(this.propsToCompare, (p) => shallowEqual(nextProps[p], this.props[p]))
    );
  }

  scrollIntoView = throttle(
    () =>
      this.listEl.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    200,
  );

  _onCommentChange = (state) => {
    let isActiveCommentFrame = false;

    // Is this the active frame?
    if (state.activeFrame) {
      isActiveCommentFrame = state.activeFrame.frameId === this.state.frame.id;
    }

    // Scroll to this frame if it becomes active
    if (
      !this.state.isActiveCommentFrame &&
      isActiveCommentFrame &&
      this.listEl.current
    ) {
      this.scrollIntoView();
    }

    // Open new comment box
    if (state.commentingInFrame && isActiveCommentFrame) {
      this.setState({ isCommenting: true });
    } else if (this.state.isCommenting && !isActiveCommentFrame) {
      // If we WERE commenting on this frame but aren't anymore,
      // remove highlight
      this.setState({
        isCommenting: false,
        isActiveCommentFrame: false,
      });
    }
  };

  _insertAfter = (e) => {
    const position = parseInt(this.state.frame.sort_order as any) + 1;
    e.preventDefault();
    FrameActions.insertFrame({
      storyboard_id: this.state.frame.storyboard_id,
      dropped_sort_order: position,
      move_frames_after_here: true,
    });
  };

  _insertBefore = (e) => {
    let position = parseInt(this.state.frame.sort_order as any);
    if (position < 0) {
      position = 0;
    }
    e.preventDefault();
    FrameActions.insertFrame({
      storyboard_id: this.state.frame.storyboard_id,
      dropped_sort_order: position,
      move_frames_after_here: true,
    });
  };

  _onInsertAfterMouseEnter = () => {
    this.addButtonTimeout = setTimeout(
      function () {
        FrameActions.mouseEnterInsertAfter(this.props.frame.sort_order);
      }.bind(this),
      200,
    ) as any;
    this.setState({ insertAfterActive: true });
  };

  _onInsertBeforeMouseEnter = () => {
    this.addButtonTimeout = setTimeout(
      function () {
        FrameActions.mouseEnterInsertBefore(this.props.frame.sort_order);
      }.bind(this),
      200,
    ) as any;
    this.setState({ insertBeforeActive: true });
  };

  _onInsertAfterMouseLeave = () => {
    clearTimeout(this.addButtonTimeout);
    FrameActions.mouseLeaveInsertAfter();
    this.setState({ insertAfterActive: false });
  };

  _onInsertBeforeMouseLeave = () => {
    clearTimeout(this.addButtonTimeout);
    FrameActions.mouseLeaveInsertBefore();
    this.setState({ insertBeforeActive: false });
  };

  _onDragStart = (event) => {
    FrameActions.startDragging(this.state.frame.id);
  };

  _onDragEnd = (event) => {
    FrameActions.endDragging();
  };

  _animateLeft = () => {
    const el = this.frameEl.current;
    TweenLite.to(el, 0.2, { x: -10 });
  };

  _animateRight = () => {
    const el = this.frameEl.current;
    TweenLite.to(el, 0.2, { x: 10 });
  };

  _animateBack = () => {
    const el = this.frameEl.current;
    TweenLite.to(el, 0.2, {
      x: 0,
      clearProps: 'all',
    });
  };

  _onFrameDragStart = (e) => {
    // When dragging, invisible elements unnecessarily
    // take up space in the draggable area
    const el = this.insertEl.current!;
    el.style.display = 'none';
  };

  _onFrameDragEnd = (e) => {
    const el = this.insertEl.current!;
    el.style.display = 'inline-block';
  };

  handleCommentsClick = (e) => {
    e.preventDefault();
    CommentActions.setActiveFrame.defer(this.state.frame.id);
    PanelbarActions.open.defer('comments');
  };

  handleGroupHandleClick = () => {
    this.props.onHandleClick?.(this.props.frame);
  };

  render() {
    let activeClassName = '';
    const userFrameLimit = getUserFrameLimit(this.props);
    // We only check for the free limit, because we don't want to grey out the
    // frames for users on the paid plan
    const frameIsOutsideFrameLimit =
      this.props.frame.sort_order > userFrameLimit &&
      isUserSubjectToFreeLimit();

    const canCreateNewFrame = !cannotCreateNewFrame(FrameStore.getState());

    if (this.state.frame.image_status == 'creating_record') {
      activeClassName += ' is--processing ';
    }

    // Set the insert after button class
    if (this.state.insertAfterActive || this.state.insertBeforeActive) {
      activeClassName += ' is--showing-insert-prompt ';
    }

    if (this.state.deleting) {
      activeClassName += ' deleting ';
    }

    if (this.state.frame && this.state.frame.is_selected) {
      activeClassName +=
        'ring-2 ring-offset-4 ring-offset-surface rounded ring-brand-pink is--selected';
    }

    const className = this.props.grouped
      ? bemHelper(null, [
          this.props.grouped === 'start' && 'start',
          this.props.grouped === 'end' && 'end',
        ])
      : '';

    if (frameIsOutsideFrameLimit) {
      activeClassName += ' opacity-60';
    }

    return (
      <ConditionalTooltip
        distance={0}
        title={
          frameIsOutsideFrameLimit
            ? `You've reached the ${userFrameLimit} frame limit for Free storyboards. Please choose a paid plan to edit this frame.`
            : undefined
        }
      >
        <div
          className={classNames(
            `frame__wrapper relative flex ${className}`,
            this.props.gridSize === 'xs' && 'p-1.5',
            this.props.gridSize === 'sm' && 'p-2',
            this.props.gridSize === 'md' && 'p-2.5',
            this.props.gridSize === 'lg' && 'p-3',
            this.props.gridSize === 'xl' && 'p-4',
          )}
          onDragStart={this._onFrameDragStart}
          onDragEnd={this._onFrameDragEnd}
          ref={this.listEl}
        >
          {this.props.grouped === 'end' && this.state.groupedFrameCount && (
            <div
              className="top-1/2 w-[50px] h-2 block rounded  bg-surface border-2 border-border-mid  frameSortableHandle z-10 cursor-grab hover:border-border"
              style={this.handleStyle(this.state.groupedFrameCount)}
              title="Move the entire group"
              onMouseDown={this.handleGroupHandleClick}
            />
          )}
          {canCreateNewFrame ? (
            <button
              className="flex flex-col gap-2 justify-center items-center opacity-0 hover:opacity-100 focus:opacity-100 absolute -left-[1em] w-[2em] top-[15%] h-[70%] cursor-pointer focus:outline-none -ml-[1px]"
              type="button"
              onMouseLeave={this._onInsertBeforeMouseLeave}
              onBlur={this._onInsertBeforeMouseLeave}
              onMouseEnter={this._onInsertBeforeMouseEnter}
              onFocus={this._onInsertBeforeMouseEnter}
              onClick={this._insertBefore}
              ref={this.insertEl}
            >
              <div className="bg-type-primary w-[2px] h-[72px]" />
              <Icon
                icon={<AddIcon />}
                className="w-4 h-4 text-white rounded-full bg-type-primary"
                fill
              />
              <div className="bg-type-primary w-[2px] h-[72px]" />
            </button>
          ) : (
            <div className="w-[2em] -left-[1em] -ml-[1px] absolute h-[70%]" />
          )}
          <div
            className={classNames(
              'frame--editable relative flex flex-col',
              activeClassName,
              this.props.gridSize === 'xs' && 'gap-2',
              this.props.gridSize === 'sm' && 'gap-2',
              this.props.gridSize === 'md' && 'gap-3',
              this.props.gridSize === 'lg' && 'gap-4',
              this.props.gridSize === 'xl' && 'gap-4',
            )}
            ref={this.frameEl}
          >
            <div className="relative">
              <TourHintable
                canShow={this.state.frame.sort_order === 1}
                step={hintableSteps}
                overlayPosition="right"
                // We need to make sure the user will open the editor, as the normal
                // default is player.
                onActivate={() => focusModeLocalState.setValue('frameEditor')}
                onNext={() =>
                  navigateToRoute('frameFocus', {
                    slug: this.props.storyboard.slug,
                    frameIndex: this.state.frame.sort_order,
                  })
                }
              >
                <FrameImage
                  {...this.props}
                  frame={this.state.frame}
                  isCommenting={this.state.isCommenting}
                  isMinimal={this.props.isMinimal}
                  frameIsOutsideFrameLimit={frameIsOutsideFrameLimit}
                  estimatedWidth={this.props.estimatedWidth}
                />
                <FrameImageStatus frame={this.state.frame} />
              </TourHintable>
            </div>

            <FrameInfo
              gridSize={this.props.gridSize}
              frame={this.state.frame}
              isCommenting={this.state.isCommenting}
              isMinimal={this.props.isMinimal}
              isInteractive={
                // If the frame is a temporary frame, we want to hold off on
                // any edits for now
                !frameIsOutsideFrameLimit && this.state.frame.id >= 0
              }
              frame_fields={this.props.storyboard.frame_fields!}
              update_all_frames={false}
              index={this.props.index}
              storyboardSlug={this.props.storyboard.slug}
              storyboardId={this.props.storyboard.id}
            />

            {this.props.commentCount > 0 &&
              this.props.storyboard.has_comments_enabled && (
                <PopTransition
                  appear={true}
                  show={
                    this.props.storyboard.preferences?.show_comment_badge ??
                    false
                  }
                  className={`absolute top-2 left-2 will-change-transform ${
                    animationDelays[this.props.frame.sort_order - 1] ??
                    last(animationDelays)
                  }`}
                >
                  <FrameCommentCount
                    count={this.props.commentCount}
                    tabIndex={-1}
                  />
                </PopTransition>
              )}

            <div className={'absolute -top-2 -right-2 z-10'}>
              <FrameStatusIndicator frame={this.state.frame} />
            </div>
          </div>
        </div>
      </ConditionalTooltip>
    );
  }
}
