/** @prettier */
import * as React from 'react';
import type { DetailedFrame } from 'javascripts/types/frame';
import { useStore } from 'javascripts/helpers/useStore';
import type { ScriptEditorStore } from 'javascripts/flux/stores/scripteditor';
import { ScriptEditorStoryboardItem } from './ScriptEditorStoryboardItem';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { FrameGrid } from '../frames/FrameGrid';

interface Props {
  frames: DetailedFrame[];
  storyboard: IStoryboardInStore;
}

export const ScriptEditorStoryboardView = React.memo<Props>((props) => {
  const frames = useStore<DetailedFrame[], ScriptEditorStore>(
    'scriptEditor',
    (s) => s.frames ?? [],
  );

  return (
    <FrameGrid
      className="relative overflow-x-hidden cursor-not-allowed frames -m-3"
      frameAspectRatio={props.storyboard.frame_aspect_ratio}
    >
      {({ isMinimal, size }) =>
        frames.map((f) => (
          <ScriptEditorStoryboardItem
            frame={f}
            key={f.id}
            storyboard={props.storyboard}
            isMinimal={isMinimal}
            gridSize={size}
          />
        ))
      }
    </FrameGrid>
  );
});

ScriptEditorStoryboardView.displayName = 'ScriptEditorStoryboardView';
