/** @format */

import classNames from 'classnames';
import React from 'react';
import { type PuffinPlanName } from './TeamContext';

interface TeamPlanBadgeProps {
  planName: PuffinPlanName;
}

export const TeamPlanBadge: React.FC<TeamPlanBadgeProps> = ({ planName }) => {
  return (
    <div className="inline-flex items-center">
      <div
        className={classNames(
          'px-2 pb-0.5 rounded-md text-xs font-semibold border ',
          planName === 'Standard'
            ? 'bg-decorative-blue/30 text-brand-blue border-decorative-blue/50'
            : planName === 'Workflow'
              ? 'bg-premium-100 text-premium-500 border-premium-200 '
              : planName === 'Trialing'
                ? 'bg-trial-200 text-trial-600 border-trial-300 '
                : 'bg-decorative-blue/30 text-brand-blue border-decorative-blue/50',
        )}
      >
        <>{planName === 'Trialing' ? 'Workflow Trial' : planName}</>
      </div>
    </div>
  );
};
