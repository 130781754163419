/** @prettier */
import React, { useContext } from 'react';
import classNames from 'classnames';
import { CircleCheckIcon, CircleIcon } from 'lucide-react';
import { formatCurrency } from 'javascripts/helpers/currencyHelper';
import { CreditsContext, type CreditBundle } from './CreditsContext';

interface CreditBundleOptionProps {
  bundle: CreditBundle;
  isSelected: boolean;
  onSelect: (bundleId: string) => void;
  disabled?: boolean;
  currency: string;
}

export const CreditBundleOption: React.FC<CreditBundleOptionProps> = ({
  bundle,
  isSelected,
  onSelect,
  disabled = false,
  currency,
}) => {
  // Find the price for the current currency
  const price = bundle.prices.find((p) => p.currency === currency);

  if (!price) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex items-center px-4 pt-3 pb-3 border-2 cursor-pointer rounded-xl mb-2 relative',
        isSelected ? 'border-brand-blue' : 'border-border',
        disabled && 'opacity-50 cursor-not-allowed',
      )}
      onClick={() => !disabled && onSelect(bundle.slug)}
    >
      <div className="hidden sm:flex justify-center items-center flex-shrink-0 text-brand-blue mr-3.5">
        {isSelected ? (
          <CircleCheckIcon className="w-5 h-5 text-brand-blue" />
        ) : (
          <CircleIcon className="w-5 h-5 text-type-disabled" />
        )}
      </div>
      <div className="flex-auto">
        <div
          className={classNames(
            'text-base flex items-center space-x-2',
            isSelected ? '' : 'text-type-subdued',
          )}
        >
          <div className="pb-1">{`${bundle.quantity} Credits`}</div>
          {bundle.isFeatured && (
            <div className="bg-brand-yellow-25 text-brand-yellow font-semibold rounded-md px-2 pt-[0.1rem] pb-0.5 text-xs border border-brand-yellow">
              {`Popular`}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-start flex-grow flex-shrink-0 ml-5 text-right">
        <div
          className={classNames(
            'text-base font-semibold pb-1',
            isSelected ? '' : 'text-type-disabled',
          )}
        >
          {formatCurrency({
            amount: price.amount / 100,
            currency: price.currency,
          })}
        </div>
      </div>
    </div>
  );
};

export const CreditBundleSelector: React.FC = () => {
  const { selectedBundleId, handleBundleSelect, isProcessing, creditBundles } =
    useContext(CreditsContext);

  return (
    <div className="space-y-3">
      {creditBundles.map((bundle) => (
        <CreditBundleOption
          key={bundle.slug}
          bundle={bundle}
          isSelected={selectedBundleId === bundle.slug}
          onSelect={handleBundleSelect}
          disabled={isProcessing}
          currency={BoordsConfig.Currency}
        />
      ))}
    </div>
  );
};
