/** @format */
import React from 'react';
import type { ValidateResult } from 'react-hook-form';
import TextInput, { type TextInputProps } from '../form/text-input/TextInput';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import { CircleCheck, LucideEye, LucideEyeOff } from 'lucide-react';
import classNames from 'classnames';
import { ConditionalTooltip } from '../feedback/tooltip/Tooltip';

yupPassword(yup);

export const checkPassword = (string): ValidateResult => {
  try {
    yup.string().min(8).minSymbols(1).validateSync(string, {
      abortEarly: false,
    });
  } catch (error) {
    return error.inner.map((e: Error) =>
      e.message.replace(/^this/, 'password'),
    );
  }
  return true;
};

const PasswordHint: React.FC<{
  isValid: boolean;
  label: string;
  tooltip?: string;
}> = ({ isValid, label, tooltip }) => (
  <div
    className={classNames(
      'flex items-center space-x-1',
      !isValid && 'opacity-40',
    )}
  >
    <span className={classNames(isValid ? 'text-type-green' : 'opacity-40')}>
      <CircleCheck size={14} />
    </span>
    <span className="pb-0.5">
      <ConditionalTooltip placement="top" title={tooltip} disabled={!tooltip}>
        <span>{label}</span>
      </ConditionalTooltip>
    </span>
  </div>
);

interface Props extends TextInputProps {
  error?: string;
  showCriteriaOnLoad?: boolean;
}

export const PasswordField = React.forwardRef<HTMLInputElement, Props>(
  ({ error, showCriteriaOnLoad, ...props }, ref) => {
    const [isMasked, setIsMasked] = React.useState(true);
    const [showHints, setShowHints] = React.useState(
      showCriteriaOnLoad ?? true,
    );
    const [validationInfo, setValidationInfo] = React.useState(
      checkPassword(''),
    );

    // Function to check if a specific rule is met
    const isRuleMet = (rule: string): boolean => {
      if (validationInfo === true) return true;
      if (!Array.isArray(validationInfo)) return false;
      return !validationInfo.some((e) => e.includes(rule));
    };

    return (
      <div className="space-y-2">
        <TextInput
          ref={ref}
          {...props}
          onChange={(e) => {
            setValidationInfo(checkPassword(e.currentTarget.value));
            props.onChange?.(e);
          }}
          autoComplete={props.autoComplete ?? 'new-password'}
          type={isMasked ? 'password' : 'text'}
          error={error}
          onFocus={(e) => {
            props.onFocus?.(e);
            setShowHints(true);
          }}
          rightComponent={
            <button
              type="button"
              aria-label="toggle password visibility"
              className="opacity-20 hover:opacity-100"
              onClick={() => setIsMasked((i) => !i)}
            >
              {isMasked ? <LucideEyeOff size={16} /> : <LucideEye size={16} />}
            </button>
          }
        />
        {showHints && (
          <div className="px-1 mt-1 mb-2 text-sm grid grid-cols-2">
            <PasswordHint
              isValid={isRuleMet('at least 1 symbol')}
              label={`One special character`}
              tooltip={`E.g. @ & # *`}
            />
            <PasswordHint
              isValid={isRuleMet('at least 8 characters')}
              label={`At least 8 characters`}
            />
          </div>
        )}
      </div>
    );
  },
);

PasswordField.displayName = 'PasswordField';

export default PasswordField;
