/** @format */

import { Listbox } from '@headlessui/react';
import { SecondaryLink } from 'blackbird/components/common/SecondaryLink';
import { useTranslation } from 'react-i18next';
import Toast from 'blackbird/components/feedback/toast/Toast';
import useCustomPopper from 'blackbird/helpers/hooks/useCustomPopper';
import classNames from 'classnames';
import { generatorCharacterLocalState } from 'javascripts/helpers/local-state';
import React from 'react';
import { type GeneratorGuideline, GeneratorContext } from '../GeneratorContext';
import { GuidelineBetaBadge } from './GuidelineBetaBadge';
import { GuidelineCharacterGroup } from './GuidelineCharacterGroup';
import { GuidelineOptionBlank } from './GuidelineOptionBlank';
import { GuidelineOptionCharacter } from './GuidelineOptionCharacter';
import { Star, Trophy } from 'lucide-react';

export const GuidelineCharacterDropdown: React.FC = () => {
  const {
    generatorGuidelines,
    isPanelbar,
    selectedCharacter,
    filteredCharacters,
    setSelectedCharacter,
  } = React.useContext(GeneratorContext);

  const { t } = useTranslation('generatorCopy');

  const [parentRef, setParentRef] = React.useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );

  const { attributes, styles: popperStyles } = useCustomPopper(
    parentRef,
    popperElement,
    {
      placement: 'bottom-end',
      distance: 0,
      maxHeight: 430,
    },
  );

  const handleChange = (value: string) => {
    if (generatorGuidelines) {
      const newCharacter = generatorGuidelines.find(
        (g) => g.id === value,
      ) as GeneratorGuideline;

      if (newCharacter) {
        setSelectedCharacter(newCharacter);
        generatorCharacterLocalState.setValue(newCharacter.id);
      }
    }
  };

  return !filteredCharacters || filteredCharacters.length === 0 ? null : (
    <div>
      <div className="flex items-baseline">
        <div
          className={classNames(
            'mb-1 font-semibold flex-auto flex items-center',
            isPanelbar && 'text-sm',
          )}
        >
          <span className="mr-2">{`Choose Character`}</span>

          <div className="flex-auto -mt-1 text-right">
            <SecondaryLink href={t('imageHelpDoc.href')} newWindow>
              {t('imageHelpDoc.cta')}
            </SecondaryLink>
          </div>
        </div>
      </div>

      <Listbox value={null} onChange={handleChange}>
        <Listbox.Button ref={(ref) => setParentRef(ref)} className="w-full">
          <div
            className={classNames(
              'flex items-center border rounded-md space-x-2 ',
              selectedCharacter ? 'border-form' : 'border-brand-pink border-2',
              isPanelbar ? 'px-3 py-2.5 ' : 'px-4 py-3.5 ',
            )}
          >
            {selectedCharacter ? (
              <GuidelineOptionCharacter small character={selectedCharacter} />
            ) : (
              <GuidelineOptionBlank title={`Pick a character (Recommended)`} />
            )}
          </div>
        </Listbox.Button>

        <>
          <Listbox.Options
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
            className={classNames(
              'z-40 overflow-y-auto bg-white rounded-lg shadow-lg focus:outline-none ',

              isPanelbar ? 'px-3 py-3 w-[18.5rem]' : 'px-6 py-6 w-[27rem]',
            )}
          >
            <GuidelineCharacterGroup
              title={`Custom`}
              filter={(guideline) =>
                !guideline.is_global && guideline.is_active
              }
            />
            <GuidelineCharacterGroup
              title={`Default Characters`}
              filter={(guideline) =>
                (guideline.is_active || BoordsConfig.BackendAccess) &&
                guideline.positive &&
                guideline.is_global
              }
            />
          </Listbox.Options>
        </>
      </Listbox>
    </div>
  );
};
