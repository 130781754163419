/** @format */
import DialogContextProvider from 'blackbird/components/dialog/DialogContext';
import { GeneratorProvider } from 'blackbird/components/generator/GeneratorContext';
import { GeneratorCharacterProvider } from 'blackbird/components/generator/guidelines/GeneratorCharacterContext';
import { InpaintProvider } from 'blackbird/components/inpaint/InpaintContext';
import { WizardProvider } from 'blackbird/components/wizard/WizardContext';
import { BillingProvider } from 'blackbird/helpers/contexts/BillingContext';
import { BoordsAiProvider } from 'blackbird/helpers/contexts/BoordsAiContext';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import { posthogClient } from 'javascripts/helpers/posthog';
import { PostHogProvider } from 'posthog-js/react';
import * as React from 'react';
import * as memoize from 'memoizee';
import { BoordsDndProvider } from './BoordsDndProvider';
import { ComponentComposer } from './ComponentComposer';

interface Props {
  children: React.ReactNode;
  additional?: any[];
}

const defaultAdditions = [];

/** Combines all the typical contexts for root elements into one file. It also
 * uses the ComponentComposer component, which allows providers to be passed as
 * an array, preventing the nesting and indenting hell that can get quite
 * chaotic and noisy in git.  */
export const DefaultProviders = React.memo<Props>((props) => {
  const providers = memoize((add = defaultAdditions) => [
    [PostHogProvider, { client: posthogClient() }],
    BoordsAiProvider,
    WizardProvider,
    GeneratorProvider,
    GeneratorCharacterProvider,
    InpaintProvider,
    BillingProvider,
    DefaultStoreContextProvider,
    BoordsDndProvider,
    DialogContextProvider,
    ...add,
    // a change in this.state.type will update this memoized thing
  ])(props.additional);

  return (
    <ComponentComposer items={providers}>{props.children}</ComponentComposer>
  );
});

DefaultProviders.displayName = 'BoordsContexts';
