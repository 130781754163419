/** @format */
const _ = require('underscore');
const createReactClass = require('create-react-class');
const PropTypes = require('prop-types');
const fitLib = require('fit.js');
const Container = require('./Container2.jsx');
const FrameSubtitles = require('./FrameSubtitles').default;
const Rollbar = require('../../helpers/rollbar').rollbar;
const logger = require('../../helpers/logger').default;
const {
  LoadingIndicator,
} = require('blackbird/components/common/loading-indicator/LoadingIndicator');
const { getFrameImage } = require('javascripts/helpers/getFrameImage');
const { Watermark } = require('blackbird/components/watermark/Watermark');
const {
  isImagePlaceholder,
} = require('javascripts/helpers/isImagePlaceholder');

const FrameViewer = createReactClass({
  displayName: 'FrameViewer',

  propTypes: {
    frame: PropTypes.shape({
      id: PropTypes.number.isRequired,
      large_image_url: PropTypes.string.isRequired,
      voiceover: PropTypes.string,
    }).isRequired,
    frameAspectRatio: PropTypes.string.isRequired,
    isSubtitlesEnabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    cursor: PropTypes.string,
    subtitleSource: PropTypes.string,
    framesAreSaving: PropTypes.bool.isRequired,
    border: PropTypes.bool,
    opacity: PropTypes.number,
    size: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  },

  getInitialState() {
    return {
      scale: 0,
      styles: {
        opacity: this.props.size ? this.props.opacity : 0,
        width: this.props.size?.width,
        height: this.props.size?.height,
        cursor: this.props.onClick ? 'pointer' : undefined,
        transform: this.props.size
          ? `translate(-${this.props.size.width / 2}px, -${
              this.props.size.height / 2
            }px)`
          : undefined,
      },
    };
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.frame.large_image_url !== this.props.frame.large_image_url ||
      nextState !== this.state ||
      nextProps.frame.voiceover !== this.props.frame.voiceover ||
      nextProps.subtitleSource !== this.props.subtitleSource ||
      nextProps.isSubtitlesEnabled !== this.props.isSubtitlesEnabled ||
      nextProps.framesAreSaving !== this.props.framesAreSaving ||
      nextProps.size !== this.props.size
    );
  },

  componentDidUpdate(prevProps) {
    const { isPlaying, time, frameTimes } = PlayerStore.getState();

    if (prevProps.frame !== this.props.frame && isPlaying) {
      const targetTime = frameTimes[this.props.frame.id];
      const delta = time - targetTime;
      const format = delta > 0 ? '+' : delta < 0 ? '-' : '0';
      const formattedDelta = `${format}${delta}`;
      logger.log(
        `Received new frame at ${time}. Target: ${targetTime} (${formattedDelta}ms)`,
      );
    }

    if (prevProps.size !== this.props.size && this.props.size) {
      const { width, height } = this.props.size;
      this.setState({
        styles: {
          width: width,
          height: height,
          opacity: this.props.opacity,
          transform: `translate(-${width / 2}px, -${height / 2}px)`,
          scale: 1,
        },
      });
    }
  },

  timeouts: [],

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.size) {
      this.resize();
      this._onResize = _.throttle(this.resize, 50);
      window.addEventListener('resize', this._onResize);
      this.timeouts.push(window.setTimeout(() => this.resize(), 250));
      this.timeouts.push(window.setTimeout(() => this.resize(), 1000));
    }
  },

  componentWillUnmount() {
    this._isMounted = false;
    if (!this.props.size) {
      window.removeEventListener('resize', this._onResize);
      this.timeouts.forEach((id) => window.clearTimeout(id));
    }
  },

  onImageError(error) {
    if (navigator.onLine && typeof Rollbar !== 'undefined') {
      Rollbar.warn('Error loading frame image', error.currentTarget.src);
    }
  },

  resize() {
    if (!this._isMounted || this.props.size) return;

    const container = ReactDOM.findDOMNode(this).parentNode;

    const frameSize = BoordsFrameSizeHelper(this.props.frameAspectRatio);
    const { width, height, scale } = fitLib(frameSize, container, {
      apply: false,
    });

    if (_.isNaN(width) || _.isNaN(height) || _.isNaN(scale)) return;

    if (
      width !== this.state.styles.width ||
      height !== this.state.styles.height
    ) {
      this.setState({
        scale: scale,
        styles: {
          width,
          height,
          transform: `translate(-${width / 2}px, -${height / 2}px)`,
          cursor: this.props.onClick ? 'pointer' : this.props.cursor,
          opacity: this.props.opacity,
        },
      });
    }
  },

  render() {
    const { frame, border = true } = this.props;
    const subTitleSize = Math.max(12, 40 * this.state.scale);
    const currentSrc = getFrameImage(
      frame.large_image_url,
      this.props.frameAspectRatio,
    );

    const imageClassNames = `w-full h-full border ${
      border ? 'border-border-image' : 'border-transparent'
    }`;

    return (
      <div
        className="absolute left-1/2 top-1/2"
        data-hj-suppress
        style={this.state.styles}
        onClick={this.props.onClick}
      >
        {/* We don't want to show this frame if we haven't calculated a size yet. Otherwise it would display full-size */}
        {this.state.styles.width ? (
          <Watermark isMissing={isImagePlaceholder(currentSrc)}>
            <img
              className={imageClassNames}
              src={currentSrc}
              alt=""
              onError={this.onImageError}
            />
          </Watermark>
        ) : null}

        <FrameSubtitles
          size={subTitleSize}
          subtitleSource={this.props.subtitleSource}
        />

        {this.props.framesAreSaving && <LoadingIndicator fill />}
      </div>
    );
  },
});

module.exports.default = Container(FrameViewer);
module.exports.FrameViewer = FrameViewer;
