/** @prettier */
import React, { useState, useEffect, useCallback } from 'react';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  format,
} from 'date-fns';
import Tooltip from '../feedback/tooltip/Tooltip';
import { Calendar } from 'lucide-react';

interface Props {
  date: Date;
  className?: string;
}

const getRelativeTimeString = (date: Date): string => {
  const now = new Date();
  const secondsDiff = differenceInSeconds(now, date);
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);
  const daysDiff = differenceInDays(now, date);
  const weeksDiff = differenceInWeeks(now, date);
  const monthsDiff = differenceInMonths(now, date);
  const yearsDiff = differenceInYears(now, date);

  // Less than 2 minutes
  if (secondsDiff < 120) {
    return 'Just now';
  }
  // Within the hour (2m-59m)
  if (minutesDiff < 60) {
    return `${Math.round(minutesDiff)}m`;
  }
  // Within the day (1h-23h)
  if (hoursDiff < 24) {
    return `${hoursDiff}h`;
  }
  // Within the week (1d-7d)
  if (daysDiff < 7) {
    return `${daysDiff}d`;
  }
  // Within the month (1w-3w)
  if (weeksDiff < 4) {
    return `${weeksDiff}w`;
  }
  // Within the year (1mo-11mo)
  if (monthsDiff < 12) {
    return `${monthsDiff}mo`;
  }
  // More than a year
  return `${yearsDiff}y`;
};

const getUpdateInterval = (date: Date): number => {
  const now = new Date();
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);

  if (minutesDiff < 2) return 15 * 1000; // 15 seconds
  if (minutesDiff < 60) return 60 * 1000; // 1 minute
  if (hoursDiff < 24) return 30 * 60 * 1000; // 30 minutes
  return 24 * 60 * 60 * 1000; // 24 hours
};

export const SimpleRelativeTime: React.FC<Props> = ({ date, className }) => {
  const [relativeTime, setRelativeTime] = useState<string>(
    getRelativeTimeString(date),
  );

  const updateTime = useCallback(() => {
    setRelativeTime(getRelativeTimeString(date));
  }, [date]);

  useEffect(() => {
    const interval = getUpdateInterval(date);
    const timeoutId = setInterval(updateTime, interval);
    return () => clearInterval(timeoutId);
  }, [date, updateTime]);

  return (
    <Tooltip
      title={
        <span className="inline-flex items-center space-x-1.5">
          <Calendar className="w-3 h-3 !text-type-disabled" />
          <span className="pb-0.5">
            {format(date, "MMMM d, yyyy 'at' h:mm:ss a")}
          </span>
        </span>
      }
      placement="top-start"
    >
      <time className={className} dateTime={date.toISOString()}>
        {relativeTime}
      </time>
    </Tooltip>
  );
};
