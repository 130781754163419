/** @format */
import * as frameGroupedState from 'javascripts/helpers/frame-grouped-state.js';
import { useOnMount } from 'javascripts/helpers/useOnMount';
import { useStore } from 'javascripts/helpers/useStore';
import { useStoreAction } from 'javascripts/helpers/useStoreAction';
import type { DetailedFrame } from 'javascripts/types/frame';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import * as ShareableGridItem from './ShareableGridItem.react';
import * as React from 'react';
import type {
  CommentSection,
  CommentStore,
} from 'javascripts/flux/stores/comment';

import { FrameGrid } from 'javascripts/components/frames/FrameGrid';

interface Props {
  storyboard: IStoryboardInStore;
  frames: DetailedFrame[];
  zoomType: 'player' | 'zoom';
}
export const ShareableGrid: React.FC<Props> = (props) => {
  const fetchComments = useStoreAction(
    'CommentActions',
    'fetch',
    props.storyboard.short_slug,
  );
  useOnMount(fetchComments);
  const comments = useStore<Record<string, CommentSection>, CommentStore>(
    'comment',
    (c) => c.commentSectionsById,
  );

  // We use this.props.frames instead of this.props.storyboard.frames
  // so that we have the number attribute
  return (
    <FrameGrid
      frameAspectRatio={props.storyboard.frame_aspect_ratio}
      className="flex-auto p-6 overflow-x-hidden bg-surface gap-4"
    >
      {({ estimatedWidth }) =>
        props.frames.map((frame, index) =>
          frame ? (
            <ShareableGridItem
              {...props}
              fullSizeImage={false}
              key={'frame:' + frame.id}
              frame={frame}
              grouped={frameGroupedState(props.frames, index)}
              index={index + 1}
              commentCount={comments[frame.id] && comments[frame.id].count}
              zoomType={props.zoomType}
              estimatedWidth={estimatedWidth}
            />
          ) : null,
        )
      }
    </FrameGrid>
  );
};
