/** @prettier */
/* tslint:disable variable-name */
import { FakeAltStoreClass } from './AltStore';
import type {
  PickerOptions,
  PickerFileErrorCallback,
  PickerFileMetadata,
} from 'filestack-js/build/main/lib/picker';
import type { Client } from 'filestack-js/build/main/lib/client';
import { AspectRatioName, frameAspectRatio } from '../../types/storyboard';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { notificationIfCannotCreateNewFrame } from '../../helpers/can-create-new-frame';
import { FrameActions } from '../actions/frame';
import { IFrame } from '../../types/frame';
import { FileUploadActions } from '../actions/file_upload';

import { FilestackActions } from '../actions/filestack';
import {
  TransformFilestackUrlProps,
  transformFilestackUrl,
} from '../../helpers/transformFilestackImage';
import { maxImageFileSize, maxImageSizeInMB } from '../../helpers/constants';
import { RequestActions } from '../actions/request';
import { idleTimeout } from 'javascripts/helpers/idle-timeout';
import BoordsFrameSizeHelper, {
  frameAspectRatioNormalizer,
  getAspectRatioNumerical,
} from 'javascripts/helpers/frame-size-helper';
import { getThumbnailSize } from 'javascripts/helpers/getThumbnailSize';
import { isFileTypeAccepted } from 'javascripts/helpers/isFileTypeAccepted';
import { fileTypeListToString } from 'javascripts/helpers/fileTypeListToString';
import type { GenericCallback } from 'blackbird/helpers/types';
import { isUndefined, without } from 'underscore';
import type { FileUploadProgressCallback } from './file_upload';
import logger from 'javascripts/helpers/logger';

const Env = require('../../helpers/env.js')();

const errorHandler = RequestErrorHandler('Filestack');

const promiseErrorHandler = errorHandler({
  message: `Sorry, we couldn\'t process your image`,
  rollbarMessage: 'Could not upload image',
  serviceName: 'Filestack',
});

/**
 * An abstraction covering the returned data from both our custom-storage
 * uploader and filestack. Filestack offers more, but these are marked
 * as optional
 */
export type BoordsPickerFileMetaData = Pick<
  PickerFileMetadata,
  'filename' | 'url' | 'mimetype'
> &
  Partial<PickerFileMetadata>;

/** Based on certain parameters, either one or multiple files are returned */
type BoordsPickerFileMetaDataAmbiguous =
  | BoordsPickerFileMetaData
  | BoordsPickerFileMetaData[];

/**
 * Callback function to be fired after upload finishes. `file` will be
 * undefined if upload failed */
export type pickerCallback = (file?: BoordsPickerFileMetaData) => void;
/**
 * Callback function to be fired after upload finishes when multiple files are
 * uploaded. `file` will be undefined if upload failed */
export type pickerCallbackMultiple = (
  files?: BoordsPickerFileMetaData[],
) => void;

/**
 * Callback function to be fired after upload finishes when one or multiple
 * files are uploaded. `file` will be undefined if upload failed */
export type pickerCallbackAmbiguous = (
  file?: BoordsPickerFileMetaDataAmbiguous,
) => void;

// prettier-ignore
const imageMimeTypes = [
  'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp', 'image/tiff', 'image/png', 'image/webp', 'image/heic', 'image/heif', 'image/psd', 'image/xvg+xml', 'image/photoshop', 'image/psd', 'image/x-psd', 'image/vnd.adobe.photoshop',
];

// Not sure why filestack's SDK doesn't offer types for this
// prettier-ignore
export type fileStackSource = 'local_file_system' | 'url' | 'imagesearch' | 'facebook' | 'instagram' | 'googledrive' | 'dropbox' | 'webcam' | 'video' | 'audio' | 'box' | 'github' | 'gmail' | 'picasa' | 'onedrive' | 'onedriveforbusiness' | 'clouddrive' | 'customsource'

const filestackError =
  (callback?): PickerFileErrorCallback =>
  (file: PickerFileMetadata | Error, err?) => {
    /**
     * If the internet connection drops, err is null, file is instanceof Error
     * If the user cancels upload, file is half-filled in, but err is null
     * 🐶🔥 this is fine
     */
    if (err && err.message === 'Upload cancelled') return;
    const fileName =
      file instanceof Error || !file.filename ? 'your image' : file.filename;

    errorHandler({
      message: `Sorry, we couldn\'t process ${fileName}`,
      rollbarMessage: 'Could not upload image',
    })(err || file);
    if (callback) callback(false);
  };

export class FilestackStore extends FakeAltStoreClass<FilestackStore> {
  apiKey = Env.development()
    ? 'Ag8Bmt0ajRo6bu8NyYPthz'
    : 'A4kyaIkAQbWVnXvxox8Nwz';
  sources = require('../../helpers/upload-sources.js')();
  maxSize = maxImageFileSize;
  client: Client;
  /**
   * We need this variable to prevent closing of the pdf Dialog that opened the FileStack Picker.
   * Dialog will close on clicking anywhere
   * on the FileStack UI as it will be considered  same as "clicking outside".
   * We need to track the opening and closing of FileStack picker and pass the same information
   * to the pdf dialog
   */
  pdfDialogOpened = false;
  constructor() {
    super();
    this.bindListeners({
      handleAddResponseAsFrames: FilestackActions.ADD_RESPONSE_AS_FRAMES,
      handleCropUI: FilestackActions.OPEN_CROP_UI,
      handleOpenAvatar: FilestackActions.OPEN_AVATAR,
      handleOpenDialog: FilestackActions.OPEN_DIALOG,
      handleOpenEditorDialog: FilestackActions.OPEN_EDITOR_DIALOG,
      handleOpenCommentAttachment: FilestackActions.OPEN_COMMENT_ATTACHMENT,
      handleOpenPDF: FilestackActions.OPEN_PDF,
      handleOpenPdfFooterLogo: FilestackActions.OPEN_PDF_FOOTER_LOGO,
      handleOpenPdfCoverLogo: FilestackActions.OPEN_PDF_COVER_LOGO,
      handleOpenPicker: FilestackActions.OPEN_PICKER,
      handleOpenWithFile: FilestackActions.OPEN_WITH_FILE,
      handleUploadFile: FilestackActions.UPLOAD_FILE,
      handleUploadFrameFile: FilestackActions.UPLOAD_FRAME_FILE,
      handleStoreURL: FilestackActions.STORE_URL,
    });

    idleTimeout(() => {
      import('filestack-js').then(
        (filestack) => {
          this.client = filestack.init(this.apiKey);
        },
        errorHandler({
          message: null,
          rollbarMessage: 'Could not load Filestack',
        }),
      );
    }, 1500);
  }

  handleOpenCommentAttachment(args: { callback?: pickerCallback }) {
    this.client
      .picker({
        maxSize: this.maxSize,
        accept: imageMimeTypes,
        fromSources: this.sources.filter({
          context: 'image',
          freeloader: false,
        }),
        transformations: {
          crop: true,
        },
        maxFiles: 1,
        onFileUploadFinished: (file) => {
          if (args.callback) {
            args.callback(file);
          }
        },
        onFileUploadFailed: filestackError(),
      })
      .open()
      .catch(promiseErrorHandler);
  }

  handleCropUI(args: {
    frame: IFrame;
    frame_aspect_ratio: frameAspectRatio;
    callback?: pickerCallback;
  }) {
    let url = args.frame.large_image_url;

    if (url.indexOf('//') === 0) {
      url = 'https:' + url;
    }

    this.client
      .picker({
        ...this.getOptions(args.frame_aspect_ratio),
        onFileUploadFinished: (file) => {
          this.saveFilestackResponse(
            {
              ...args,
              imageUrl: file.url,
              mimetype: file.mimetype,
            },
            () => args.callback && args.callback(file),
          );
        },
        onFileUploadFailed: filestackError(),
      })
      .crop(url)
      .catch(promiseErrorHandler);
  }

  handleOpenAvatar(args: {
    type: 'user' | 'team';
    /** id of user or team to update */
    id: number;
  }) {
    this.client
      .picker({
        maxSize: this.maxSize,
        accept: imageMimeTypes,
        fromSources: this.sources.filter({
          context: 'image',
          freeloader: BoordsConfig.Freeloader,
        }),
        maxFiles: 1,
        transformations: {
          crop: {
            force: true,
            aspectRatio: 1,
          },
        },
        onFileUploadFinished: (file) => {
          AvatarActions.upload.defer({
            response: file,
            type: args.type,
            id: args.id,
          });
        },
        onFileUploadFailed: filestackError(),
      })
      .open()
      .catch(promiseErrorHandler);
  }

  handleOpenPDF(args: {
    team_id: number;
    storyboard_id: number;
    type: 'endslate' | 'cover';
  }) {
    this.pdfDialogOpened = true;
    this.emitChange();
    const callback = (file) => {
      PdfCoverActions.uploadByURL({
        storyboard_id: args.storyboard_id,
        type: args.type,
        response: file,
      });
    };

    const pickerOptions = {
      maxSize: this.maxSize,
      accept: ['application/pdf'],
      fromSources: this.sources.filter({
        context: 'pdf',
        freeloader: BoordsConfig.Freeloader,
      }),
      maxFiles: 1,
      onFileUploadFinished: callback,
      onFileUploadFailed: filestackError(),
      onClose: () => {
        this.pdfDialogOpened = false;
        this.emitChange();
      },
    };

    if (BoordsConfig.CustomStorage)
      return FileUploadActions.openPicker.defer({
        ...pickerOptions,
        options: {
          accept: ['application/pdf'],
        },
        team_id: args.team_id,
        callback: (result) => {
          if (result) {
            callback(result);
          } else {
            errorHandler({
              message: 'Could not set PDF',
              rollbarMessage: 'Setting new PDF url failed',
            })();
          }
          this.pdfDialogOpened = true;
          this.emitChange();
        },
      });

    this.client.picker(pickerOptions).open().catch(promiseErrorHandler);
  }

  handleOpenPdfFooterLogo(args: { team_id: number; storyboard_id: number }) {
    this.pdfDialogOpened = true;
    this.emitChange();
    const pickerOptions = {
      maxSize: this.maxSize,
      accept: imageMimeTypes,
      fromSources: this.sources.filter({
        context: 'image',
        freeloader: BoordsConfig.Freeloader,
      }),
      maxFiles: 1,
      onFileUploadFinished: (file) => {
        PdfFooterLogoActions.upload({
          response: file,
          storyboard_id: args.storyboard_id,
        });
      },
      onFileUploadFailed: filestackError(),
      onClose: () => {
        this.pdfDialogOpened = false;
        this.emitChange();
      },
    };

    if (BoordsConfig.CustomStorage)
      return FileUploadActions.openPicker.defer({
        ...pickerOptions,
        team_id: args.team_id,
        callback: () => {
          this.pdfDialogOpened = true;
          this.emitChange();
        },
      });

    this.client.picker(pickerOptions).open().catch(promiseErrorHandler);
  }

  handleOpenPdfCoverLogo(args: { team_id: number }) {
    this.pdfDialogOpened = true;
    this.emitChange();
    const pickerOptions: Partial<PickerOptions> = {
      maxSize: this.maxSize,
      accept: imageMimeTypes,
      fromSources: this.sources.filter({
        context: 'image',
        freeloader: BoordsConfig.Freeloader,
      }),
      maxFiles: 1,
      onClose: () => {
        this.pdfDialogOpened = false;
        this.emitChange();
      },
      onFileUploadFinished: (file) => {
        CoverpageActions.uploadLogo({
          response: file,
        });
      },
      onFileUploadFailed: filestackError(),
    };

    if (BoordsConfig.CustomStorage)
      return FileUploadActions.openPicker.defer({
        maxFiles: 1,
        ...pickerOptions,
        team_id: args.team_id,
        callback: () => {
          this.pdfDialogOpened = false;
          this.emitChange();
        },
      });

    this.client.picker(pickerOptions).open().catch(promiseErrorHandler);
  }

  _setSourceOrder(initial_source: fileStackSource = 'local_file_system') {
    const filteredSources = this.sources.filter({
      context: 'image',
      freeloader: BoordsConfig.Freeloader,
    });
    if (isUndefined(initial_source)) {
      return filteredSources;
    } else {
      const sources = [initial_source].concat(
        without(filteredSources, initial_source),
      );
      return sources;
    }
  }

  // This is meant to basically be the same as handleOpenDialog, but without
  // the automatic upload. Just launches FileStack
  /**
   * Launches the picker, and passes the uploaded files to the callback.
   * if `maxFiles` is higher than 1, an array of uploaded files will be
   * returned, otherwise it returns one result object.
   */
  handleOpenPicker(args: {
    callback: pickerCallbackAmbiguous;
    maxFiles: number;
    options?: PickerOptions;
    team_id: number;
  }) {
    const { callback, options, maxFiles = 1 } = args;
    if (BoordsConfig.CustomStorage)
      return FileUploadActions.openPicker.defer({
        options: { ...this.getOptions(), ...options },
        maxFiles: maxFiles,
        team_id: args.team_id,
        callback: callback,
      });

    this.client
      .picker({
        ...this.getOptions(), // Apply the default options
        ...options,
        imageMax: [1920, 1920], // And set a max width?
        maxFiles: maxFiles,
        onUploadDone: (response) => {
          // Sometimes, this is called when length === 0, we should ignore this
          if (response.filesUploaded.length === 0) return;
          callback(
            maxFiles === 1 ? response.filesUploaded[0] : response.filesUploaded,
          );
        },
        onFileUploadFailed: filestackError(callback),
      })
      .open()
      .catch(promiseErrorHandler);
  }

  /**
   * Takes the filesUploaded array from a filestack upload, and adds it
   * as a frame.
   */
  handleAddResponseAsFrames(params: {
    filesUploaded: BoordsPickerFileMetaData | BoordsPickerFileMetaData[];
    storyboardId: number;
    callback?: () => void | null;
    frame_aspect_ratio: frameAspectRatio;
  }) {
    if (notificationIfCannotCreateNewFrame(FrameStore.getState())) return;

    const { filesUploaded, storyboardId, callback, frame_aspect_ratio } =
      params;
    if (!filesUploaded) return;
    const filesArray = Array.isArray(filesUploaded)
      ? filesUploaded
      : [filesUploaded];

    FrameActions.insertFrames.defer({
      storyboard_id: storyboardId,
      frame_count: filesArray.length,
      saveToHistory: false,
      callback: (newFrames) => {
        newFrames.map((frame, index) =>
          this.saveFilestackResponse({
            frame_aspect_ratio: frame_aspect_ratio,
            frame: frame,
            imageUrl: filesArray[index].url,
            mimetype: filesArray[index].mimetype,
          }),
        );

        if (callback) callback();
      },
    });
  }

  /**
   * @deprecated
   * This function is doing a bit too much, it shouldn't be concerned with
   * creating + modifying frames here.
   * ideally its clients would call openPicker, and pass the output to a
   * function that uses that output for creating new frames or updating
   * existing ones.
   *
   * Not sure where this function should live, but perhaps the frame store?
   * `newFramesFromUpload` & `updateFrameFromUpload`
   */
  handleOpenDialog(args: {
    frame_aspect_ratio: frameAspectRatio;
    initial_source?: fileStackSource;
    multi: boolean;
    storyboard_id: number;
    team_id: number;
    frame?: IFrame;
    callback?: pickerCallbackMultiple;
    replace?: boolean;
  }) {
    if (
      !args.replace &&
      notificationIfCannotCreateNewFrame(FrameStore.getState())
    ) {
      return;
    }

    if (BoordsConfig.CustomStorage) {
      if (args.multi) {
        return FileUploadActions.openPickerAndAddFrames.defer({
          frame_aspect_ratio: args.frame_aspect_ratio,
          storyboard_id: args.storyboard_id,
          team_id: args.team_id,
          maxFrames: 20,
          options: this.getOptions(args.frame_aspect_ratio),
          callback: args.callback ? () => args.callback!([]) : undefined,
        });
      } else if (args.frame) {
        return FileUploadActions.openPickerAndUpdateFrame.defer({
          frame: args.frame,
          frame_aspect_ratio: args.frame_aspect_ratio,
          team_id: args.team_id,
          options: this.getOptions(args.frame_aspect_ratio),
          callback: args.callback ? () => args.callback!([]) : undefined,
        });
      } else {
        throw new Error(
          'Either a frame or a multiple frame target must be specified',
        );
      }
    }

    this.handleOpenPicker({
      maxFiles: args.multi ? 999 : 1,
      team_id: args.team_id,
      options: {
        ...this.getOptions(args.frame_aspect_ratio),
        fromSources: this._setSourceOrder(args.initial_source),
        onUploadStarted: () => FrameActions.ensureUndoItems.defer(),
      },
      callback: (filesUploaded) => {
        const isMultiUpload = (
          filesUploaded: BoordsPickerFileMetaDataAmbiguous,
        ): filesUploaded is BoordsPickerFileMetaData[] => !!args.multi;

        if (filesUploaded && isMultiUpload(filesUploaded)) {
          this.handleAddResponseAsFrames({
            filesUploaded: filesUploaded,
            storyboardId: args.storyboard_id,
            frame_aspect_ratio: args.frame_aspect_ratio,
          });
          if (args.callback) args.callback(filesUploaded);
        } else if (filesUploaded && typeof args.frame !== 'undefined') {
          this.saveFilestackResponse({
            ...args,
            frame: args.frame,
            imageUrl: filesUploaded.url,
            mimetype: filesUploaded.mimetype,
          });
          if (args.callback) args.callback([filesUploaded]);
        } else if (!filesUploaded) {
          promiseErrorHandler(new Error('filesUploaded was empty'));
        } else {
          throw new Error(
            'Either a frame or a multiple frame target must be specified',
          );
        }
      },
    });
  }

  /** Allows for uploading arbitrary images into the frame editor. */
  handleOpenEditorDialog(args: { team_id: number; callback: pickerCallback }) {
    if (BoordsConfig.CustomStorage)
      return FileUploadActions.openPicker.defer({
        team_id: args.team_id,
        callback: args.callback,
        maxFiles: 1,
        options: {
          ...this.getOptions(), // Apply the default options
          imageMax: [1920, 1920], // And set a max width?
        },
      });

    this.client
      .picker({
        ...this.getOptions(), // Apply the default options
        imageMax: [1920, 1920], // And set a max width?
        onFileUploadFinished: (file) => args.callback(file),
        onFileUploadFailed: filestackError(args.callback),
      })
      .open()
      .catch(promiseErrorHandler);
  }

  /**
   * Opens the picker with a file which would allow the user to crop it.
   * At the point of writing this, only used for dropping files in the frame
   * editor.
   */
  handleOpenWithFile({
    file,
    callback,
    team_id,
    options = {},
  }: {
    file: File;
    callback: pickerCallback;
    team_id: number;
    options?: PickerOptions;
  }) {
    if (BoordsConfig.CustomStorage)
      return FileUploadActions.uploadFile.defer({
        file,
        team_id,
        callback,
      });

    this.client
      .picker({
        ...this.getOptions(), // Apply the default options
        imageMax: [1920, 1920], // And set a max width?
        ...options,
        onFileUploadFinished: (responseFile) => callback(responseFile),
        onFileUploadFailed: filestackError(callback),
      })
      .crop([file])
      .catch(promiseErrorHandler);
  }

  /**
   * Called directly from FrameStore's `_useFilestackUpload`, so this is one
   * of the only handlers in this store where we don't have to worry about
   * if people use customStorage
   */
  handleUploadFrameFile({
    frame,
    file,
    frame_aspect_ratio,
    onProgress,
    callback,
  }: {
    frame: IFrame;
    file: File;
    frame_aspect_ratio: frameAspectRatio;
    onProgress?: FileUploadProgressCallback;
    callback?: GenericCallback;
  }) {
    if (file.size > maxImageFileSize) {
      RequestActions.error.defer({
        key: 'sharedErrors.fileSizeExceeded',
        data: { maxSize: maxImageSizeInMB + 'MB' },
      });
      return callback?.(false);
    }

    if (!frame || !file || !frame_aspect_ratio)
      throw new Error(
        `Missing arguments, got ${JSON.stringify({
          frame,
          file,
          frame_aspect_ratio,
          callback,
        })}`,
      );

    this.client
      .upload(file, {
        onProgress: onProgress
          ? (progress) => onProgress(progress.totalPercent / 100)
          : undefined,
      })
      .then((response) =>
        this.saveFilestackResponse(
          {
            frame,
            frame_aspect_ratio,
            imageUrl: response.url,
            mimetype: response.mimetype,
          },
          callback,
        ),
      )
      .catch(filestackError(callback) as any);
  }

  /**
   * Stores a url and returns the filestack response, the Filestack SDK doesn't
   * type the response for some reason, so I'm not sure anymore what the
   * response is, please update when used.
   */
  handleStoreURL({
    url,
    callback,
  }: {
    url: string;
    callback: (response: BoordsPickerFileMetaData) => void;
  }) {
    this.client
      .storeURL(url)
      .then((response: any) => callback(response))
      .catch(filestackError(callback) as any);
  }

  /** Directly uploads a file, can check supported file types */
  handleUploadFile({
    file,
    callback,
    team_id,
    accept,
  }: {
    file: File;
    team_id: number;
    callback: pickerCallback;
    /** array of MIME types (e.g. image/png) */
    accept?: string[];
  }) {
    if (!isFileTypeAccepted(accept, file.type)) {
      RequestActions.error.defer({
        key: 'sharedErrors.unsupportedUpload',
        data: {
          fileType: file.type,
          formats: fileTypeListToString(accept),
        },
      });

      return callback();
    }

    if (BoordsConfig.CustomStorage)
      return FileUploadActions.uploadFile.defer({
        file,
        callback,
        team_id,
      });

    this.client
      .upload(file)
      .then((response) => callback(response))
      .catch(filestackError(callback) as any);
  }

  private applyTransformation(args: {
    mimetype: string;
    isThumbnail: boolean;
    orientation: AspectRatioName;
    url: string;
  }) {
    const showSmall = args.mimetype === 'image/gif' || args.isThumbnail;
    const targetSize = BoordsFrameSizeHelper(args.orientation);
    const thumbnailSize = getThumbnailSize(targetSize);

    const transformProps: TransformFilestackUrlProps = {
      width: showSmall ? thumbnailSize.width : targetSize.width,
      height: showSmall ? thumbnailSize.height : targetSize.height,
      format: 'jpg',
      url: args.url,
    };

    if (args.mimetype === 'image/gif') {
      transformProps.format = 'webp';
    }

    return transformFilestackUrl(transformProps);
  }

  private getOptions(frame_aspect_ratio?: frameAspectRatio): PickerOptions {
    return {
      allowManualRetry: true,
      // This is needed because otherwise filestack only sends us the full res
      // image, even when someone crops the image.
      uploadInBackground: false,
      maxSize: this.maxSize,
      accept: [
        ...imageMimeTypes,
        'application/x-photoshop',
        'application/photoshop',
        'application/psd',
      ],
      transformations: {
        crop: frame_aspect_ratio
          ? {
              force: false,
              aspectRatio: getAspectRatioNumerical(frame_aspect_ratio),
            }
          : {},
        rotate: true,
      },
    };
  }

  /**
   * Takes a response from filestack, transforms the image, and updates the
   * image's urls
   */
  private saveFilestackResponse(
    args: {
      frame_aspect_ratio: frameAspectRatio;
      imageUrl: string;
      mimetype: string;
      frame: IFrame;
      replace?: boolean;
    },
    callback?: GenericCallback,
  ) {
    const orientation = frameAspectRatioNormalizer(args.frame_aspect_ratio);

    const thumbnail_image_url = this.applyTransformation({
      url: args.imageUrl,
      mimetype: args.mimetype,
      orientation: orientation,
      isThumbnail: true,
    });

    const large_image_url = this.applyTransformation({
      url: args.imageUrl,
      mimetype: args.mimetype,
      orientation: orientation,
      isThumbnail: false,
    });

    FrameActions.updateFrameImageUrls.defer({
      frame: args.frame,
      thumbnail_image_url: thumbnail_image_url,
      large_image_url: large_image_url,
      // Don't set the background to large_image_url, because it ruins the
      // frames edited with the frame editor
      background_image_url: args.frame.background_image_url,
      replace: args.replace,
      createHistoryItem: true,
      callback: callback,
    });
  }
}

(window as any).FilestackStore = alt.createStore(
  FilestackStore,
  'FilestackStore',
);
