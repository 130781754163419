/** @prettier */
import * as React from 'react';
import type { PlayerStore, PlayerAudio } from '../../flux/stores/player';
import * as PropTypes from 'prop-types';
import { FrameIndex } from '../FrameIndex';
import { IconSpacer, IconSpacerGroup } from '../IconSpacer';
import * as Container from './Container2.jsx';
const DownloadOverlay = require('./DownloadOverlay').withContainer;
import { AudioUploader } from './AudioUploader';
import { PlayerActions } from '../../flux/actions/player';
import { PlayerSettings } from './PlayerSettings';
import { TourHintable } from '../tours/TourHintable';
import { IconButton } from 'blackbird/components/common/IconButton';
import PauseIcon from 'blackbird/images/icons/pause.svg';
import PlayIcon from 'blackbird/images/icons/play.svg';
import Slider from 'blackbird/components/slider/Slider';
import SoundIcon from 'blackbird/images/icons/sound-2.svg';
import DownloadIcon from 'blackbird/images/icons/download.svg';
import FullscreenIcon from 'blackbird/images/icons/fullscreen-2.svg';
import SettingsIcon from 'blackbird/images/icons/settings-3.svg';
import Popover from 'blackbird/components/common/popover/Popover';
import { ToursActions } from 'javascripts/flux/actions/tours';
import { WizardContext } from 'blackbird/components/wizard/WizardContext';
import { type availableTourNames } from 'javascripts/flux/stores/tours';

const noop = () => {};

interface Props extends PlayerStore {
  onSetIndex: (props) => void;
  theme: string;
  toggleFullscreen: () => void;
  audio: PlayerAudio;

  // Added by container
  togglePlay: () => void;
  setTimelineZoom: (number: number) => void;
}

class Navigation extends React.PureComponent<Props> {
  static displayName = 'PlayerNavigation';

  static contextType = WizardContext;
  context!: React.ContextType<typeof WizardContext>;

  // These proptypes are required by the player's container component to run
  // detect which properties to pass (for optimisation reasons).
  // I'm not so sure this specific container component is _really_ necessary
  static propTypes = {
    audio: PropTypes.object.isRequired,
    currentFrameIndex: PropTypes.number.isRequired,
    frames: PropTypes.array.isRequired,
    timelineZoom: PropTypes.number.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    storyboard: PropTypes.object.isRequired,
    isWaitingOnAudio: PropTypes.bool.isRequired,
    togglePlay: PropTypes.func.isRequired,
    toggleFullscreen: PropTypes.func.isRequired,

    frameDurationMismatch: PropTypes.bool.isRequired,
    onSetIndex: PropTypes.func.isRequired,
    setTimelineZoom: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
  };

  tourIsActive = (tourName: availableTourNames) =>
    ToursStore.state.currentTours.length > 0 &&
    ToursStore.state.currentTours[0].name === tourName;

  onPlayClick = () => {
    this.props.togglePlay();
    Track.event.defer('play_animatic');
    if (this.tourIsActive('wizardAnimatic')) {
      ToursActions.advanceTour.defer('wizardAnimatic');
    }
    this.context.handleComplete('play-animatic');
  };

  render() {
    const { currentFrameIndex, isPlaying, frames, isWaitingOnAudio } =
      this.props;

    return (
      <div className={'mb-4 flex justify-between'}>
        <IconSpacer>
          <TourHintable
            step="pressPlay"
            overlayPosition="top-start"
            distance={0}
            preset="rounded"
            deferred
            hideNext
          >
            <IconButton
              iconClassName="h-8 w-auto"
              icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
              aria-label={isPlaying ? 'Pause' : 'Play'}
              disabled={isWaitingOnAudio}
              onClick={this.onPlayClick}
              hasTooltip={false}
            />
          </TourHintable>

          <FrameIndex frameNo={currentFrameIndex! + 1} of={frames.length} />
        </IconSpacer>

        <IconSpacer>
          <Slider
            value={this.props.timelineZoom}
            onChange={this.props.setTimelineZoom}
            max={1}
            step={0.01}
          />

          <IconSpacerGroup separatorLocation="start">
            {this.props.isEditable && (
              <Popover placement="top" distance={16}>
                <Popover.Button>
                  <IconButton
                    onClick={noop}
                    icon={<SoundIcon />}
                    aria-label="Upload sound"
                  />
                </Popover.Button>
                <Popover.Panel className="w-[300px] rounded-md shadow-md overflow-hidden bg-white p-4 z-modal">
                  <AudioUploader
                    {...this.props}
                    resetAudio={PlayerActions.resetAudio}
                    removeAudio={PlayerActions.removeAudio}
                  />
                </Popover.Panel>
              </Popover>
            )}

            {this.props.isEditable && (
              <Popover placement="top" distance={16} portal>
                <Popover.Button>
                  <IconButton
                    onClick={noop}
                    icon={<DownloadIcon />}
                    aria-label="Download as MP4"
                  />
                </Popover.Button>
                <Popover.Panel className="w-[300px] rounded-md shadow-md overflow-hidden bg-white p-4 z-modal">
                  <DownloadOverlay
                    storyboardId={this.props.storyboard!.id}
                    storyboardOwnerId={
                      this.props.storyboard!.project.owner.user_id
                    }
                    frameDurationMismatch={this.props.frameDurationMismatch}
                  />
                </Popover.Panel>
              </Popover>
            )}

            <IconButton
              icon={<FullscreenIcon />}
              aria-label="Full screen"
              onClick={this.props.toggleFullscreen}
            />

            {this.props.isEditable && (
              <Popover distance={16} placement="top" portal>
                <Popover.Button>
                  <IconButton
                    color={
                      this.props.frameDurationMismatch ? 'warning' : undefined
                    }
                    aria-label={`Settings${
                      this.props.frameDurationMismatch
                        ? ' (fix the frame duration mismatch)'
                        : ''
                    }`}
                    icon={<SettingsIcon />}
                    // badgeColor={
                    //   this.props.frameDurationMismatch ? 'yellow' : undefined
                    // }
                  />
                </Popover.Button>
                <Popover.Panel className="w-[300px] rounded-md shadow-md overflow-y-auto bg-white p-4 z-modal">
                  <PlayerSettings storyboard={this.props.storyboard!} />
                </Popover.Panel>
              </Popover>
            )}
          </IconSpacerGroup>
        </IconSpacer>
      </div>
    );
  }
}

export default Container(Navigation, ['togglePlay', 'setTimelineZoom']);
