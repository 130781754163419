/** @prettier */
/* globals WebFont */
/* eslint-disable max-params */
const webfont =
  'https://cdn.jsdelivr.net/npm/webfontloader@1.6.28/webfontloader.min.js';
const _ = require('underscore');
const memoize = require('memoizee');
require('./load-script');

const loadWebFontLoader = memoize(
  () =>
    new Promise(
      (resolve, reject) => {
        loadScript(webfont, function (err) {
          if (err) {
            return reject(err);
          }
          resolve();
        });
      },
      { promise: true },
    ),
);

/**
 * This makes sure we only load each variation once. It memoizes by comparing
 * all the arguments.
 */
const load = memoize(
  (fontName, fontWeight, variations, display) =>
    new Promise((resolve, reject) => {
      // prettier-ignore
      const fontString = _.compact([
      fontName.replace(/ /g, '+'),
      fontWeight,
      variations
      ]).join(':') + `&display=${display ?? 'swap'}`;

      loadWebFontLoader().then(() => {
        // Create a DOMnode that requires the font, so that it's actually loaded.
        const fontNode = document.createElement('div');
        fontNode.innerHTML = 'LOADING FONT';
        fontNode.style.fontFamily = fontName;
        fontNode.style.fontWeight = fontWeight;
        fontNode.style.visibility = '';
        fontNode.style.opacity = 0.05;

        document.body.appendChild(fontNode);

        WebFont.load({
          google: { families: [fontString] },
          timeout: 2000,
          classes: false,
          fontactive: () => {
            setTimeout(resolve, 150);
            setTimeout(() => fontNode.remove(), 500);
          },
          fontinactive: (fontName) => {
            fontNode.remove();
            reject(new Error(`Could not load ${fontName}`));
          },
        });
      }, reject);
    }),
  { promise: true, length: 4, size: 10 },
);

const loadMainFonts = () =>
  loadWebFontLoader().then(() =>
    WebFont.load({
      classes: false,
      custom: {
        families: ['matter', 'abc_ginto_nordmedium'],
        urls: ['https://fonts.boords.com/blackbird/fonts.css'],
      },
    }),
  );

module.exports = { load, loadMainFonts };
