/** @prettier */

import React from 'react';
import Info from '../../images/icons/info.svg';
import CheckCircle from '../../images/icons/checkmark.svg';
import WarningRound from '../../images/icons/warning-round.svg';
import WarningTriangle from '../../images/icons/warning-2.svg';
import { FeedbackKind } from './types';

export const feedbackBackgrounds: { [key in FeedbackKind]: string } = {
  info: 'bg-decorative-blue',
  success: 'bg-surface-success',
  error: 'bg-surface-critical',
  warning: 'bg-surface-warning',
  fancy: 'bg-gradient-to-r from-brand-yellow/50 to-brand-pink/50',
  alert: 'bg-brand-yellow/50',
  subtle: 'bg-surface-light',
};

export const feedbackIcons: { [key in FeedbackKind]: React.ReactElement } = {
  info: <Info />,
  fancy: <Info />,
  alert: <Info />,
  subtle: <Info />,
  success: <CheckCircle />,
  warning: <WarningRound />,
  error: <WarningTriangle />,
};
