/** @format */

import * as React from 'react';
import { type TFunction, useTranslation } from 'react-i18next';
import { memoize } from 'underscore';
import { notFalse } from 'javascripts/helpers/notUndefined';
import Dialog from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Select from 'blackbird/components/form/select/Select';
import Button from 'blackbird/components/button/Button';
import Toggle from 'blackbird/components/toggle/Toggle';
import {
  useTemplates,
  type TemplateField,
  type AspectRatio,
} from './TemplatesContext';
import type { Option } from 'blackbird/components/common/types';
import { moveInArray } from 'javascripts/helpers/move-in-array';
import type { sortableOnChangeHandler } from 'blackbird/helpers/hooks/useSortable';
import { FrameFieldItem } from 'javascripts/components/storyboard/Settings/FrameFieldItem';
import { TeamContext } from '../team/TeamContext';
import { TeamUpgradePill } from '../team/TeamUpgradePill';

const getSizes = memoize((t: TFunction): Option[] => {
  return [
    {
      label: t('aspectRatios.portrait'),
      value: '9x16',
    },
    {
      label: t('aspectRatios.landscape'),
      value: '16x9',
    },
    {
      label: t('aspectRatios.square'),
      value: '1x1',
    },
    {
      label: t('aspectRatios.social'),
      value: '4:5',
    },
    {
      label: t('aspectRatios.tv'),
      value: '4:3',
    },
    {
      label: t('aspectRatios.widescreen'),
      value: '1.85:1',
    },
    {
      label: t('aspectRatios.anamorphic'),
      value: '2.4:1',
    },
  ].filter(notFalse);
});

const getFrameCounts = (): Option[] => {
  return [
    { label: 'No frames', value: '0' },
    ...Array.from({ length: 50 }, (_, i) => ({
      label: `${i + 1} ${i === 0 ? 'frame' : 'frames'}`,
      value: (i + 1).toString(),
    })),
  ];
};

const FIELD_LIMIT = 50;

const DEFAULT_FIELDS: TemplateField[] = [
  {
    id: `field-${Date.now()}-1`,
    label: 'Voiceover',
    icon: 'mic',
    isEnabled: true,
  },
  {
    id: `field-${Date.now()}-2`,
    label: 'Direction',
    icon: 'action',
    isEnabled: true,
  },
  {
    id: `field-${Date.now()}-3`,
    label: 'Notes',
    icon: 'clipboard',
    isEnabled: true,
  },
];

export const TemplateFormDialog: React.FC = () => {
  const { t } = useTranslation();
  const {
    isTemplateModalOpen,
    setIsTemplateModalOpen,
    createTemplate,
    updateTemplate,
    currentTemplate,
    setCurrentTemplate,
  } = useTemplates();

  const { teamData } = React.useContext(TeamContext);

  const [name, setName] = React.useState<string>('');
  const [aspectRatio, setAspectRatio] = React.useState<AspectRatio>('16x9');
  const [fields, setFields] = React.useState<TemplateField[]>(DEFAULT_FIELDS);
  const [fieldVersion, setFieldVersion] = React.useState(0);
  const [hasComments, setHasComments] = React.useState(false);
  const [hasPassword, setHasPassword] = React.useState(false);
  const [allowCommentsInOldVersions, setAllowCommentsInOldVersions] =
    React.useState(false);
  const [shareWithVersionNumber, setShareWithVersionNumber] =
    React.useState(false);
  const [shareWithVersionSwitching, setShareWithVersionSwitching] =
    React.useState(false);
  const [shareWithVersionNotification, setShareWithVersionNotification] =
    React.useState(true);
  const [frameCount, setFrameCount] = React.useState('0');
  const [placeholder, setPlaceholder] = React.useState('');

  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  React.useEffect(() => {
    if (currentTemplate) {
      setName(currentTemplate.name);
      setAspectRatio(currentTemplate.aspect_ratio);
      setFields(currentTemplate.fields);
      setHasComments(currentTemplate.has_comments ?? false);
      setHasPassword(currentTemplate.has_password ?? false);
      setFrameCount(currentTemplate.frame_count?.toString() ?? '0');
      setPlaceholder(currentTemplate.placeholder ?? '');
      setAllowCommentsInOldVersions(
        currentTemplate.allow_comments_in_old_versions ?? false,
      );
      setShareWithVersionNumber(
        currentTemplate.share_with_version_number ?? false,
      );
      setShareWithVersionSwitching(
        currentTemplate.share_with_version_switching ?? false,
      );
      setShareWithVersionNotification(
        currentTemplate.share_with_version_notification ?? true,
      );
    } else {
      setName('');
      setAspectRatio('16x9');
      setFields(DEFAULT_FIELDS);
      setHasComments(false);
      setHasPassword(false);
      setFrameCount('0');
      setPlaceholder('');
      setAllowCommentsInOldVersions(false);
      setShareWithVersionNumber(false);
      setShareWithVersionSwitching(false);
      setShareWithVersionNotification(true);
    }
  }, [currentTemplate]);

  const isFormValid = React.useMemo(() => {
    return (
      name.length > 0 &&
      aspectRatio &&
      fields.every((field) => field.label.trim().length > 0)
    );
  }, [name, aspectRatio, fields]);

  const confirmBtnProps = {
    disabled: !isFormValid,
  };

  const handleClose = () => {
    setIsTemplateModalOpen(false);
    setCurrentTemplate(null);
    setFieldVersion(0);
  };

  const handleAddField = () => {
    if (fields.length < FIELD_LIMIT) {
      const newField: TemplateField = {
        id: `field-${Date.now()}`,
        label: '',
        icon: 'clipboard',
        isEnabled: true,
      };
      setFields((prevFields) => [...prevFields, newField]);
      setFieldVersion((prev) => prev + 1);
    }
  };

  const handleFieldChange = React.useCallback(
    (id: string, newState: Partial<TemplateField>) => {
      setFields((prevFields) =>
        prevFields.map((field) =>
          field.id === id ? { ...field, ...newState } : field,
        ),
      );
    },
    [],
  );

  const handleDeleteField = React.useCallback((id: string) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== id));
  }, []);

  const handleReorder = React.useCallback<sortableOnChangeHandler>(
    (oldIndex, newIndex) => {
      setFields((prevFields) =>
        moveInArray(prevFields, [prevFields[oldIndex]], newIndex),
      );
    },
    [],
  );

  React.useEffect(() => {
    if (fields.length > 0) {
      const lastIndex = fields.length - 1;
      inputRefs.current[lastIndex]?.focus();
    }
  }, [fields.length]);

  const handleSubmit = () => {
    const templateData = {
      name,
      aspect_ratio: aspectRatio,
      fields,
      has_comments: hasComments,
      has_password: hasPassword,
      frame_count: parseInt(frameCount, 10),
      placeholder,
      allow_comments_in_old_versions: allowCommentsInOldVersions,
      share_with_version_number: shareWithVersionNumber,
      share_with_version_switching: shareWithVersionSwitching,
      share_with_version_notification: shareWithVersionNotification,
    };
    if (currentTemplate) {
      updateTemplate(currentTemplate.id as any, templateData);
      Track.event.defer('template_updated', {
        posthogCapture: true,
      });
    } else {
      createTemplate(templateData);
      Track.event.defer('template_created', {
        posthogCapture: true,
      });
    }
    handleClose();
  };

  return !isTemplateModalOpen ? null : (
    <Dialog
      isDark
      wrapperClasses="!pb-0"
      size={'md'}
      title={currentTemplate ? `Edit template` : `Create a template`}
      subtitle={`Set up standard fields and settings to use across your chosen storyboards.`}
      isOpen
      keepOpen
      confirmText={currentTemplate ? `Update` : `Create`}
      confirmBtnProps={confirmBtnProps}
      onConfirmBtnClick={handleSubmit}
      onCancelBtnClick={handleClose}
      onCloseBtnClick={handleClose}
      onEscapeOrOutsideClick={handleClose}
    >
      <div className="grid grid-cols-2 gap-10">
        <div>
          <TextInput
            inputSize="md"
            autoFocus
            className="mb-4"
            labelClasses="font-semibold"
            label={<span className="text-sm">{`Label`}</span>}
            placeholder={`Add a description for your template`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            inputSize="md"
            className="mb-6"
            labelClasses="font-semibold"
            label={<span className="text-sm">{`Name Placeholder`}</span>}
            placeholder={`Shown in new storyboard name field`}
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
          />

          {/* Sharing Settings */}
          <div className="mb-4">
            <div className="mb-3 text-sm font-semibold text-type-primary">{`Sharing Settings`}</div>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <span className="text-sm">{`Password protection`}</span>
                {!teamData?.has_password_protection ? (
                  <TeamUpgradePill />
                ) : (
                  <Toggle value={hasPassword} onChange={setHasPassword} />
                )}
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm">{`Enable comments`}</span>
                <Toggle
                  value={hasComments}
                  onChange={(value) => {
                    setHasComments(value);
                    if (!value) {
                      setAllowCommentsInOldVersions(false);
                    }
                  }}
                />
              </div>
              {hasComments && (
                <div className="flex items-center justify-between">
                  <span className="text-sm">{`Allow comments in old versions`}</span>
                  <Toggle
                    value={allowCommentsInOldVersions}
                    onChange={setAllowCommentsInOldVersions}
                  />
                </div>
              )}
              <div className="flex items-center justify-between">
                <span className="text-sm">{`Share with version number`}</span>
                <Toggle
                  value={shareWithVersionNumber}
                  onChange={(value) => {
                    setShareWithVersionNumber(value);
                    if (!value) {
                      setShareWithVersionSwitching(false);
                      setShareWithVersionNotification(false);
                    }
                  }}
                />
              </div>
              {shareWithVersionNumber && (
                <>
                  <div className="flex items-center justify-between">
                    <span className="text-sm">{`Allow version switching`}</span>
                    <Toggle
                      value={shareWithVersionSwitching}
                      onChange={setShareWithVersionSwitching}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm">{`Show version notification`}</span>
                    <Toggle
                      value={shareWithVersionNotification}
                      onChange={setShareWithVersionNotification}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Select
            disableInput
            label={`Image Aspect Ratio`}
            size="md"
            selectContainerClassName="w-full mb-4"
            options={getSizes(t)}
            value={aspectRatio}
            onChange={(value) => setAspectRatio(value as AspectRatio)}
          />

          <Select
            disableInput
            label={`Frame Count`}
            size="md"
            selectContainerClassName="w-full mb-4"
            options={getFrameCounts()}
            value={frameCount}
            onChange={(value) => setFrameCount(value)}
          />
          <div className="mt-0.5 mb-2 text-sm font-semibold text-type-primary">{`Text Fields`}</div>
          <div className="mb-4 space-y-4 -ml-[1.35rem] -mr-[1.75rem]">
            {fields.map((field, index) => (
              <FrameFieldItem
                id={field.id}
                key={field.id}
                name={field.id}
                label={field.label}
                icon={field.icon}
                showActionsOnHover={true}
                isEnabled={field.isEnabled}
                placeholder={`Field ${index + 1}`}
                fieldVersion={fieldVersion}
                onChange={handleFieldChange}
                onDelete={handleDeleteField}
                draggable={true}
                onReorder={handleReorder}
                onCommitReorder={() => {}}
                index={index}
                ref={(r) => (inputRefs.current[index] = r)}
              />
            ))}
          </div>
          <div className="mb-4">
            <Button
              type="secondary"
              size="sm"
              onClick={handleAddField}
              disabled={fields.length >= FIELD_LIMIT}
            >
              {`Add Field`}
            </Button>
            {fields.length >= FIELD_LIMIT && (
              <div className="mt-1 text-xs text-center text-type-subdued">
                {`Field limit reached (${FIELD_LIMIT})`}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
