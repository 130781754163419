/** @prettier */
/* eslint-disable react/jsx-handler-names */
import * as React from 'react';
import GridViewIcon from '../../../images/icons/grid-view.svg';
import ShotlistIcon from '../../../images/icons/list-view.svg';
import UndoIcon from '../../../images/icons/undo.svg';
import DuplicateIcon from '../../../images/icons/duplicate.svg';
import GroupIcon from '../../../images/icons/group.svg';
import UngroupIcon from '../../../images/icons/ungroup.svg';
import SelectAllIcon from '../../../images/icons/select-all.svg';
import DeleteIcon from '../../../images/icons/delete-20.svg';
import RedoIcon from '../../../images/icons/redo.svg';
import CommentIcon from '../../../images/icons/comment-2.svg';
import EditIcon from '../../../images/icons/edit.svg';
import MoveIcon from '../../../images/icons/move.svg';
import SidePanelExpandIcon from '../../../images/icons/side-panel-expand.svg';
import SidePanelCollapseIcon from '../../../images/icons/side-panel-collapse.svg';
import { IconButton, IconButtonprops } from '../../common/IconButton';
import * as shotlistConstants from '../../../../javascripts/components/shotlist/ShotlistConstants';
import classnames from 'classnames';
import Slider from '../../slider/Slider';
import { useStore } from '../../../../javascripts/helpers/useStore';
import type {
  allowedStoryboardViews,
  StoryboardStore,
} from '../../../../javascripts/flux/stores/storyboard';
import type {
  IStoryboardZoomSettings,
  StoryboardZoomStore,
} from '../../../../javascripts/flux/stores/storyboardZoom';
import type {
  FrameUserActionInfo,
  FrameUserActionKeys,
} from '../../../../javascripts/components/shared/FrameUserActions';
import Button from '../../button/Button';
import {
  IconSpacer,
  IconSpacerGroup,
} from '../../../../javascripts/components/IconSpacer';
import type { FrameStore } from '../../../../javascripts/flux/stores/frame';
import StatusDropdown from '../../menus/StatusDropdown';
import { FrameStatus } from '../../menus/constants';
import type { IStoryboardInStore } from 'javascripts/types/storyboard';
import { notificationIfNoPermission } from '../../../../javascripts/helpers/has-permission';
import navigateToRoute from '../../../../javascripts/helpers/router/navigate-to-route';
import { useTranslation } from 'react-i18next';
import Popover from 'blackbird/components/common/popover/Popover';
import {
  ImageSourcesMultiPanel,
  ImageSourcesMultiPanelDefaultWidth,
} from 'javascripts/components/frames/AddImageMultipanel/ImageSourcesMultiPanel';
import {
  cannotCreateNewFrame,
  notificationIfCannotCreateNewFrame,
} from 'javascripts/helpers/can-create-new-frame';
import StatusIcon from 'blackbird/images/icons/status.svg';
import { TourHintable } from 'javascripts/components/tours/TourHintable';
import { useScreenSizeMonitor } from 'javascripts/components/shared/useScreenSizeMonitor';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import { PlayIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { isFunction } from 'underscore';

const viewButtonClassName = (active) =>
  classnames(
    'w-6 py-4 border-b-2',
    active ? 'border-black' : 'border-transparent',
  );

export const FrameProcessingBar: React.VFC<{
  onChangeView: (newView: string) => void;
  onChangeZoom: (newZoom: number) => void;
  onSidebarToggle: (open?: boolean) => void;
  /**
   * What actions is the user able/allowed to perform based on permissions and
   * selected frames */
  actions: Record<FrameUserActionKeys, FrameUserActionInfo>;
  onStatusChange?: (status: FrameStatus) => void;
  children?: React.ReactChild | ((padding: string) => React.ReactChild);
}> = (props) => {
  const { t } = useTranslation();
  const { onChangeView, onChangeZoom, onSidebarToggle, actions } = props;

  const ButtonForAction = React.useCallback(
    (
      props: {
        action?: FrameUserActionInfo;
        icon?: React.ReactElement;
      } & Partial<IconButtonprops>,
    ) => {
      const { action, icon, ...rest } = props;
      return action ? (
        <IconButton
          aria-label={t(action.labelKey, action.labelData)}
          onClick={action.onClick}
          icon={icon!}
          disabled={action.disabled}
          {...rest}
        />
      ) : null;
    },
    [t],
  );

  const view = useStore<allowedStoryboardViews, StoryboardStore>(
    'storyboard',
    (s) => s.view,
  );
  const storyboard = useStore<IStoryboardInStore, StoryboardStore>(
    'storyboard',
    (s) => s.storyboard,
  );

  const showShotlistCommentColumn = useStore<boolean, StoryboardStore>(
    'storyboard',
    (s) => s.showGridViewCommentColumn,
  );
  const panelbarIsOpen = useStore<boolean>('panelbar', (s) => s.isOpen);
  const zoomSettings = useStore<IStoryboardZoomSettings, StoryboardZoomStore>(
    'storyboardZoom',
    (s) => s.zoomSettings,
  );

  const frameStore = useStore<FrameStore, FrameStore>('frame', (s) => s);
  const selectedFrames = frameStore.selectedFrames;
  const isReplace = selectedFrames.length === 1;
  const multipleSelected = selectedFrames.length > 1;

  const handleViewButtonClick = React.useCallback<
    (newView: string) => React.MouseEventHandler<HTMLButtonElement>
  >(
    (newView) => () => {
      if (view !== newView) onChangeView(newView);
    },
    [onChangeView, view],
  );

  const handleSidebarToggle = () => {
    onSidebarToggle(!panelbarIsOpen);
  };

  const handleAnimaticClick = () => {
    const storyboardOwnerId = storyboard.project.owner.user_id;
    if (notificationIfNoPermission(storyboardOwnerId, 'animatics')) return;

    navigateToRoute('player', {
      slug: storyboard.slug,
      frameIndex: selectedFrames[0] ? selectedFrames[0].sort_order : 1,
    });
  };
  const handleAddImages = () => {
    if (notificationIfCannotCreateNewFrame(frameStore)) return;
  };

  const cannotCreateFrame = cannotCreateNewFrame(frameStore);

  // We have to reduce space a bit to accommodate both the panelbar and the 'x
  // frames selected' text. So if we need to save space we set the iconSpacer to
  // narrow
  const enoughSpace = useScreenSizeMonitor('frameProcessingBar', 1390);
  const saveSpace = panelbarIsOpen && !enoughSpace && selectedFrames.length > 0;
  const padding = 'pl-5 pr-2';

  return (
    <div className="sticky bg-white shadow-xs top-banner z-header">
      <div className={classNames('flex items-center justify-between', padding)}>
        <div className="flex items-center gap-6">
          <IconButton
            className={viewButtonClassName(view === 'grid')}
            aria-label={t('storyboard.gridView.button')}
            iconDisabled={view !== 'grid'}
            onClick={handleViewButtonClick('grid')}
            name="grid"
            icon={<GridViewIcon />}
          />

          <TourHintable
            step="viewShotList"
            canShow={view !== shotlistConstants.SHOTLIST}
          >
            <IconButton
              className={viewButtonClassName(
                view === shotlistConstants.SHOTLIST,
              )}
              aria-label={t('storyboard.shotList.button')}
              iconDisabled={view !== shotlistConstants.SHOTLIST}
              onClick={handleViewButtonClick(shotlistConstants.SHOTLIST)}
              name={shotlistConstants.SHOTLIST}
              icon={<ShotlistIcon />}
            />
          </TourHintable>

          {view === 'grid' ? (
            selectedFrames.length > 0 ? (
              <div className="ml-3 text-sm select-none">
                {t('counters.selected_frames', {
                  count: selectedFrames.length,
                })}
              </div>
            ) : (
              <Slider
                className="ml-2"
                value={zoomSettings.columns}
                min={zoomSettings.min}
                max={zoomSettings.max}
                onChange={onChangeZoom}
                reverse
                step={1}
              />
            )
          ) : (
            <></>
          )}
        </div>

        <IconSpacer spacing={saveSpace ? 'narrow' : undefined}>
          <IconSpacerGroup>
            <ButtonForAction action={actions.undo} icon={<UndoIcon />} />
            <ButtonForAction action={actions.redo} icon={<RedoIcon />} />
          </IconSpacerGroup>

          {view === 'grid' ? (
            <>
              {selectedFrames.length > 0 && (
                <>
                  <IconSpacerGroup>
                    <ButtonForAction
                      action={actions.edit}
                      icon={<EditIcon />}
                      aria-label={t('actions.frames.edit')}
                    />
                    <StatusDropdown
                      as={IconButton}
                      disabled={actions.status.disabled}
                      status={FrameStatus.PROGRESS}
                      onStatusChange={props.onStatusChange}
                      aria-label={t('actions.frames.set_status')}
                      icon={<StatusIcon />}
                      iconClassName="h-6 w-auto pointer-events-none"
                      containerClassName="dont-deselect"
                    />

                    <ButtonForAction
                      action={actions.comment}
                      icon={<CommentIcon />}
                    />
                  </IconSpacerGroup>

                  <IconSpacerGroup>
                    <ButtonForAction
                      action={actions.duplicate}
                      icon={<DuplicateIcon />}
                    />

                    {actions.transfer?.component?.()}

                    <ButtonForAction
                      action={actions.move}
                      icon={<MoveIcon />}
                    />

                    <ButtonForAction
                      action={actions.group}
                      icon={<GroupIcon />}
                    />
                    <ButtonForAction
                      action={actions.ungroup}
                      icon={<UngroupIcon />}
                    />
                  </IconSpacerGroup>
                </>
              )}

              <IconSpacerGroup>
                <ButtonForAction
                  action={actions.selectAll}
                  icon={<SelectAllIcon />}
                />
                <ButtonForAction
                  action={actions.deselectAll}
                  icon={<SelectAllIcon />}
                />
                <ButtonForAction
                  action={actions.delete}
                  icon={<DeleteIcon />}
                />
              </IconSpacerGroup>
            </>
          ) : null}

          <Popover placement="bottom" distance={10} portal>
            <Popover.Button as="div">
              {!isReplace ? (
                <>
                  {cannotCreateFrame ? (
                    <></>
                  ) : (
                    <Button
                      disabled={multipleSelected}
                      type="secondary"
                      onClick={handleAddImages}
                      size="sm"
                      className="flex-shrink-0"
                    >
                      {t('actions.frames.add_images')}
                    </Button>
                  )}
                </>
              ) : (
                <Button type="secondary" size="sm" className="flex-shrink-0">
                  {t('actions.frames.replace')}
                </Button>
              )}
            </Popover.Button>
            <Popover.Panel className="z-40">
              {isReplace ? (
                <ImageSourcesMultiPanel
                  width={ImageSourcesMultiPanelDefaultWidth}
                  storyboard={storyboard}
                  currentFrame={selectedFrames[0]}
                  replace
                />
              ) : (
                <ImageSourcesMultiPanel
                  width={ImageSourcesMultiPanelDefaultWidth}
                  storyboard={storyboard}
                  replace={false}
                />
              )}
            </Popover.Panel>
          </Popover>

          <TourHintable step="openAnimaticTool">
            <Button
              icon={
                <div className="w-3 h-3">
                  <PlayIcon className="w-3.5 h-3.5 mt-1.5 -ml-0.5" />
                </div>
              }
              iconLocation="left"
              type="secondary"
              size="sm"
              onClick={handleAnimaticClick}
            >
              {t('actions.frames.animatic_button')}
            </Button>
          </TourHintable>

          {view === shotlistConstants.SHOTLIST && (
            <IconButton
              aria-label={
                showShotlistCommentColumn
                  ? t('storyboard.shotList.hideComments')
                  : t('storyboard.shotList.showComments')
              }
              onClick={StoryboardActions.toggleGridViewCommentColumn.defer}
              icon={<CommentIcon />}
            />
          )}

          <IconButton
            onClick={handleSidebarToggle}
            aria-label={
              panelbarIsOpen
                ? t('actions.sidebar.close')
                : t('actions.sidebar.open')
            }
            icon={
              panelbarIsOpen ? (
                <SidePanelCollapseIcon />
              ) : (
                <SidePanelExpandIcon />
              )
            }
          />
        </IconSpacer>
      </div>

      {isFunction(props.children) ? props.children(padding) : props.children}
    </div>
  );
};
