/** @format */
import { StoryboardActions } from './actions/storyboard';
import { StoryboardZoomActions } from './actions/storyboardZoom';
import { CommentActions } from './actions/comment';
import { StoryboardAnalysisActions } from './actions/storyboardAnalysis';
import './actions/changelog';
import './actions/flyover';
import './actions/project';
import './actions/storyboard';
import './actions/avatar';

/** An (incomplete) list of all the actions, used for passing to the
 * `storeContextProvider`  */
export const allTheActions = {
  ChangelogActions,
  StoryboardActions,
  FlyoverActions,
  ProjectActions,
  StoryboardAnalysisActions,
  AvatarActions,
  CommentActions,
  StoryboardZoomActions,
};

export type allTheActionNames = keyof typeof allTheActions;
