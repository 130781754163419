/** @prettier */
import { PDFCoverPicture, PDFLayoutProps, DocInfo } from '../types';
import { createPDFImage } from './PDFImage';
import { loadPDFImageWithFallback } from './loadFrameImages';
import { createTextFactory } from './createText';
import makeItFit from 'javascripts/helpers/makeItFit';

export const createPDFCoverPage = (
  docInfo: DocInfo,
  cover: PDFCoverPicture,
  props: PDFLayoutProps,
) =>
  new Promise<void>((resolve, reject) => {
    if (!cover.enabled) return resolve();
    const { doc, pageFormat } = docInfo;
    const style = props.style;
    doc.addPage(pageFormat.format, pageFormat.orientation);
    const pageSize = doc.internal.pageSize;

    if (cover.type === 'logo') {
      doc.setFillColor(cover.backgroundColor || '#ffffff');
      doc.rect(0, 0, pageSize.width, pageSize.height, 'F');
      const availableWidth =
        pageSize.width - style.margin.left - style.margin.right;

      const createText = createTextFactory(docInfo);
      createText(props.footerText || '', {
        x: style.margin.left,
        y: pageSize.height - style.margin.bottom,
        originY: 'bottom',
        maxWidth: availableWidth,
        fontSize: style.coverFontSize,
        color: cover.textColor,
      });

      if (cover.footerText) {
        createText(cover.footerText, {
          x: pageSize.width - style.margin.right,
          y: pageSize.height - style.margin.bottom,
          originY: 'bottom',
          align: 'right',
          maxWidth: availableWidth,
          fontSize: style.coverFontSize,
          color: cover.textColor,
        });
      }

      const maxHeight = pageSize.height * 0.8;

      loadPDFImageWithFallback(cover.url, 'landscape', (err, result) => {
        if (err || !result) return reject(err);
        const { width, height } = makeItFit(
          { width: result.width, height: result.height },
          {
            width: Math.min(result.width, style.coverLogoMaxWidth),
            height: Math.min(result.height, maxHeight),
          },
        );

        createPDFImage(docInfo, {
          x: (pageSize.width - width) / 2,
          y: (pageSize.height - height) / 2,
          maxWidth: width,
          maxHeight: height,
          imageInfo: result,
        });

        resolve();
      });
    } else if (cover.type === 'fullPageImage') {
      doc.setFillColor('#ffffff');
      doc.rect(0, 0, pageSize.width, pageSize.height, 'F');

      loadPDFImageWithFallback(cover.url, 'landscape', (err, result) => {
        if (err || !result) return reject(err);

        const { width, height } = makeItFit(
          { width: result.width, height: result.height },
          {
            width: Math.min(result.width, pageSize.height),
            height: Math.min(result.height, pageSize.height),
          },
        );

        createPDFImage(docInfo, {
          x: (pageSize.width - width) / 2,
          y: (pageSize.height - height) / 2,
          maxWidth: width,
          maxHeight: height,
          imageInfo: result,
        });

        resolve();
      });
    } else {
      resolve();
    }
  });
