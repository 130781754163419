/** @prettier */
import * as React from 'react';
import { DashboardLayout } from './DashboardLayout';
import { DefaultProviders } from '../shared/DefaultContextProviders';

const DashboardContainer = (props) => (
  <DefaultProviders>
    <DashboardLayout {...props} />
  </DefaultProviders>
);

(window as any).DashboardContainer = DashboardContainer;
