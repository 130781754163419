/** @format */
import * as fitjs from 'fit.js';
import type { WidthAndHeight } from 'javascripts/types/frame';

interface Options {
  /** defaults to `center` */
  hAlign: 'left' | 'right' | 'center';
  /** defaults to `center` */
  vAlign: 'top' | 'bottom' | 'center';

  /** Fit within the area or fill it all (true), defaults to `false` */
  cover: boolean;

  /* Fit again automatically on window resize */
  watch: boolean;

  /** Apply computed transformations (true) or just return the transformation
definition (false), defaults to `false`  */
  apply: boolean;
}

const defaultOptions: Options = {
  hAlign: 'center',
  vAlign: 'center',
  apply: false,
  watch: false,
  cover: false,
};

interface ItemDimensions extends WidthAndHeight {
  x: number;
  y: number;
}

interface MakeItFitResults extends ItemDimensions {
  tx: number;
  ty: number;
  scale: number;
}

const defaultDimensions: ItemDimensions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

/** Our own wrapper of fit.js. It mostly exists because fitjs is not easily
 * imported and doesn't have types. Additionally this wrapper will add sensible
 * default values which will make it less prone to `NaN`s */
function makeItFit(
  element: Partial<ItemDimensions> | HTMLElement,
  targetElement: Partial<ItemDimensions> | HTMLElement,
  options?: Partial<typeof defaultOptions>,
): MakeItFitResults {
  return fitjs(
    element instanceof HTMLElement
      ? element
      : { ...defaultDimensions, ...element },
    targetElement instanceof HTMLElement
      ? targetElement
      : { ...defaultDimensions, ...targetElement },
    { ...defaultOptions, ...options },
  );
}

export default makeItFit;
