const unescape = require('lodash.unescape');
const { each } = require('underscore')
const ReactDOM = require('react-dom');
const React = require('react');

module.exports = () => {
  each(document.querySelectorAll('[data-react-component]'), (scriptElement) => {
    var data = scriptElement.innerText && scriptElement.innerText.length ?
      JSON.parse(unescape(scriptElement.innerText).slice(1, -1)) :
      {};

    var componentName = scriptElement.dataset.reactComponent

    var el = document.createElement('div');
    scriptElement.after(el);

    if (!window[componentName]) {
      throw new Error('could not find component with name ' + componentName)
    }

    ReactDOM.render(React.createElement(
      window[componentName], data || {}
    ), el);

    scriptElement.remove();
  });
};
