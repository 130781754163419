/** @prettier */
import Button from 'blackbird/components/button/Button';
import classNames from 'classnames';
import { type FrameStore } from 'javascripts/flux/stores/frame';
import { type StoryboardZoomStore } from 'javascripts/flux/stores/storyboardZoom';
import { isUserSubjectToFreeLimit } from 'javascripts/helpers/can-create-new-frame';
import { useStore } from 'javascripts/helpers/useStore';
import * as React from 'react';

type LayoutSize = 'sm' | 'md' | 'lg';

export const FrameLimitButton: React.FC = () => {
  const columns = useStore<number, StoryboardZoomStore>(
    'storyboardZoom',
    (s) => s.zoomSettings.columns,
  );

  const unpaidFrameCountLimit = useStore<number, FrameStore>(
    'frame',
    (f) => f.unpaidFrameCountLimit,
  );

  const [layoutSize, setLayoutSize] = React.useState<LayoutSize>('md');

  React.useEffect(() => {
    if (columns > 7) {
      setLayoutSize('sm');
    } else {
      setLayoutSize('md');
    }
  }, [columns]);

  return (
    <div className="flex flex-col px-3 py-3 frame__wrapper new">
      <div className="flex flex-col flex-grow w-full border rounded-xl bg-brand-yellow-25/50 border-brand-yellow">
        <div className="flex flex-col items-center justify-center flex-grow w-full px-3 py-4">
          <div className={`text-center -mt-4`}>
            <div
              className={classNames(
                'mt-4 mb-1',
                layoutSize === 'sm' && 'text-sm',
              )}
            >
              {isUserSubjectToFreeLimit() ? (
                <>{`Trial Frame Limit: ${unpaidFrameCountLimit}`}</>
              ) : (
                <>{`Frame Limit Reached`}</>
              )}
            </div>
            <div
              className={classNames(
                'mb-4 text-sm opacity-50',
                layoutSize === 'sm' ? 'text-xs' : 'text-sm',
              )}
            >
              {isUserSubjectToFreeLimit() ? (
                <>{`Unlock unlimited frames`}</>
              ) : (
                <>{`Please contact support to request a frame limit increase.`}</>
              )}
            </div>
            {isUserSubjectToFreeLimit() && (
              <Button
                type="yellow"
                size={layoutSize === 'sm' ? 'xs' : 'sm'}
                rounded
                onClick={() => {
                  Track.event.defer('Upgrade prompt open', {
                    context: 'frameLimit',
                    category: 'Checkout',
                  });
                  FlyoverActions.open.defer({
                    type: 'inlinePricing',
                  });
                }}
              >{`Compare Plans`}</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
