/** @format */
import React from 'react';
import { format } from 'date-fns';
import Img from 'javascripts/components/shared/Img';
import BoordsFrameSizeHelper, {
  frameAspectRatioNormalizer,
} from 'javascripts/helpers/frame-size-helper';
import { getMissingFrameImageURL } from 'javascripts/helpers/getMissingFrameImageURL';
import { useTemplates, type Template } from './TemplatesContext';
import { SingleTemplateActions } from './SingleTemplateActions';
import {
  Calendar,
  Grid,
  Image,
  LayoutGrid,
  LayoutTemplateIcon,
  List,
  Rows3,
  type LucideIcon,
} from 'lucide-react';
import classNames from 'classnames';

interface SingleTemplateProps {
  template: Template;
}

export const SingleTemplate: React.FC<SingleTemplateProps> = ({ template }) => {
  const placeholderImage = getMissingFrameImageURL(template.aspect_ratio);
  const { setCurrentTemplate, setIsTemplateModalOpen } = useTemplates();

  const MetaItem: React.FC<{
    text: string;
    Icon: LucideIcon;
    className?: string;
  }> = ({ text, Icon, className }) => (
    <div className="flex items-center space-x-1.5">
      <Icon className="flex-shrink-0 w-3 h-3 text-type-disabled" />
      <div
        className={classNames('text-xs text-type-subdued pb-0.5', className)}
      >
        {text}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col group/template">
      <div
        className="rounded-lg cursor-pointer transition-opacity hover:bg-black/10"
        onClick={() => {
          setCurrentTemplate(template);
          setIsTemplateModalOpen(true);
        }}
      >
        <div className="m-1.5">
          <div className="relative">
            <div
              className="absolute z-50 opacity-0 top-2 right-2 group-hover/template:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SingleTemplateActions template={template} />
            </div>

            {/* Square container that takes full width */}
            <div className="relative w-full aspect-square">
              {/* Centered aspect ratio badge */}
              <div className="absolute inset-0 z-40 flex items-center justify-center">
                <div className="bg-white rounded-md">
                  <div className="bg-decorative-blue/30 text-brand-blue border-decorative-blue/50 px-2 pb-0.5 rounded-md text-xs font-semibold border flex items-center space-x-1">
                    <LayoutTemplateIcon className="w-3 h-3" />
                    <span className="capitalize">{template.name}</span>
                  </div>
                </div>
              </div>

              {/* Overlay */}
              <div className="absolute inset-0 z-10 rounded-t-lg bg-white/70 group-hover/template:bg-white" />

              {/* Centered image container */}
              <div className="absolute inset-0 z-20 flex items-center justify-center p-8">
                <Img
                  className={classNames(
                    'max-h-full rounded-sm',
                    template.aspect_ratio === '9x16' && 'w-2/3 mx-auto',
                  )}
                  containerClasses=""
                  src={placeholderImage}
                  // width={BoordsFrameSizeHelper(template.aspect_ratio).width}
                  // height={BoordsFrameSizeHelper(template.aspect_ratio).height}
                  placeholder={placeholderImage}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center p-4 pt-3.5 bg-surface-add_frame/70 rounded-b-lg space-y-1">
            <MetaItem
              text={`${frameAspectRatioNormalizer(template.aspect_ratio).charAt(0).toUpperCase()}${frameAspectRatioNormalizer(template.aspect_ratio).slice(1)} images (${template.aspect_ratio})`}
              Icon={Image}
            />
            <MetaItem
              text={`${template.fields.length} text fields`}
              Icon={Rows3}
            />
            <MetaItem
              text={`${template.frame_count} frames`}
              Icon={LayoutGrid}
            />
            {/* <MetaItem */}
            {/*   text={`Modified ${format(new Date(template.updated_at), 'd MMM yy')}`} */}
            {/*   Icon={Calendar} */}
            {/* /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
