/** @prettier */
import type {
  frameAspectRatio,
  AspectRatioName,
} from 'javascripts/types/storyboard';
import { contains, mapObject } from 'underscore';

/** The source of truth for most of these values */
const reference: Record<
  AspectRatioName,
  {
    width: number;
    height: number;
    shorthand: frameAspectRatio;
    thumbnailFactor: number;
  }
> = mapObject(
  {
    square: {
      width: 1080,
      height: 1080,
      shorthand: '1:1',
      thumbnailFactor: 0.5,
    },
    portrait: {
      width: 1080,
      height: 1920,
      shorthand: '9x16',
    },
    landscape: {
      width: 1920,
      height: 1080,
      shorthand: '16x9',
    },
    social: {
      width: 1080,
      height: 1350,
      shorthand: '4:5',
    },
    tv: {
      width: 1920,
      height: 1440,
      shorthand: '4:3',
    },
    widescreen: {
      width: 1920,
      height: 1038,
      shorthand: '1.85:1',
    },
    anamorphic: {
      width: 1920,
      height: 800,
      shorthand: '2.4:1',
    },
  },
  (i) => {
    /** This is the same logic used by Dolly's `get_small_size` function. */
    const findSize = (factor = 4) => {
      const withDefault = i.width / factor;
      if (withDefault < 460) {
        return findSize(factor - 1);
      } else {
        return 1 / factor;
      }
    };

    return {
      ...i,
      thumbnailFactor: findSize(),
    };
  },
);

type inputType = frameAspectRatio | AspectRatioName;
const check = (items: Array<inputType | ''>, value: string) =>
  contains(items, value);

/** Takes either a `frameAspectRatio` or a `AspectRatioName` value and returns
 * an aspect ratio name **/
export function frameAspectRatioNormalizer(
  frame_aspect_ratio: inputType,
): AspectRatioName {
  if (check(['16:9', '16x9', '', 'landscape'], frame_aspect_ratio)) {
    return 'landscape';
  } else if (check(['9:16', '9x16', 'portrait'], frame_aspect_ratio)) {
    return 'portrait';
  } else if (check(['1:1', '1x1', 'square'], frame_aspect_ratio)) {
    return 'square';
  } else if (check(['4:5', 'social'], frame_aspect_ratio)) {
    return 'social';
  } else if (check(['4:3', 'tv'], frame_aspect_ratio)) {
    return 'tv';
  } else if (check(['1.85:1', 'widescreen'], frame_aspect_ratio)) {
    return 'widescreen';
  } else if (check(['2.4:1', 'anamorphic'], frame_aspect_ratio)) {
    return 'anamorphic';
  } else {
    throw new Error('Unknown aspect ratio ' + frame_aspect_ratio);
  }
}

/** converts a {@link frameAspectRatio} or {@link AspectRatioName} to a
 * width + height */
export default function BoordsFrameSizeHelper(frame_aspect_ratio: inputType): {
  width: number;
  height: number;
} {
  const ratio = frameAspectRatioNormalizer(frame_aspect_ratio);
  const { width, height } = reference[ratio];
  return { width, height };
}
(window as any).BoordsFrameSizeHelper = BoordsFrameSizeHelper;

/** converts a {@link AspectRatioName} (e.g. 'widescreen') to the `1.85:1` shorthand */
export const aspectRatioNameToAspectRatio = (
  orientation: AspectRatioName,
): frameAspectRatio => {
  return reference[orientation].shorthand;
};

/** gets the numerical ratio (e.g. `0.56`) for a frame aspect ratio */
export const getAspectRatioNumerical = (ratio: inputType) => {
  const { width, height } = BoordsFrameSizeHelper(ratio);
  return width / height;
};

export const getThumbnailSize = (ratio: inputType) => {
  const { width, height, thumbnailFactor } =
    reference[frameAspectRatioNormalizer(ratio)];
  return {
    width: width * thumbnailFactor,
    height: height * thumbnailFactor,
  };
};
