/** @prettier */
import * as React from 'react';
import classNames from 'classnames';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import { CheckIcon } from '@heroicons/react/24/outline';
import { GiftIcon, SparklesIcon, StarIcon } from '@heroicons/react/20/solid';
import cdnUrl from 'javascripts/helpers/cdn-url.js';
import { type Placement } from '@popperjs/core';
import { XIcon } from 'lucide-react';

interface IFeatureProps {
  label: string | React.ReactNode;
  large?: boolean;
  missing?: boolean;
  iconColor?: string;
  tooltipPlacement?: Placement;
  customIcon?: React.ReactElement;
  tooltip?: string | React.ReactNode;
}

interface FeatureDetails {
  label: string;
  tooltip?: string;
  image?: string;
  isAi?: boolean;
}

type Features = {
  [key in FeatureName]: FeatureDetails;
};

export type FeatureName =
  | 'animatics'
  | 'business-additional-users'
  | 'predictable-pricing'
  | 'invoiced-billing'
  | 'priority-ticket'
  | 'automatic-frame-numbering'
  | 'character-creator'
  | 'cancel-anytime'
  | 'moneyback'
  | 'custom-subdomain'
  | 'custom-text-fields'
  | 'password-protection'
  | 'dropbox-google-drive'
  | 'dropbox-google-drive-short'
  | 'essential-storyboarding'
  | 'export-formats'
  | 'guest-comments'
  | 'image-library'
  | 'limited-ai-credits'
  | 'priority-support'
  | 'project-based-access'
  | 'real-time-updates'
  | 'remove-branding'
  | 'remove-frame-limit'
  | 'remove-export-limit'
  | 'script-generator'
  | 'shareable-storyboard'
  | 'shareable-project'
  | 'storyboard-generator'
  | 'storyboard-generator-with-characters'
  | 'sharing-and-collaboration'
  | 'templates'
  | 'team-activity-log'
  | 'team-collaboration'
  | 'team-only-comments'
  | 'team-comments'
  | 'team-editing'
  | 'team-invite-link'
  | 'two-factor-authentication'
  | 'unlimited-storyboards'
  | 'versioning-specific'
  | 'versioning';

const features: Features = {
  'business-additional-users': {
    label: '30 Teammates Included',
    tooltip:
      'Collaborate with up to 30 team members with full access to all features, enabling seamless creative workflow across your organization.',
  },
  templates: {
    label: 'Customizable Templates',
    tooltip:
      'Create and save custom storyboard templates tailored to your workflow. Automatically apply templates to new projects to ensure consistency and save time across your team.',
  },
  'unlimited-storyboards': {
    label: 'Unlimited Storyboards',
    tooltip: 'Create unlimited storyboards with no frame limits.',
  },
  'sharing-and-collaboration': {
    label: 'Sharing & Collaboration',
    tooltip: 'Simple storyboard sharing, commeting and collaboration.',
  },
  'predictable-pricing': {
    label: 'Predictable Pricing for Teams',
    tooltip:
      'Plan your budget with confidence using our transparent, fixed pricing model that scales with your team.',
  },
  'invoiced-billing': {
    label: 'PO/Invoiced Billing',
    tooltip:
      'Simplify your procurement process with support for purchase orders and bank transfer payments, making enterprise billing hassle-free.',
  },
  'priority-ticket': {
    label: 'Priority Ticket Resolution',
    tooltip: `Get faster resolution on feature requests with priority handling for your team's questions and technical needs.`,
  },
  'automatic-frame-numbering': {
    label: 'Automatic Frame Numbering',
    tooltip:
      'Save time and effort with frames that automatically update their numbers when reordered.',
  },
  animatics: {
    label: 'Animatics',
    image: '/assets/tooltip-previews/animatics.jpg',
    tooltip: 'Create and edit animatics from your storyboards, automatically.',
  },
  'custom-text-fields': {
    label: 'Custom Text Fields',
    tooltip:
      'Add custom text fields & icons to create storyboards tailored to your workflow.',
  },
  'cancel-anytime': {
    label: 'Cancel Anytime',
    tooltip: 'Change or cancel your subscription at any time within the app.',
  },
  'shareable-storyboard': {
    label: 'Storyboard Sharing Links',
    tooltip:
      'Share password-protected links to individual storyboards to gather client feedback.',
  },
  'shareable-project': {
    label: 'Project Sharing Links',
    tooltip:
      'Share password-protected links to entires projects with multiple storyboards and supporting text.',
  },
  moneyback: {
    label: '30-Day Moneyback Guarantee',
    tooltip: `If you decide Boords isn't for you we'll refund your payment in full, no questions asked.`,
  },
  'password-protection': {
    label: 'Password Protection',
    tooltip:
      'Add passwords to shareable links to ensure your storyboards stay secure.',
  },
  'dropbox-google-drive': {
    label: 'Connect to Dropbox & Google Drive',
    tooltip:
      'Easily add assets from Dropbox or Google Drive to your storyboards.',
  },
  'dropbox-google-drive-short': {
    label: 'Dropbox & Google Drive',
    tooltip:
      'Easily add assets from Dropbox or Google Drive to your storyboards.',
  },
  'essential-storyboarding': {
    label: 'Essential Storyboarding',
    tooltip: 'Access to essential tools to create storyboards.',
  },
  'limited-ai-credits': {
    label: 'Test AI Credits',
    tooltip: 'Includes sample AI credits to test image generation.',
  },
  'image-library': {
    label: 'Stock Image Library',
    image: '/assets/tooltip-previews/image-library.png',
    tooltip:
      'Find the perfect assets for your storyboard from millions of photos and drawings. Save your favorites for easy access later.',
  },
  'storyboard-generator': {
    label: 'Storyboard Generator',
    image: '/assets/tooltip-previews/storyboard-generator.jpg',
    tooltip:
      'No drawing skills? No problem. Quickly turn your script into vibrant scenes, characters, and visuals with our AI storyboard generator.',
  },
  'storyboard-generator-with-characters': {
    label: 'Storyboard Generator',
    image: '/assets/tooltip-previews/storyboard-generator.jpg',
    tooltip:
      'No drawing skills? No problem. Quickly generate storyboard images with consistent characters using our AI Storyboard Generator and Character Creator.',
  },
  'script-generator': {
    label: 'Script Generator',
    image: '/assets/tooltip-previews/script-generator.jpg',
    tooltip:
      'Say goodbye to blank-page syndrome. Generate storyboard scripts using AI and effortlessly bring your ideas to life in multiple languages.',
  },
  'character-creator': {
    label: 'Character Creator',
    image: '/assets/tooltip-previews/character-creator.jpg?v1',
    tooltip:
      'Generate consistent, recognisable characters to use throughout your storyboards, bringing unparalleled consistency and speed to your storyboarding process.',
  },
  versioning: {
    label: 'Workflow Management',
    image: '/assets/tooltip-previews/versioning.jpg',
    tooltip:
      'Streamline your workflow with instant versioning and change tracking, enabling you to iterate faster and move into production with ease.',
  },
  'versioning-specific': {
    label: 'Versioning',
    image: '/assets/tooltip-previews/versioning.jpg',
    tooltip:
      "Easily make new versions of your storyboard without losing old work or comments. Exported files are named clearly, so you always know which version you're looking at.",
  },
  'export-formats': {
    label: 'Multiple Export Formats',
    image: '/assets/tooltip-previews/export-formats.jpg?v2',
    tooltip:
      'Seamlessly transition into production by exporting your storyboard in a variety of formats, ensuring compatibility with your preferred tools.',
  },
  'remove-branding': {
    label: 'Unlimited Watermark Removal',
    tooltip: 'Remove all Boords watermarks from images and storyboards.',
  },
  'remove-frame-limit': {
    label: 'Unlimited Frames & Exports',
    tooltip:
      'Remove the 12-frame storyboard limit and get unlimited exports in multiple formats.',
  },
  'remove-export-limit': {
    label: 'Unlimited Exports',
    tooltip: 'Unlock exports in multiple formats, including PDF, PNG and MP4.',
  },
  'team-editing': {
    label: 'Team Editing',
    tooltip:
      'Foster collaboration and creativity by enabling your team to create, edit, and work together on storyboards seamlessly.',
  },
  'team-comments': {
    label: 'Team Commenting',
    image: '/assets/tooltip-previews/team-comments.jpg',
    tooltip:
      'Leave comments on frames for clear feedback. Get notified of updates and mark comments as done to keep everyone on the same page.',
  },
  'team-only-comments': {
    label: 'Team-Only Comments',
    tooltip:
      'Leave private, team-only comments to ensure internal communication stays hidden from clients.',
  },
  'guest-comments': {
    label: 'Guest Commenting',
    tooltip:
      'Share storyboards with clients to gather feedback, no signup required.',
  },
  'team-collaboration': {
    label: 'Centralized Feedback',
    image: '/assets/tooltip-previews/team-comments.jpg',
    tooltip:
      'Leave comments on frames for clear feedback. Get notified of updates and mark comments as done to keep everyone on the same page.',
  },
  'real-time-updates': {
    label: 'Real-Time Collaboration',
    image: '/assets/tooltip-previews/real-time-updates.jpg',
    tooltip:
      'Stay in sync with your team by seeing storyboard updates and comments as they happen in real-time, ensuring everyone is always on the same page.',
  },
  'project-based-access': {
    label: 'Project-Based Access',
    image: '/assets/tooltip-previews/project-based-access.jpg',
    tooltip:
      'Maintain control and security by limiting team access to storyboards on a project-by-project basis, ensuring the right people have access to the right information.',
  },
  'team-invite-link': {
    label: 'Team Invite Link',
    image: '/assets/tooltip-previews/team-invite-link.jpg',
    tooltip:
      'Expand your team effortlessly by allowing coworkers to join your Boords team with a simple, shareable link.',
  },
  'custom-subdomain': {
    label: 'Custom Subdomain',
    image: '/assets/tooltip-previews/custom-subdomain.jpg',
    tooltip:
      'Personalize your Boords experience by accessing and sharing your storyboards via a custom name.boords.com URL.',
  },
  'team-activity-log': {
    label: 'Team Activity Log',
    image: '/assets/tooltip-previews/team-activity-log.jpg',
    tooltip:
      "Stay informed and up-to-date by viewing your team's activity and storyboard history in one convenient location.",
  },
  'priority-support': {
    label: 'Priority Support',
    tooltip:
      'Get the help you need, when you need it, with fast and reliable assistance from our expert customer success team.',
  },
  'two-factor-authentication': {
    label: 'Two-Factor Authentication',
    image: '/assets/tooltip-previews/two-factor-authentication.jpg',
    tooltip:
      "Enhance the security of your account and your team's data by enabling an additional layer of protection with two-factor authentication.",
  },
};

interface AiLabelProps {
  text: string;
}

const AiLabel: React.FC<AiLabelProps> = ({ text }) => (
  <span className="whitespace-nowrap">
    <span className="mr-2">{text}</span>
    <span className="inline-flex items-center pb-[0.05rem] pl-1 pr-1.5 rounded-md bg-brand-blue-25 text-type-primary">
      <SparklesIcon className="w-2.5 h-2.5 text-brand-blue/50" />
      <span className="ml-0.5 text-xs text-brand-blue">AI</span>
    </span>
  </span>
);

export const Feature: React.FC<IFeatureProps> = ({
  label,
  tooltip,
  large,
  missing,
  tooltipPlacement = 'right',
  customIcon,
  iconColor = 'text-type-green',
}) => {
  const Label: React.FC = () => (
    <span
      className={classNames(
        large ? 'text-base' : 'text-sm',
        missing && 'text-type-subdued',
        tooltip &&
          'underline decoration-dotted cursor-help underline-offset-2 decoration-border-mid',
      )}
    >
      {label}
    </span>
  );

  return (
    <div className="flex items-center">
      {customIcon ? (
        <>{customIcon}</>
      ) : missing ? (
        <XIcon
          className={classNames(
            'text-type-disabled',
            'mt-1 mr-1.5',
            large ? 'w-6 ' : 'w-4 ',
          )}
        />
      ) : (
        <CheckIcon
          className={classNames(
            iconColor,
            '-ml-0.5 mt-0.5 mr-1.5 flex-shrink-0',
            large ? 'w-6 ' : 'w-4 ',
          )}
        />
      )}

      {tooltip ? (
        <Tooltip
          placement={tooltipPlacement}
          title={tooltip}
          as="div"
          disabled={!tooltip}
        >
          <span>
            <Label />
          </span>
        </Tooltip>
      ) : (
        <Label />
      )}
    </div>
  );
};

interface FeatureInlineProps {
  name: FeatureName;
  missing?: boolean;
  tooltipPlacement?: Placement;

  iconColor?: string;
}

export const FeatureInline: React.FC<FeatureInlineProps> = ({
  name,
  missing,
  tooltipPlacement = 'right',
  iconColor,
}) => {
  const feature = features[name];

  if (!feature) {
    return null;
  }

  const { label, tooltip, isAi, image } = feature;

  return (
    <Feature
      iconColor={iconColor}
      label={isAi ? <AiLabel text={label} /> : <>{label}</>}
      tooltipPlacement={tooltipPlacement}
      missing={missing}
      tooltip={
        image ? (
          <div>
            <img
              className="mt-1.5 rounded-sm min-h-[200px]"
              src={cdnUrl(image)}
            />
            <div className="mt-2 text-sm">{tooltip}</div>
          </div>
        ) : (
          <div className="text-sm">{tooltip}</div>
        )
      }
    />
  );
};

interface FeatureStarProps {
  label: string;
  tooltip: string;
}

export const FeatureStar: React.FC<FeatureStarProps> = ({ label, tooltip }) => (
  <Feature
    label={<>{label}</>}
    customIcon={
      <StarIcon className="w-3.5 mt-0.5 text-brand-yellow ml-[0.1rem] mr-1.5" />
    }
    tooltip={<div className="text-sm">{tooltip}</div>}
  />
);

export const FeatureGift: React.FC<FeatureStarProps> = ({ label, tooltip }) => (
  <Feature
    label={<>{label}</>}
    customIcon={
      <GiftIcon className="w-3.5 mt-0.5 text-brand-blue ml-[0.1rem] mr-1.5" />
    }
    tooltip={<div className="text-sm">{tooltip}</div>}
  />
);
