/** @prettier */
import React, { useContext, useEffect, useState } from 'react';
import { LayoutTemplateIcon } from 'lucide-react';
import Tooltip from '../feedback/tooltip/Tooltip';
import { ProjectsContext } from '../projects/ProjectsContext';
import { useTemplates } from './TemplatesContext';
import Dialog, { ModalDivider } from 'blackbird/components/dialog/Dialog';
import { TemplateSelect } from './TemplateSelect';
import classNames from 'classnames';
import { ManageTemplatesButton } from './ManageTemplatesLink';
import { TemplateCta } from './ActiveTemplateIndicator';
import { TeamUpgradePill } from '../team/TeamUpgradePill';

export const TemplatePill = () => {
  const { activeProject, activeGroup, updateProject } =
    useContext(ProjectsContext);
  const {
    isFeatureAvailable,
    isTemplatesReleased,
    fetchTemplates,
    templates,
    isFetched,
    isFetching,
  } = useTemplates();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTemplateId, setNewTemplateId] = useState<string | null>(null);

  useEffect(() => {
    if (activeGroup && !isFetched && !isFetching) {
      fetchTemplates(activeGroup.team_id as any);
    }
  }, [activeGroup, isFetched, isFetching]);

  const activeTemplate = React.useMemo(() => {
    if (!activeProject || !templates) return null;
    return templates.find(
      (template) => template.id.toString() === activeProject.template_id,
    );
  }, [activeProject, templates]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (
    !activeProject ||
    !activeGroup?.actions.includes('team.manage') ||
    !isTemplatesReleased
  )
    return null;

  const handleConfirm = () => {
    updateProject(activeProject.id, { template_id: newTemplateId });
    Track.event.defer('template_set_as_default', {
      posthogCapture: true,
      templateId: newTemplateId,
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="pl-2">
        <Tooltip
          placement="top"
          title={
            activeTemplate
              ? `Default storyboard template for this project`
              : `Set a default storybord template for this project`
          }
        >
          <div
            className={classNames(
              'pl-1.5 pr-2 pb-0.5 rounded-md text-xs border inline-flex items-center space-x-1 cursor-pointer font-semibold group/templatepill',
              activeTemplate
                ? 'bg-decorative-blue/30 text-brand-blue border-decorative-blue/50'
                : 'text-type-subdued hover:text-type-subdued bg-surface border-border',
            )}
            onClick={handleOpenModal}
          >
            <LayoutTemplateIcon className="w-3 h-3" />
            {activeTemplate ? (
              <span>{activeTemplate.name}</span>
            ) : (
              <span className="group-hover/templatepill:text-type-primary">{`Set template`}</span>
            )}
          </div>
        </Tooltip>
      </div>

      <Dialog
        isDark
        hideActionDivider
        wrapperClasses="!pb-0"
        onConfirm={handleConfirm}
        onCancel={handleCloseModal}
        confirmText={`Save`}
        size="sm"
        title={`Set Project Template`}
        subtitle={
          <span className="inline-block pr-10">{`Set the starting template for all new storyboards in this project. Team members can still choose a different template when needed.`}</span>
        }
        isOpen={isModalOpen}
        hideActions={templates.length === 0 || !isFeatureAvailable}
        onCloseBtnClick={handleCloseModal}
        onEscapeOrOutsideClick={handleCloseModal}
        actionChildren={<ManageTemplatesButton />}
      >
        <div className="relative">
          {!isFeatureAvailable ? (
            <div className="flex items-center mb-12">
              <div className="flex-auto">{`Enable storyboard templates`}</div>
              <TeamUpgradePill />
            </div>
          ) : templates.length === 0 ? (
            <div className="mb-12">
              <TemplateCta>
                <span className="flex items-center flex-auto">
                  <span className="flex-auto">{`No templates yet`}</span>
                  <ManageTemplatesButton title={`Create new`} />
                </span>
              </TemplateCta>
            </div>
          ) : (
            <div className="mb-4 space-y-4">
              <div>
                <TemplateSelect
                  teamId={activeGroup.team_id}
                  placeholder={`No template selected`}
                  defaultTemplateId={activeTemplate?.id}
                  onSelect={setNewTemplateId}
                  hideLabel
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
