import {FrameLimitButton} from './FrameLimitButton';

/** @prettier */
const {
  cannotCreateNewFrame,
} = require('javascripts/helpers/can-create-new-frame');
const createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
var SortableMixin = require('../../helpers/react-sortable-mixin');
const { moveInArray } = require('../../helpers/move-in-array');
const frameGroupedState = require('../../helpers/frame-grouped-state');
const { FrameContainer } = require('./FrameContainer.react');
const { frameUndoManager } = require('../../helpers/undo-stacks');
const { FrameAddButton } = require('./FrameAddButton');
const { TourHintable } = require('../tours/TourHintable');
const { FrameGrid } = require('./FrameGrid');
const _ = require('underscore');

window.FramesSortable = createReactClass({
  propTypes: {
    storyboard: PropTypes.object.isRequired,
    frames: PropTypes.array.isRequired,
    groupInfo: PropTypes.object.isRequired,
    isMultipleSelected: PropTypes.bool.isRequired,
    hideCreateFrame: PropTypes.bool,
    simpleNewFrameButton: PropTypes.bool,
    update_all_frames: PropTypes.bool,
    paidFrameCountLimit: PropTypes.number,
    unpaidFrameCountLimit: PropTypes.number,
  },

  getInitialState: function () {
    return {
      frames: this.props.frames, // Need to do this for sortable to work
      commentedFrames: CommentStore.getState().commentSections,
    };
  },

  componentDidMount: function () {
    CommentStore.listen(this._onCommentChange);
    window.addEventListener('touchstart', this.handleTouch);
    if (this.props.storyboard.has_comments_enabled) {
      CommentActions.fetchIfNecessary.defer(this.props.storyboard.short_slug);
    }
  },

  componentWillUnmount: function () {
    CommentStore.unlisten(this._onCommentChange);
    window.removeEventListener('touchstart', this.handleTouch);
  },

  handleTouch() {
    if (
      BoordsConfig.IsTouchDevice &&
      this._sortableInstance &&
      this._sortableInstance.el
    ) {
      window.removeEventListener('touchstart', this.handleTouch);
      this._sortableInstance.destroy();
    }
  },

  /**
   * Yes, this could probably be changed to getDerivedStateFromProps,
   * But considering that the only reason we use derived state here is because
   * the Sortable mixin requires it, perhaps it's better to use a new
   * drag & drop plugin instead that isn't a mixin or used derived state, both
   * things are rather deprecated!
   */
  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (this.props.frames !== nextProps.frames) {
      this.setState({
        frames: nextProps.frames,
      });
    }
  },

  mixins: [SortableMixin],

  sortableOptions: {
    filter: '.new',
    group: 'shared',
    handle: '.frameSortableHandle',
    model: 'frames',
  },

  _onCommentChange: function (state) {
    if (state.commentSectionsById !== this.state.commentedFrames) {
      this.setState({ commentedFrames: state.commentSectionsById });
    }
  },

  handleStart: function (e) {
    // Clone the frames array, because it's being modifier
    this._frameCache = [...this.props.frames];
    if (frameUndoManager.getLength() === 0)
      frameUndoManager.add({ frames: this._frameCache });
    this.setState({ isSorting: true });
  },

  handleEnd: function (e) {
    this.setState({ isSorting: false });
  },

  handleSort: function (e) {
    frameUndoManager.add({ frames: this.state.frames });
    // The Sortable doesn't handle multiple frames, but that doesn't matter
    // we know which frames are selected, so we can move all the selected
    // frames to the new index

    Track.event.defer('frame_reorder');

    if (
      !this.props.isMultipleSelected &&
      !this.state.frames[e.oldIndex].is_selected
    ) {
      FrameActions.sortFrames({
        storyboard_id: this.props.storyboard.id,
        frames: this.state.frames,
        newIndex: e.newIndex,
      });
      return;
    }

    // We we have to use a cache object here because something is already
    // Changing the frame array. _frameCache is created by onStart
    const selected = _.filter(this._frameCache, (f) => f.is_selected);
    const reordered = moveInArray(this._frameCache, selected, e.newIndex);

    if (reordered.length !== this.props.frames.length) {
      throw new Error('Not the same lengthttttshhh!');
    }

    this.setState({ frames: reordered }, () => {
      FrameActions.sortFrames({
        storyboard_id: this.props.storyboard.id,
        frames: reordered,
        newIndex: e.newIndex,
      });
    });
  },

  onHandleClick(frame) {
    FrameActions.selectGroup(frame.group_id);
  },

  render: function () {
    return (
      <FrameGrid
        className={`-m-3 ${this.state.isSorting ? ' is--sorting' : ''}`}
        frameAspectRatio={this.props.storyboard.frame_aspect_ratio}
      >
        {({ isMinimal, size, estimatedWidth }) => (
          <>
            {this.state.frames.map((frame, index) => {
              var key = frame.id == -1 ? frame.tmp_id : frame.id;
              var comments = this.state.commentedFrames[frame.id];
              const showHandle =
                _.isString(frame.group_id) &&
                this.props.groupInfo[frame.group_id].end === frame.id;

              const frameContainer = (
                <FrameContainer
                  key={'frame:' + key}
                  frame={frame}
                  commentCount={comments ? comments.count : 0}
                  index={frame.number}
                  grouped={frameGroupedState(this.state.frames, index)}
                  onHandleClick={showHandle ? this.onHandleClick : null}
                  isMultipleSelected={this.props.isMultipleSelected}
                  paidFrameCountLimit={this.props.paidFrameCountLimit}
                  unpaidFrameCountLimit={this.props.unpaidFrameCountLimit}
                  storyboard={this.props.storyboard}
                  update_all_frames={this.props.update_all_frames}
                  isMinimal={isMinimal}
                  estimatedWidth={estimatedWidth}
                  gridSize={size}
                  highRes={false}
                />
              );

              if (index === 0) {
                return (
                  <TourHintable
                    nextTourName="export"
                    overlayPosition="right"
                    step={'frameOverview'}
                    key={'frame:' + key}
                  >
                    {frameContainer}
                  </TourHintable>
                );
              } else {
                return frameContainer;
              }
            })}
            {/* end of map */}

            {window.BoordsConfig.TeamRole !== 'guest' ? (
              <>
                {cannotCreateNewFrame(FrameStore.getState()) ? (
                  <FrameLimitButton />
                ) : (
                  <FrameAddButton
                    {...this.props}
                    sort_order={this.state.frames.length}
                  />
                )}
              </>
            ) : null}
          </>
        )}
      </FrameGrid>
    );
  },
});
