/** @prettier */

import React from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Banner from 'blackbird/components/feedback/banner/Banner';
import type { OnboardingProps } from './Onboarding';
import { useTranslation } from 'react-i18next';
import PasswordField from 'blackbird/components/common/PasswordField';

const ChangePasswordScreen: React.FC<OnboardingProps> = (props) => {
  const { notification } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'accounts.changePassword',
  });

  if (!props.passwordResetToken) throw new Error('reset token is not defined');

  const handleSubmit = (e) => {
    if (props.onChangePassword) {
      e.preventDefault();
      const form = e.target;
      const password = form['user[password]'].value;
      const confirmedPass = form['user[password_confirmation]'].value;
      props.onChangePassword({ password, confirmedPass });

      return;
    } else if (!props.authenticityToken || !props.formAction) {
      e.preventDefault();
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex-grow flex justify-center items-center"
      method="post"
      action={props.formAction}
    >
      <input name="utf8" type="hidden" value="✓" />
      <input name="_method" type="hidden" value="put" />
      <input
        name="user[reset_password_token]"
        type="hidden"
        value={props.passwordResetToken}
      />
      {props.authenticityToken && (
        <input
          type="hidden"
          name="authenticity_token"
          value={props.authenticityToken}
        />
      )}

      <fieldset className="flex flex-grow flex-col max-w-md space-y-8">
        <legend className="text-2xl text-type-primary">{t('heading')}</legend>
        {!!notification && (
          <Banner
            message={notification.message}
            kind={notification.kind}
            className="w-full-imp"
          />
        )}
        <div className="flex flex-col gap-6">
          <TextInput
            name="user[password]"
            label={t('newPassword')}
            className="flex-grow-0"
            autoComplete="new-password"
            type="password"
          />
          <PasswordField
            name="user[password]"
            label={t('newPassword')}
            className="flex-grow-0"
            autoComplete="new-password"
          />
          <TextInput
            name="user[password_confirmation]"
            label={t('confirmPassword')}
            className="flex-grow-0"
            autoComplete="new-password"
            type="password"
          />
          <Button htmlType="submit" size="lg">
            {t('button')}
          </Button>
        </div>
      </fieldset>
    </form>
  );
};
export default ChangePasswordScreen;
