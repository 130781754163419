/** @prettier */
import {
  type ErrorHandlerInstanceOptions,
  RequestErrorHandler,
} from '../request-error-handler';
let loaded = false;
const errorHandler = RequestErrorHandler('pusher');

/**
 * Loads pusher with a delay (depending on CPU availability) so we don't
 * clog up connections early in the app.
 */
export const loadPusher = () => {
  if (BoordsConfig.pusher && !loaded) {
    loaded = true;

    const handleError = (err, options?: ErrorHandlerInstanceOptions) =>
      errorHandler({
        ...options,
        // messageKey: 'sharedErrors.realtime'
        messageKey: null,
      })(err);

    loadScript('https://js.pusher.com/7.0/pusher.min.js', (err) => {
      if (err) {
        loaded = false;
        handleError(err ?? 'could not load Pusher JS API', { reason: 0 });
        return;
      }

      try {
        const pusher = new Pusher(BoordsConfig.pusher.key, {
          cluster: BoordsConfig.pusher.cluster,
          encrypted: true,
          authEndpoint: '/pusher',
          auth: {
            headers: {
              'X-CSRF-Token': BoordsConfig.pusher.token,
            },
          },
        });

        pusher.connection.bind('connected', function () {
          RealtimeActions.pusherLoaded();
        });

        pusher.connection.bind('error', (e) =>
          handleError(e ?? 'unknown pusher error'),
        );

        pusher.connection.bind('disconnected', (dErr) =>
          errorHandler({
            messageKey: null,
            rollbarMessage: 'disconnected from pusher',
            severity: 'warn',
          })(dErr ?? 'no error passed'),
        );

        (window as any).pusher = pusher;
      } catch (error) {
        handleError(error);
      }
    });
  }
};
