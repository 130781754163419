/** @prettier */
import * as React from 'react';
import classNames from 'classnames';
import Dialog, { ModalDivider } from '../dialog/Dialog';
import { Gift, ShieldCheck } from 'lucide-react';
import { CreditBundleSelector } from './CreditBundles';
import { CreditsContext, CreditsProvider } from './CreditsContext';
import Button from '../button/Button';
import { SecondaryLink } from '../common/SecondaryLink';

interface CreditsDialogProps {
  onClose?: () => void;
}

const CreditsDialogComponent: React.FC<CreditsDialogProps> = ({ onClose }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    FlyoverActions.close.defer();
  };

  const { trackCreditEvent, isProcessing, handlePurchaseCredits } =
    React.useContext(CreditsContext);

  React.useLayoutEffect(() => {
    trackCreditEvent('credits_flyover_load');
  }, []);

  return (
    <Dialog
      size="slim"
      hideActions
      isColorBurst
      isOpen
      onEscapeOrOutsideClick={handleClose}
      closeButtonClasses="mt-8 z-50 hidden"
      containerClasses={classNames('!pb-0 overflow-x-hidden pt-0')}
      titleTextContainerClasses="hidden"
      title="x"
    >
      <div className="absolute top-0 left-0 right-0">
        <div className="bg-brand-blue-25">
          <div className="flex items-center justify-center px-8 py-2 font-semibold text-brand-blue">
            <span className="pt-[0.1rem]">
              <ShieldCheck size={16} />
            </span>
            <div className="mx-1 text-sm">{`No subscription required`}</div>
          </div>
        </div>
      </div>

      {/* Main content area */}
      <div>
        <div className="mb-1.5 text-lg font-semibold leading-tight text-center">
          {`Finish Your Storyboard`}
        </div>
        <div className="mb-1.5 text-sm text-type-subdued text-center">{`Get more image generator credits, no subscription required.`}</div>
        <div className="mt-6">
          <CreditBundleSelector />
        </div>
        <div className="mt-6">
          <Button
            type="yellow"
            className="w-full"
            loading={isProcessing}
            rounded
            onClick={handlePurchaseCredits}
          >
            {`Continue`}
          </Button>
        </div>
        <div className="mt-4 text-center">
          <span className="mr-1 text-sm text-type-subdued">
            {`Get unlimited credits with`}
          </span>
          <SecondaryLink
            onClick={() => FlyoverActions.open.defer({ type: 'inlinePricing' })}
          >{`Workflow`}</SecondaryLink>
        </div>
      </div>
    </Dialog>
  );
};

// Higher-order component for potential future context providers
export const CreditsDialog: React.FC<CreditsDialogProps> = (props) => (
  <CreditsProvider currency={BoordsConfig.Currency}>
    <CreditsDialogComponent {...props} />
  </CreditsProvider>
);
