/** @format */

import type { AdaptiveImageSources } from 'javascripts/components/shared/Img';
import { some } from 'underscore';
import { allImagePlaceholders } from './getMissingFrameImageURL';

/** Detects if the current image is a placeholder image, i.e. 'missing.jpg' */
export const isImagePlaceholder = (url?: string) =>
  url
    ? !!url.match(/missing(-error)?(-[0-9]\w+)?\.\w+/) ||
      some(allImagePlaceholders, url.replace('https://cdn.boords.com', ''))
    : false;

export const isPlaceholderSource = (source: AdaptiveImageSources) =>
  some(source, (i) => isImagePlaceholder(i));
