/** @prettier */

import type {
  AspectRatioName,
  frameAspectRatio,
} from 'javascripts/types/storyboard';
import { chain } from 'underscore';
import { frameAspectRatioNormalizer } from './frame-size-helper';

const reference: Record<AspectRatioName, [string, string]> = {
  landscape: [
    'https://cdn.boords.com/assets/missing-16x9.jpg',
    'https://cdn.boords.com/assets/missing-error-16x9.jpg',
  ],
  portrait: [
    'https://cdn.boords.com/assets/missing-9x16.jpg',
    'https://cdn.boords.com/assets/missing-error-9x16.jpg',
  ],
  square: [
    'https://cdn.boords.com/assets/missing-1x1.jpg',
    'https://cdn.boords.com/assets/missing-error-1x1.jpg',
  ],
  social: [
    'https://cdn.boords.com/assets/missing-social.jpg',
    'https://cdn.boords.com/assets/missing-error-social.jpg',
  ],
  tv: [
    'https://cdn.boords.com/assets/missing-tv.jpg',
    'https://cdn.boords.com/assets/missing-error-tv.jpg',
  ],
  widescreen: [
    'https://cdn.boords.com/assets/missing-widescreen.jpg',
    'https://cdn.boords.com/assets/missing-error-widescreen.jpg',
  ],
  anamorphic: [
    'https://cdn.boords.com/assets/missing-anamorphic.jpg',
    'https://cdn.boords.com/assets/missing-error-anamorphic.jpg',
  ],
};

type inputType = frameAspectRatio | AspectRatioName;
export const getMissingFrameImageURL = (frameAspectRatio: inputType) => {
  const normalized = frameAspectRatioNormalizer(frameAspectRatio);
  return reference[normalized][0];
};

export const getErrorFrameImageURL = (frameAspectRatio: inputType) => {
  const normalized = frameAspectRatioNormalizer(frameAspectRatio);
  return reference[normalized][1];
};

export const allImagePlaceholders = chain(reference)
  .values()
  .map((i) => i.map((url) => url.replace('https://cdn.boords.com', '')))
  .uniq()
  .flatten()
  .value();
