/** @prettier */
/* eslint eqeqeq:0 one-var:0 */
require('../actions/realtime');
require('../actions/shareable');
require('../actions/comment');
const ignoreWebP = require('../../helpers/ignore-webp');
const { moveInArray } = require('../../helpers/move-in-array');
const updateFrameNumbers = require('../../helpers/update-frame-numbers');
const createGuid = require('../../helpers/guid');
const api = require('../../helpers/api')();

import * as _ from 'underscore';
import { FilestackActions } from '../../flux/actions/filestack';
import { group, ungroup, sort } from '../../helpers/group-frames';
import { RequestErrorHandler } from '../../helpers/request-error-handler';
import { frameUndoManager } from '../../helpers/undo-stacks';
import { FakeAltStoreClass } from './AltStore';
import { FrameActions } from '../actions/frame';
import { ShareableActions } from '../actions/shareable';
import type { PlayerStore as PlayerStoreType } from '../stores/player';
import type {
  base64String,
  DetailedFrame,
  fieldData,
  frameId,
  frameStatus,
  IFrame,
  IFrameImageProps,
  GeneratorPreferences,
  IFrameNotesProps,
  NewFrame,
} from '../../types/frame';
import {
  getUserFrameLimit,
  notificationIfCannotCreateNewFrame,
  showFrameLimitNotification,
} from '../../helpers/can-create-new-frame';
import { FileUploadActions } from '../actions/file_upload';
import { FrameEditorActions } from '../actions/frame_editor';
import { pickDefined } from '../../helpers/pick-defined';
import { rollbar } from '../../helpers/rollbar';
import { StoryboardActions } from '../actions/storyboard';
// prettier-ignore
import {
  isEqual, uniq, isObject, last, pick, memoize, debounce, some, each, filter, findIndex, findLastIndex, findWhere, groupBy, isEmpty, isFunction, isNumber, isUndefined, mapObject, omit, pluck, sortBy, without, isString
} from 'underscore';
import { AssetsActions } from '../actions/assets';
import {
  maxImageFileSize,
  maxImageSizeExceededError,
  maxImageSizeInMB,
} from '../../helpers/constants';
import { ensureNumber } from '../../helpers/ensure-number';
import { CommentActions } from '../actions/comment';
import { eachLimit } from 'async';
import { RequestActions } from '../actions/request';
import logger from 'javascripts/helpers/logger';
import {
  cleanFieldData,
  getFrameField,
  getFrameFieldInfo,
  setFrameField,
} from 'javascripts/helpers/fieldDataHelpers';
import { deserializeJSONFields } from 'javascripts/helpers/frame/frameJSON';
import { ajax, ajaxRuby, type AjaxSettings } from 'javascripts/helpers/ajax';
import { StoryboardAnalysisActions } from '../actions/storyboardAnalysis';
import { frameAspectRatioNormalizer } from 'javascripts/helpers/frame-size-helper';
import { aspectRatioNameToAspectRatio } from 'javascripts/helpers/frame-size-helper';
import { hasRolloutFlagEnabled } from 'javascripts/helpers/rollout';
import { isFileTypeAccepted } from 'javascripts/helpers/isFileTypeAccepted';
import { fileTypeListToString } from 'javascripts/helpers/fileTypeListToString';
import {
  sanitizeHTML,
  trimHTML,
} from 'blackbird/components/form/richTextInput/sanitizeHTML';
import { ScriptEditorActions } from '../actions/scripteditor';
import type { GenericCallback } from 'blackbird/helpers/types';
import { PlayerActions } from '../actions/player';
const frameErrorHandler = RequestErrorHandler('frame');

interface NewFrameOptions
  extends Partial<IFrameNotesProps>,
    Partial<IFrameImageProps> {
  file?: File;
  dropped_sort_order?: number;
  group_id?: string;
  storyboard_id: number;
  move_frames_after_here?: boolean;
  frame_to_duplicate?: IFrame;
  callback?: (frame: DetailedFrame) => void;
  sort_order?: number;
  image_status?: string;
}

export interface Base64ImageProp {
  /** base64 string of the image */
  image: base64String;
}

export type PreviewFrame = NewFrame &
  Partial<fileData> & {
    id: number;
  };

export type bulkUpdatesArray = Array<Partial<DetailedFrame> & { id: number }>;

type fileData = {
  image_file_type: string;
  image_file_size: number;
} & Base64ImageProp;

interface NewFrameOptionsWithImage extends NewFrameOptions, Base64ImageProp {
  image_file_size: number;
  image_status: string;
  image_file_type: string;
  background_image_url: string;
  layer_data: string | null;
}

interface GeneratorPollFrameResponse {
  data: {
    attributes: {
      background_image_url?: string;
      generator_processing: boolean;
      thumbnail_image_url: string;
    };
  };
}

interface ExistingFrameOptions {
  id: number;
  image_status?: string;
  image_file_size: number;
  image_file_type: string;
  background_image_url: string | null;
  layer_data?: string;
  storyboard_id: number;
}

export interface UploadDrawnImageOptions
  extends ExistingFrameOptions,
    Base64ImageProp {
  callback?: (...args: unknown[]) => void;
}

export interface UploadImageOptions extends ExistingFrameOptions {
  file: File;
  callback?: GenericCallback;
  onProgress?: (number) => void;
}

interface UploadFunction {
  (
    frame: IFrame,
    options: {
      id: number;
      background_image_url?: string | null;
      onProgress?: (number) => void;
    },
    callback?: GenericCallback,
  ): void;
}

/**
 * An array with the database keys for note fields, this can be used to
 * iterate over.
 * @deprecated
 */
// prettier-ignore
export const noteFieldKeys = [
  'reference', 'voiceover','direction','note_4','note_5','note_6',
] as const;

// prettier-ignore
/** These are the file types that are accepted by Dolly */
export const acceptedFrameFileTypes = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg', 'image/vnd.adobe.photoshop', 'application/x-photoshop', 'application/photoshop', 'image/psd', 'application/psd', ''];

/**
 * The data that's omitted when sending the update to Pusher (this keeps the
 * payload small enough!)
 */
const LIVE_IMAGE_OMIT = ['layer_data', 'image'];

/**
 * This function generates a debounced ajax function for every ID passed
 * called like debouncedAjax(frame.id)(ajaxProps);
 * smooths out repeated calls to update the same endpoint
 * It memoizes it based on the ID parameter
 */
const debouncedAjax = memoize(
  (id) => debounce((props: AjaxSettings) => ajax(props), 1500, false), // eslint-disable-line
);

function clampDuration(duration) {
  const { min, max } = PlayerStore.getState().durationOptions;

  return Math.max(min, Math.min(max, parseInt(duration)));
}

/**
 * Detects if the two frames passed have the same group */
function getAdjacentGroup(previousFrame, nextFrame) {
  // Adopt that group
  if (
    nextFrame &&
    previousFrame &&
    nextFrame.group_id === previousFrame.group_id
  ) {
    return nextFrame.group_id;
  }

  return null;
}

export interface GroupInfo {
  start: number;
  middle: number;
  end: number;
  frames: IFrame[];
}

export class FrameStore extends FakeAltStoreClass<FrameStore> {
  unpaidFrameCountLimit = 12;
  paidFrameCountLimit = 250;
  channel = null;
  error_message: unknown | false = false;
  status_type = '';
  frame_left = 0;
  frame_right = 0;
  frames: Array<IFrame | PreviewFrame> = [];
  selectedFrames: IFrame[] = [];
  is_dragging = false;
  is_duplicating_storyboard = false;
  is_listening = false;
  is_dragging_existing: number | null = null;
  update_all_frames = false;
  pusher_loaded = false;
  is_saving = false;
  live_update_timeout?: unknown;
  live_sort_timeout?: unknown;
  live_text_update_timeout?: unknown;
  live_image_timeout?: unknown;
  live_new_frame_timeout?: unknown;
  /** This number is used for realtime stuff */
  storyboard_id: number;
  lastSelectedFrameIndex = 0;
  is_loading = false;

  has_initial_content = false;
  groupInfo: { [group_id: string]: GroupInfo } = {};

  constructor() {
    super();
    this.bindListeners({
      handleBulkFrameUpload: FrameActions.BULK_FRAME_UPLOAD,
      handleUpdateFrameImageUrls: FrameActions.UPDATE_FRAME_IMAGE_URLS,
      handleDeleteFrames: FrameActions.DELETE_FRAMES,
      handleClearFrame: FrameActions.CLEAR_FRAME,
      handleDeselectAll: FrameActions.DESELECT_ALL,
      handleSelectAll: FrameActions.SELECT_ALL,
      handleEndDragging: FrameActions.END_DRAGGING,
      handleFetchFrames: FrameActions.FETCH_FRAMES,
      handleInsertFrame: FrameActions.INSERT_FRAME,
      handleInsertFrames: FrameActions.INSERT_FRAMES,
      handleMouseEnterInsertAfter: FrameActions.MOUSE_ENTER_INSERT_AFTER,
      handleMouseEnterInsertBefore: FrameActions.MOUSE_ENTER_INSERT_BEFORE,
      handleMouseLeaveInsertAfter: FrameActions.MOUSE_LEAVE_INSERT_AFTER,
      handleMoveToIndex: FrameActions.MOVE_TO_INDEX,
      handleMouseLeaveInsertBefore: FrameActions.MOUSE_LEAVE_INSERT_BEFORE,
      handleReceiveFrames: [
        FrameActions.RECEIVE_FRAMES,
        ShareableActions.RECEIVE,
      ],
      handleSaveText: FrameActions.SAVE_TEXT,
      handleSelectFrame: FrameActions.SELECT_FRAME,
      handleCommentActivate: CommentActions.SET_ACTIVE_FRAME,
      handleSelectMultiple: FrameActions.SELECT_MULTIPLE,
      handleSetImageStatus: FrameActions.SET_IMAGE_STATUS,
      handleSetFramesStatus: FrameActions.SET_FRAMES_STATUS,
      handleShowUploadImagePrompt: FrameActions.SHOW_UPLOAD_IMAGE_PROMPT,
      handleSortFrames: FrameActions.SORT_FRAMES,
      handleStartDragging: FrameActions.START_DRAGGING,
      handleTriggerDropzoneFileDialog:
        FrameActions.TRIGGER_DROPZONE_FILE_DIALOG,
      handleUploadDrawnImage: FrameActions.UPLOAD_DRAWN_IMAGE,
      handleUploadImage: FrameActions.UPLOAD_IMAGE,
      handlePusherLoaded: RealtimeActions.PUSHER_LOADED,
      handleUpdateFrameGeneratorPreferences:
        FrameActions.UPDATE_FRAME_GENERATOR_PREFERENCES,
      handleUpdateDuration: FrameActions.UPDATE_DURATION,
      handleBatchUpdateDurations: FrameActions.BATCH_UPDATE_DURATIONS,
      handleBatchSet: FrameActions.BATCH_SET,
      handleSelectGroup: FrameActions.SELECT_GROUP,
      handleGroupFrames: FrameActions.GROUP_FRAMES,
      handleUngroupFrames: FrameActions.UNGROUP_FRAMES,
      handleTour: FrameActions.TOUR,
      handleUpdateFrameHistory: FrameActions.UPDATE_FRAME_HISTORY,
      ensureUndoItems: FrameActions.ENSURE_UNDO_ITEMS,
      handleUpdateImageStatus: FrameActions.UPDATE_IMAGE_STATUS,
      handlePermanentlyDeleteFrame: FrameActions.PERMANENTLY_DELETE_FRAME,
    });
  }

  _useGoAPI(url) {
    return api.setGoApiUrl(url);
  }

  handleTour(args) {
    if (args.action === 'openFrameCommentBox') {
      CommentActions.openFrameCommentBox.defer({
        frame: this.frames[0],
      });
      CommentActions.updateNewCommentData.defer({
        comment: 'Nice! Can we change the red? 😀',
      });
    }
  }

  handlePusherLoaded() {
    this.pusher_loaded = true;
    RealtimeActions.bindStoryboard.defer({
      store: this,
      context: 'edit',
    });
  }

  _liveSort(frameIds) {
    if (!this.live_sort_timeout && this.pusher_loaded) {
      this.live_sort_timeout = setTimeout(
        function () {
          this.channel.trigger('client-sort', {
            frameIds: frameIds,
            name: BoordsConfig.Name,
          });
          this.live_sort_timeout = null;
          this.emitChange();
        }.bind(this),
        100,
      );
    }
  }

  /** Dispatches the live update */
  _liveTextUpdate(frame: Parameters<typeof this.handleSaveText>[0]) {
    if (!this.live_text_update_timeout && this.pusher_loaded) {
      this.live_text_update_timeout = setTimeout(
        function () {
          this.channel.trigger('client-live-text-update', {
            frame: frame,
            name: BoordsConfig.Name,
          });
          this.live_text_update_timeout = null;
          this.emitChange();
        }.bind(this),
        100,
      );
    }
  }

  _liveNewFrame(data) {
    if (!this.live_new_frame_timeout && this.pusher_loaded) {
      this.live_new_frame_timeout = setTimeout(
        function () {
          this.channel.trigger('client-live-new-frame', {
            frame: data.frame,
            index: data.index,
            name: BoordsConfig.Name,
          });
          this.live_new_frame_timeout = null;
          this.emitChange();
        }.bind(this),
        100,
      );
    }
  }

  _liveImageUpdate(frame) {
    if (!this.live_image_timeout && this.pusher_loaded) {
      this.live_image_timeout = setTimeout(
        function () {
          this.channel.trigger('client-live-image', {
            frame: omit(frame, LIVE_IMAGE_OMIT),
            name: BoordsConfig.Name,
          });
          this.live_image_timeout = null;
          this.emitChange();
        }.bind(this),
        100,
      );
    }
  }

  _requestStart() {
    StoryboardActions.requestStart();
  }

  _requestComplete() {
    StoryboardActions.requestComplete.defer();
  }

  _setAuthHeader(xhr) {
    api.setGoApiAuthHeader(xhr);
  }

  handleMoveToIndex(obj) {
    let newIndex = findIndex(this.frames, (f) => f.number === obj.index);

    if (newIndex === -1 || isNumber(obj.index)) {
      newIndex = Number(obj.index - 1);
    }

    if (newIndex < 0 || !this.frames[newIndex])
      return frameErrorHandler({
        message: 'Could not find that frame number, sorry',
        askUserToRetry: false,
        severity: 'warn',
      })(new Error('Could not find new frame index ' + newIndex));

    obj.frames.forEach((f) => (f.group_id = this.frames[newIndex].group_id));
    this.frames = moveInArray(this.frames, obj.frames, newIndex);
    this.updateFrameNumbers();
    this.commitGroupStatus();
    this._runSort(pluck(this.frames, 'id'), obj.frames[0].storyboard_id);
    this._updateSortOrder(this.frames);
  }

  _updateSortOrder(frames) {
    return sort(frames);
  }

  handleSetImageStatus(obj) {
    for (var f in this.frames) {
      if (obj.frame.id == this.frames[f].id) {
        this.frames[f].image_status = obj.image_status;
      }
    }
  }

  handleSelectFrame(frame_id) {
    const frameIndex = this.frames.findIndex((f) => f.id === frame_id);
    const frame = this.frames[frameIndex];

    if (frame) {
      frame.is_selected = !frame.is_selected;
      if (frame.is_selected) this.lastSelectedFrameIndex = frameIndex;
      this.updateSelected();
    } else {
      logger.log('could not find frame with id ' + frame_id);
    }
  }

  updateSelected() {
    this.selectedFrames = filter(this.frames, (f) => f.is_selected);
  }

  handleCommentActivate(frame_id) {
    // Some other process is overriding setting this data? it's annoying
    // This works as expected.
    setTimeout(() => {
      this.frames.forEach((f) => (f.is_selected = f.id === frame_id));
      this.updateSelected();
      this.emitChange();
    }, 0);
  }

  handleSelectMultiple(frame_id) {
    const currentSelected = this.lastSelectedFrameIndex;
    const newSelection = findLastIndex(this.frames, (f) => f.id === frame_id);

    if (currentSelected < 0 || newSelection < 0)
      throw new Error('could not find some frame thing');

    const start =
      currentSelected < newSelection ? currentSelected : newSelection;

    const end =
      currentSelected < newSelection ? newSelection + 1 : currentSelected;

    this.frames.slice(start, end).forEach((f) => (f.is_selected = true));
    this.updateSelected();
  }

  handleDeselectAll() {
    for (var f in this.frames) {
      this.frames[f].is_selected = false;
    }
    this.selectedFrames = [];
  }

  handleSelectAll() {
    for (var f in this.frames) {
      this.frames[f].is_selected = true;
    }
    this.selectedFrames = [...this.frames];
  }

  handleShowUploadImagePrompt(frame_id) {
    for (var f in this.frames) {
      if (frame_id == this.frames[f].id) {
        this.frames[f].show_upload_image_prompt = true;
      } else {
        this.frames[f].show_upload_image_prompt = false;
      }
    }
    this.emitChange();
  }

  handleTriggerDropzoneFileDialog(frame_id) {
    for (var f in this.frames) {
      this.frames[f].show_file_dialog_for_frame =
        frame_id == this.frames[f].id ? true : false;
    }
  }

  /**
   * Fired when someone creates a new frame by clicking the placeholder in
   * the storyboard view, the "new frame" button in the editor, probably also
   * other ways?
   */
  handleInsertFrame(options: NewFrameOptions) {
    if (notificationIfCannotCreateNewFrame(this)) return;

    if (options.file) {
      this._gatherImageDataFromFile(
        { file: options.file },
        (success, fileData) => {
          if (success) {
            this._executeNewFrameAjaxRequest({ ...options, ...fileData });
          }
        },
      );
    } else {
      if (options.dropped_sort_order) {
        options.group_id = getAdjacentGroup(
          this.frames[options.dropped_sort_order - 1],
          this.frames[options.dropped_sort_order - 2],
        );
      }

      this._executeNewFrameAjaxRequest(options);
    }

    Track.event.defer('insert_blank_frame');
  }

  handleInsertFrames({
    frame_count,
    storyboard_id,
    callback,
  }: {
    frame_count: number;
    storyboard_id: number;
    callback?: (newFrames: IFrame[]) => void;
    saveToHistory?: boolean;
  }) {
    if (notificationIfCannotCreateNewFrame(this)) return;
    const frameData: NewFrame[] = [];
    const startAtIndex = this.frames.length;

    for (var f = 1; f <= frame_count; f++) {
      const sortOrder = startAtIndex + f;
      var frame: NewFrame = this._insertPreviewFrame({
        dropped_sort_order: sortOrder,
        sort_order: sortOrder,
        storyboard_id: storyboard_id,
      });
      frame.sort_order = sortOrder;
      frame.number = String(sortOrder);
      frame.dropped_sort_order = sortOrder;
      frameData.push(frame);
    }

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'post',
      dataType: 'json',
      enctype: 'application/x-www-form-urlencoded',
      url: this._useGoAPI('bulk/' + storyboard_id),
      data: {
        frames: JSON.stringify(frameData),
      },
    })
      .then((newFrames) => {
        this._replaceTempFramesAfterCreate({
          newFrames: newFrames,
          hasImage: false,
        });

        this.has_initial_content = true;
        ScriptEditorActions.frameInserted.defer();

        if (!isUndefined(callback) && isFunction(callback)) {
          callback(newFrames);
        }

        frameUndoManager.clearStack();

        Track.event.defer('insert_multiple_frames');
        StoryboardActions.trackLoadEvent.defer({
          frames: this.frames,
          action: 'update',
        });
      })
      .catch(
        frameErrorHandler(
          {
            rollbarMessage: '[GO API ERROR] POST bulk/storyboard_id',
            messageKey: 'frames.errors.new',
            messageData: { count: frame_count ?? 1 },
          },
          () => {
            frameData.forEach((f) => {
              frame.image_status = 'image_error';
              frame.error_message = 'Error uploading image';
            });
            this.emitChange();
          },
        ),
      );
  }

  _replaceTempFramesAfterCreate(options: {
    newFrames: IFrame[];
    hasImage: boolean;
  }) {
    for (var r in options.newFrames) {
      for (var f in this.frames) {
        if (
          this.frames[f].tmp_id == options.newFrames[r].tmp_id &&
          !isEmpty(this.frames[f].tmp_id)
        ) {
          options.newFrames[r].sort_order = this.frames[f].sort_order;
          if (options.hasImage) {
            options.newFrames[r].image_status = 'creating_crop';
          }
          this.frames[f] = options.newFrames[r];
        }
      }
    }

    this.updateFrameNumbers();
    this.emitChange();
    this._requestComplete();
    this._runSort(
      this.frames.map((f) => f.id),
      options.newFrames[0].storyboard_id,
    );
  }

  // Update an existing frame
  handleUploadImage(options: UploadImageOptions) {
    // When updating is done, we clear the undo stack
    const callback = (success) => {
      if (success) frameUndoManager.clearStack();
      options.callback?.(success);
    };

    this._gatherImageDataFromFile(
      { file: options.file },
      (success, fileData) => {
        if (!success) return callback(false);
        this._executeExistingFrameAjaxRequest(
          { ...options, ...fileData! },
          callback,
        );
      },
    );
  }

  // Add drawn image to frame
  handleUploadDrawnImage(options: UploadDrawnImageOptions) {
    const { callback, ...requestOptions } = options;

    this._executeExistingFrameAjaxRequest(requestOptions, (args) => {
      frameUndoManager.clearStack();
      if (callback) {
        callback!(args);
      }
    });
  }

  _gatherImageDataFromFile(
    options: {
      file: File;
      [addedParam: string]: unknown;
    },
    callback: (success: boolean, fileData?: fileData) => void,
  ) {
    var reader = new FileReader();
    reader.onload = () => {
      if (!this._validateFileInput(options.file)) {
        return callback(false);
      }
      if (!options.file) throw new Error('Did not receive file parameter');
      const output = {
        image_file_type: options.file.type,
        image_file_size: options.file.size,
        image: reader.result as base64String,
      };
      Object.assign(options, output);
      callback(true, output);
    };
    reader.onerror = frameErrorHandler(
      {
        messageKey: 'frames.errors.parsingImage',
      },
      () => callback(false),
    );
    reader.readAsDataURL(options.file);
  }

  _createPreviewFrameObject(
    options: NewFrameOptions | NewFrameOptionsWithImage,
  ) {
    // Sort order is set on drop in Frames.react.jsx
    var sortOrder = options.dropped_sort_order;

    var previewFrame: PreviewFrame = {
      id: -1,
      /**
       * A "fake" id so we can identify this image after it's been
       * processed on the server
       **/
      tmp_id: createGuid(),
      image_is_processing: true,
      image_status: 'creating_record',
      sort_order: sortOrder,
      number: String(sortOrder),
      storyboard_id: options.storyboard_id,
      reference: options.reference,
      direction: options.direction,
      voiceover: options.voiceover,
      group_id: options.group_id,
    };
    previewFrame = this._appendImageToFrameObject(options, previewFrame);
    return previewFrame;
  }

  _appendImageToFrameObject<T extends PreviewFrame>(
    options:
      | NewFrameOptions
      | NewFrameOptionsWithImage
      | ExistingFrameOptions
      | IFrame,
    previewFrame: T,
  ): T {
    // Find a way of distinguishing between the two possible variations of
    // options: https://stackoverflow.com/a/43388312/847689
    const hasImage = (
      options: NewFrameOptions | NewFrameOptionsWithImage | IFrame,
    ): options is NewFrameOptionsWithImage =>
      Object.prototype.hasOwnProperty.call(options, 'image');

    previewFrame.thumbnail_image_url = '/assets/missing.jpg';

    if (hasImage(options)) {
      if (
        options.image_file_size &&
        options.image_file_size > maxImageFileSize
      ) {
        previewFrame.image_status = 'image_error';
        previewFrame.error_message = maxImageSizeExceededError;
      }

      previewFrame.image_status = 'creating_crop';
      previewFrame.image = options.image;
      previewFrame.image_file_size = options.image_file_size;
      previewFrame.image_file_type = options.image_file_type;
      // Background image and layer data
      previewFrame.background_image_url = options.background_image_url;
      previewFrame.layer_data = options.layer_data;
      previewFrame.thumbnail_image_url = options.image;
    } else {
      previewFrame.image_status = options.image_status;
    }

    return previewFrame;
  }

  /** Creates a new frame with the passed options and returns it */
  _insertPreviewFrame(options: NewFrameOptions | NewFrameOptionsWithImage) {
    var previewFrame = this._createPreviewFrameObject(options);

    if (options.move_frames_after_here) {
      // If there are no frames, just add this one and be done with it
      if (isEmpty(this.frames)) {
        this.frames.push(previewFrame);
      } else {
        // If there are existing frames, loop through them to
        // find the frames with the same sort order as this new frame
        var incrementSortOrder = false;
        for (var f in this.frames) {
          if (incrementSortOrder) {
            this.frames[f].sort_order++;
          }
          if (this.frames[f].sort_order == previewFrame.sort_order) {
            // When you find it, insert our frame and update the sort_order
            // of all subsequent frames in the series
            incrementSortOrder = true;
            // Make space for our new frame
            this.frames[f].sort_order++;
            // Add the preview frame to the mix
            this.frames.push(previewFrame);
          }
        }
        // If we haven't found a frame with the same sort order
        // we must be adding one at the end, so we can go
        // ahead and just add it to the end of the array
        if (!incrementSortOrder) {
          this.frames.push(previewFrame);
        }
      }
    } else {
      // Add this preview frame and sort all frames by sort order.
      this.frames.push(previewFrame);
    }

    this.frames = sortBy(this.frames, function (f) {
      return f.sort_order;
    });
    this.updateFrameNumbers();

    this.emitChange();
    return previewFrame;
  }

  _validateFileInput(file: File): boolean {
    const fileType = file.type.toLowerCase();

    // Account for photoshop files on windows
    if (fileType === '' && !file.name.toLowerCase().match(/\.psd$/)) {
      logger.error(`file type missing or supported? ("${fileType}")`);
      return false;
    }

    if (!isFileTypeAccepted(acceptedFrameFileTypes, file.type)) {
      RequestActions.error.defer({
        key: 'sharedErrors.unsupportedUpload',
        data: {
          fileType: file.type,
          formats: fileTypeListToString(acceptedFrameFileTypes),
        },
      });
      this._requestComplete();
      return false;
    } else if (file.size > maxImageFileSize) {
      RequestActions.error.defer({
        key: 'sharedErrors.fileSizeExceeded',
        data: { maxSize: maxImageSizeInMB + 'MB' },
      });
      this._requestComplete();
      return false;
    } else {
      return true;
    }
  }

  /** create and upload multiple frames in one go */
  handleBulkFrameUpload(options: {
    files: File[];
    storyboard_id: number;
    existing_frames_length?: number;
    callback?: () => void;
  }) {
    let filesToUpload = options.files;
    const framesAllowedToAdd = getUserFrameLimit(this) - this.frames.length;

    if (options.files.length > framesAllowedToAdd) {
      showFrameLimitNotification();
      if (framesAllowedToAdd > 0) {
        logger.log(`allowing ${framesAllowedToAdd} frames to be uploaded`);
        filesToUpload = options.files.slice(0, framesAllowedToAdd);
      }
    }

    if (filesToUpload.length === 0) return options.callback?.();

    /**
     * Clear the undo stack, because it does not contain the new frame, and
     * would delete it if undo was used
     */
    frameUndoManager.clearStack();

    const hasTooBigFile = filesToUpload.filter(
      (file) => !this._validateFileInput(file),
    );
    if (hasTooBigFile.length) return options.callback?.();

    /**
     * Yes, this is cheating, but passing the required data through
     * the multiple functions that call this method is almost impossible.
     */
    const team_id = StoryboardStore.getState().storyboard.project.owner.id;

    this.handleInsertFrames({
      frame_count: filesToUpload.length,
      storyboard_id: options.storyboard_id,
      callback: (newFrames) => {
        newFrames.forEach(({ id }) =>
          FrameActions.updateImageStatus.defer({
            frameId: id,
            status: 'creating_crop',
          }),
        );

        eachLimit(
          newFrames,
          3,
          (frame, done) => {
            const index = newFrames.indexOf(frame);
            const file = filesToUpload[index];

            FileUploadActions.updateFrame({
              frame_aspect_ratio: this._getStoryboardAspectRatio(),
              team_id,
              file,
              frame: frame,
              callback: () => done(),
            });
          },
          () => {
            options.callback?.();
          },
        );
      },
    });
  }

  _bulkAttachImagesToFrames(newFrames: bulkUpdatesArray, frameData) {
    for (var n in newFrames) {
      var data = frameData[n];
      data.id = newFrames[n].id;
      this._executeExistingFrameAjaxRequest(data);
    }
    this.updateFrameNumbers();
  }

  // Create a new frame
  _executeNewFrameAjaxRequest(options: NewFrameOptions) {
    if (notificationIfCannotCreateNewFrame(this)) return;

    // Add a new preview frame into the DOM prior to
    // it actually being created on the server
    var frameData = this._insertPreviewFrame(options);
    frameData.existing_frames = JSON.stringify(pluck(this.frames, 'id'));
    this.addToFrameHistory();

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'post',
      enctype: 'application/x-www-form-urlencoded',
      url: this._useGoAPI('frames'),
      data: {
        frame: frameData,
      },
      success: (response) => {
        const index = this.frames.findIndex(
          (f) => f.tmp_id === response.tmp_id,
        );
        if (index < 0) throw new Error('cannot find frame');
        // Setting these at the same time
        const frame = (this.frames[index] = response);

        this._liveNewFrame({
          frame: response,
          index: index,
        });
        // this.frames = sort(this.frames)
        this.updateFrameNumbers();
        this._runSort(pluck(this.frames, 'id'), options.storyboard_id, false);
        this._spreadDurationAfterAction('insert', index, frame);

        if (typeof options.frame_to_duplicate !== 'undefined') {
          if (response.id < 0) throw new Error("frame doesn't have id");
          this._duplicateFrame(
            { frame_to_duplicate: options.frame_to_duplicate },
            response.id,
          );
        } else {
          // Update drawing tool frame to new one
          FrameEditorActions.updateFrame.defer(frame);
          FrameEditorActions.updateFrame.defer(frame.id);
        }

        frameUndoManager.clearStack();
        if (typeof options.callback !== 'undefined') {
          options.callback(frame);
        }
        this.is_saving = false;
        this.emitChange();

        StoryboardActions.trackLoadEvent.defer({
          frames: this.frames,
          action: 'update',
        });

        CommentActions.setActiveFrame.defer(frame.id);
      },
      error: frameErrorHandler({
        rollbarMessage: '[GO API ERROR] POST /frames',
        messageKey: 'frames.errors.new',
        messageData: { count: 1 },
      }),
    });
  }

  _duplicateFrame(
    options: {
      frame_to_duplicate: IFrame;
    },
    frame_id: number,
  ) {
    var newFrame = this._findFrame(frame_id);
    newFrame.image_status = 'creating_record';
    this.emitChange();
    this._copyImageUrlsToNewFrame(options.frame_to_duplicate, frame_id);

    // This will get posted to the server in handleSaveText later
    newFrame.field_data = { ...options.frame_to_duplicate.field_data };

    // TODO@FieldData: handleSaveText already updates the field_data, so we
    // don't need to do anything now, but when we switch over
    // this code needs to be replaced by something that copies
    // over field_data
    each(
      noteFieldKeys,
      function (field_name) {
        this.handleSaveText({
          field_id: field_name,
          frame_id: frame_id,
          storyboard_id: options.frame_to_duplicate.storyboard_id,
          text: options.frame_to_duplicate[field_name],
          update_field_state: true,
          duplicated: true,
        });
      }.bind(this),
    );

    this.handleDeselectAll();
  }

  _copyImageUrlsToNewFrame(source_frame, frame_id) {
    var frame = this._findFrame(frame_id);

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'put',
      enctype: 'application/x-www-form-urlencoded',
      data: {
        frame: {
          storyboard_id: frame.storyboard_id,
          thumbnail_image_url: source_frame.thumbnail_image_url,
          large_image_url: source_frame.large_image_url,
          layer_data: source_frame.layer_data,
          generator_preferences: source_frame.generator_preferences,
          background_image_url: source_frame.background_image_url,
          seed: source_frame.seed,
        },
      },
      url: this._useGoAPI('frames/' + frame_id + '/image_url'),
      success: function (response) {
        frame.thumbnail_image_url = response.thumbnail_image_url;
        frame.large_image_url = response.large_image_url;
        frame.layer_data = response.layer_data || JSON.stringify({});
        frame.background_image_url = response.background_image_url;
        frame.seed = response.seed;
        frame.generator_preferences = response.generator_preferences;
        frame.image_status = 'complete';
        FrameEditorActions.updateFrame(frame.id);
        StoryboardActions.clearProjectCache.defer();

        this.emitChange();
      }.bind(this),
      error: frameErrorHandler({
        messageKey: 'frames.errors.genericSaving',
        rollbarMessage: '[GO API ERROR] PUT frames/ID/image_url',
      }),
    });
  }

  _findFrame(frame_id) {
    const frame = this.frames.find((f) => f && f.id === frame_id);
    if (!frame) throw new Error(`Could not find frame ${frame_id}`);
    return frame;
  }

  handleSaveText(options: {
    frame_id: number;
    field_id: string;
    text: string;
    storyboard_id: number;
    /** Triggers a proper update for the storyboard grid items */
    update_field_state?: boolean;
    /** Is saving this frame's text a result of a frame being duplicated? */
    duplicated?: boolean;
  }) {
    if (!options.field_id) throw new Error('needs field_id');
    if (!options.frame_id) throw new Error('needs frame id');

    this.ensureUndoItems();

    /**
     * Save the new changes to the field and to the history immediately, to
     * prevent race conditions which cause data to not be saved to the history
     */
    const frame = this.frames.find((f) => f.id === options.frame_id);
    if (!frame) return;

    const originalText = getFrameField(frame, options.field_id);

    const filteredText = trimHTML(sanitizeHTML(options.text, false));

    setFrameField(frame, options.field_id, filteredText);

    if (!options.duplicated) this.addToFrameHistory();

    // TODO@FieldData: Update when we deprecate the old frame fields
    if (hasRolloutFlagEnabled('Fielddata')) {
      debouncedAjax(options.frame_id)({
        method: 'patch',
        url: api.setRailsApiUrl(`frames/${options.frame_id}`),
        data: {
          data: {
            relationships: {
              storyboard: {
                data: {
                  id: frame.storyboard_id,
                },
              },
            },
            attributes: {
              field_data: JSON.stringify(frame.field_data),
            },
          },
        },
        beforeSend: api.setRailsApiAuthHeader,
        error: frameErrorHandler({
          messageKey: null,
          rollbarMessage: 'could not update field_data',
        }),
      });
    }

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'put',
      enctype: 'application/x-www-form-urlencoded',
      dataType: 'json',
      data: {
        frame: {
          storyboard_id: options.storyboard_id,
          field_name: options.field_id,
          text: filteredText,
        },
      },
      url: this._useGoAPI('frames/' + options.frame_id),
      success: (response) => {
        const frame = this.frames.find((f) => f.id === response.frame_id);
        if (!frame) return;

        if (response.text.length > 10) {
          Track.event.defer('added text to storyboard');
        }

        Track.aggregate.defer({
          event: 'storyboard_notes',
          count: response.text.length,
          threshold: 1000,
        });

        if (options.update_field_state) {
          frame.update_field_state = true;
          this.emitChange();
          frame.update_field_state = false;
          this.emitChange();
        } else {
          this.emitChange();
        }

        this._liveTextUpdate(options);

        StoryboardActions.trackLoadEvent.defer({
          frames: this.frames,
          action: 'update',
        });
      },
      error: frameErrorHandler(
        {
          messageKey: 'frames.errors.frameTextSave',
          rollbarMessage: '[GO API ERROR] PUT frames/ID',
        },
        () => {
          setFrameField(frame, options.field_id, originalText);
          this.emitChange();
          if (!options.duplicated) this.addToFrameHistory();
        },
      ),
    });
  }

  _resizeCloudinary(data) {
    var formattedUrl;
    var resizeString = '';
    var urlSegments = data.url.split('/');
    var replaceableSegment = urlSegments[urlSegments.length - 2];

    if (data.isThumbnail && data.aspectRatio == '16x9') {
      resizeString += 'w_480,h_270';
    } else if (!data.isThumbnail && data.aspectRatio == '16x9') {
      resizeString += 'w_1920,h_1080';
    } else if (!data.isThumbnail && data.aspectRatio == '9x16') {
      resizeString += 'w_1080,h_1920';
    } else if (data.isThumbnail && data.aspectRatio == '9x16') {
      resizeString += 'w_270,h_480';
    } else if (data.isThumbnail && data.aspectRatio == '1x1') {
      resizeString += 'w_480,h_480';
    } else if (!data.isThumbnail && data.aspectRatio == '1x1') {
      resizeString += 'w_1080,h_1080';
    }

    resizeString += ',c_pad,b_white';

    formattedUrl = data.url.replace(replaceableSegment, resizeString);

    return this._applyCloudinaryExt(formattedUrl);
  }

  _applyCloudinaryExt(url) {
    var parts = url.split('/');
    var filename = parts[parts.length - 1];
    var basename = filename.split('.')[0];
    var existingExt = filename.split('.')[1];
    var newExt;

    if (existingExt.toLowerCase() == 'gif') {
      newExt = '.gif';
    } else {
      newExt = '.jpg';
    }

    var convertedFile = url.replace(filename, basename + newExt);
    return convertedFile;
  }

  _getStoryboardAspectRatio() {
    const descriptive = frameAspectRatioNormalizer(
      BoordsConfig.FrameAspectRatio,
    );

    return aspectRatioNameToAspectRatio(descriptive);
  }

  _cloudinaryUpload(data) {
    ajax({
      method: 'post',
      url:
        'https://api.cloudinary.com/v1_1/' +
        BoordsConfig.CloudinaryJsFolder +
        '/image/upload',
      data: {
        file: data.image,
        upload_preset: BoordsConfig.CloudinaryJsUploadPreset,
      },
      success: function (response) {
        data.success(response);
        this.emitChange();
      }.bind(this),
      error: frameErrorHandler(
        {
          messageKey: 'frames.errors.uploadImage',
          rollbarMessage:
            '[CLOUDINARY ERROR] POST https://api.cloudinary.com/v1_1/' +
            BoordsConfig.CloudinaryJsFolder +
            '/image/upload',
        },
        () => this.emitChange(),
      ),
    });
  }

  _executeExistingFrameAjaxRequest(
    options: ExistingFrameOptions &
      Base64ImageProp & { onProgress?: (number) => void },
    callback?: GenericCallback,
  ) {
    const frame = this.frames.find((f) => f.id === options.id);
    if (!frame) throw new Error(`Could not find frame ${options.id}`);

    options.layer_data = JSON.stringify(
      options.layer_data ? options.layer_data : {},
    );

    if (options.image_file_size > maxImageFileSize) {
      RequestActions.error.defer({
        key: 'sharedErrors.fileSizeExceeded',
        data: { maxSize: maxImageFileSize + 'MB' },
      });
      return;
    }

    this._appendImageToFrameObject(options, frame);
    this.is_saving = true;

    this.emitChange();

    this._useDollyUpload(frame, options, callback);
    // if (BoordsConfig.CustomStorage || BoordsConfig.Rollout.Dolly) {
    // } else {
    //   this._useFilestackUpload(frame, options, callback);
    // }
  }

  _useDollyUpload: UploadFunction = (frame, options, callback) => {
    /**
     * Yes, this is cheating, but passing the required data through
     * the multiple functions that call this method is almost impossible.
     */
    const team_id = StoryboardStore.getState().storyboard.project.owner.id;
    FileUploadActions.uploadFrameImage.defer({
      fileData: frame.image,
      team_id: team_id,
      onProgress: options.onProgress,
      frameAspectRatio: this._getStoryboardAspectRatio(),
      callback: (result) => {
        // If an error occurred, do nothing (error is already shown)
        if (result) {
          this.handleUpdateFrameImageUrls({
            frame: frame,
            thumbnail_image_url: result.thumbnail_image_url,
            large_image_url: result.large_image_url,
            // We leave the background_image_url unaffected
            background_image_url: frame.background_image_url,
            createHistoryItem: true,
            callback: callback,
          });
        } else {
          frame.image_status = 'image_error';
          frame.error_message = 'Error uploading image';
          this.is_saving = false;
          if (callback) callback(false);
          this.emitChange();
        }
      },
    });
  };

  _useFilestackUpload: UploadFunction = (frame, options, callback) => {
    FilestackActions.uploadFrameFile.defer({
      file: frame.image,
      frame_aspect_ratio: this._getStoryboardAspectRatio(),
      frame,
      callback: (success) => {
        if (success === false) {
          frame.image_status = 'image_error';
          frame.error_message = 'Error uploading image';
        }
        this.is_saving = false;
        this.emitChange();
        if (callback) callback(success);
      },
    });
  };

  _useCloudinaryUpload: UploadFunction = (frame, options, callback) => {
    this._cloudinaryUpload({
      image: frame.image,
      success: function (response) {
        var background_image_url;

        var large_image_url = this._resizeCloudinary({
          url: response.secure_url,
          aspectRatio: this._getStoryboardAspectRatio(),
          isThumbnail: false,
        });

        var thumbnail_image_url = this._resizeCloudinary({
          url: response.secure_url,
          aspectRatio: this._getStoryboardAspectRatio(),
          isThumbnail: true,
        });

        /**
         * The lines below are commented out because they cause a bug
         * in the drawing tool (see https://trello.com/c/p11W7knh)
         * We need the background_image_url to be null, in order to
         * distinguish between frames that have images and those that
         * are empty, but have drawings.
         */

        // if (isUndefined(options.background_image_url)) {
        //   background_image_url = large_image_url;
        // } else {
        background_image_url = options.background_image_url;
        // }

        this.handleUpdateFrameImageUrls({
          frame: frame,
          thumbnail_image_url: thumbnail_image_url,
          large_image_url: large_image_url,
          background_image_url: background_image_url,
          callback: callback,
        });
      }.bind(this),
    });
  };

  handleUpdateFrameImageUrls(
    args: {
      frame: IFrame;
      replace?: boolean;
      callback?: GenericCallback;
      createHistoryItem?: boolean;
    } & IFrameImageProps,
  ) {
    const {
      frame,
      large_image_url,
      thumbnail_image_url,
      replace,
      callback,
      createHistoryItem,
      layer_data,
    } = args;

    let background_image_url = args.background_image_url;

    // Do not save to the history in this function, as it is called by
    // handleUpdateFrameHistory, so it would override further points in
    // history
    const original = pick(frame, [
      'thumbnail_image_url',
      'large_image_url',
      'background_image_url',
      'layer_data',
    ]);

    Object.assign(frame, {
      thumbnail_image_url,
      large_image_url,
      background_image_url,
    });

    if (args.seed) {
      frame.seed = args.seed;
    }

    if (replace || isEmpty(frame.layer_data)) {
      frame.layer_data = JSON.stringify({});
    }

    if (layer_data && isObject(layer_data)) {
      frame.layer_data = JSON.stringify(layer_data);
    }

    if (replace) background_image_url = null;

    /**
     * Typically we do NOT want to do this, as this function is called from
     * within `handleUpdateFrameHistory`, but when saving a response in the
     * Filestack store, we call this function to update the frame urls, in
     * that case, we DO want to create a new history item
     */
    if (createHistoryItem) this.addToFrameHistory();

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'put',
      enctype: 'application/x-www-form-urlencoded',
      data: {
        frame: {
          storyboard_id: frame.storyboard_id,
          layer_data: frame.layer_data,
          large_image_url: large_image_url,
          generator_preferences: frame.generator_preferences,
          seed: frame.seed,
          background_image_url: background_image_url,
          thumbnail_image_url: isUndefined(thumbnail_image_url)
            ? large_image_url
            : thumbnail_image_url,
        },
      },
      url: this._useGoAPI('frames/' + frame.id + '/image_url'),
      success: function (response) {
        frame.thumbnail_image_url = response.thumbnail_image_url;
        frame.large_image_url = response.large_image_url;
        frame.layer_data = response.layer_data;
        frame.background_image_url = response.background_image_url;
        frame.image_status = 'generating_thumbnail';
        frame.show_upload_image_prompt = false;

        var img = new Image();

        img.onload = function () {
          frame.image_status = 'complete';
          this.emitChange();
        }.bind(this);

        img.src = ignoreWebP(response.thumbnail_image_url);

        FrameEditorActions.updateFrame(frame.id);
        this._liveImageUpdate(frame);

        Track.event.defer('added images to storyboard');

        StoryboardActions.clearProjectCache.defer();

        if (callback) {
          callback(true);
        }

        this.updateFrameNumbers();

        this.is_saving = false;
        this.emitChange();
        AssetsActions.refresh.defer(this.storyboard_id);

        StoryboardActions.trackLoadEvent.defer({
          frames: this.frames,
          action: 'update',
        });
      }.bind(this),
      error: frameErrorHandler(
        {
          messageKey: 'frames.errors.genericSaving',
          rollbarMessage: '[GO API ERROR] PUT frames/ID/image_url',
        },
        () => {
          Object.assign(frame, original);
          frame.image_status = 'image_error';
          frame.error_message = 'Error uploading image';
          if (callback) callback(false);
          this.emitChange();
        },
      ),
    });
  }

  handleSortFrames(options: {
    newIndex: number;
    /** All the frames in their new order */
    frames: IFrame[];
    storyboard_id: number;
  }) {
    if (!options.storyboard_id) throw new Error('need a storyboard id');
    /**
     * If we're moving a frame in between other frames in the same group,
     * adopt that group */
    const existingGroupId = getAdjacentGroup(
      this.frames[options.newIndex - 1],
      this.frames[options.newIndex + 1],
    );

    const selectedFrames = this.frames.filter((f) => f.is_selected);
    const firstFrameBeingMoved = options.frames[options.newIndex];
    const firstGroup = firstFrameBeingMoved?.group_id;

    const userIsMovingEntireGroup =
      firstGroup &&
      isEqual(
        selectedFrames,
        this.frames.filter((f) => f.group_id === firstGroup),
      );

    if (existingGroupId) {
      let framesToUpdate: IFrame[] = [];

      if (!this.frames[options.newIndex].is_selected) {
        // If the frame we want to drag is not selected, ignore selection and
        // only group that one
        framesToUpdate = [this.frames[options.newIndex]];
      } else {
        framesToUpdate = selectedFrames;
      }

      // Iterate over the framesToUpdate, set the group
      framesToUpdate.forEach((f) => (f.group_id = existingGroupId));
      this.handleBatchUpdates(
        framesToUpdate.map((f) => ({
          id: f.id,
          group_id: f.group_id,
        })),
        { commit: true },
      ).catch(frameErrorHandler());
    } else if (
      // If the user is not moving the entire group, and the moved frames
      // have a group_id, it means we're moving certain frames out of a group
      !userIsMovingEntireGroup &&
      firstFrameBeingMoved?.group_id
    ) {
      const framesToUpdate = uniq([...selectedFrames, firstFrameBeingMoved]);

      this.handleBatchUpdates(
        framesToUpdate.map((frame) => ({
          id: frame.id,
          group_id: null,
        })),
        { commit: true },
      ).catch(frameErrorHandler());
    }

    this.frames = sort(options.frames);
    this._runSort(
      this.frames.map((f) => f.id),
      options.storyboard_id,
    );
    this.updateFrameNumbers();
  }

  _runSort(frameIds: frameId[], storyboard_id: number, pushLiveUpdate = true) {
    if (!frameIds) return;
    // Sometimes, new frames with temporary ids are asked to be sorted, which
    // is a bad idea, we shouldn't commit these to the server. We'll still
    // save the rest.
    frameIds = frameIds.filter((f) => f >= 0);

    // if (frameIds.length === 0) return; Uncommenting this would make it impossible to delete all frames. If some other issue pops up because of this, find another way

    if (isUndefined(storyboard_id)) {
      RequestActions.error.defer('Error saving changes');
    } else {
      this.is_saving = true;

      ajax({
        beforeSend: this._setAuthHeader,
        method: 'post',
        dataType: 'json',
        url: this._useGoAPI('storyboards/' + storyboard_id + '/sort_frames'),
        enctype: 'application/x-www-form-urlencoded',
        data: {
          frames: JSON.stringify(frameIds),
        },
        success: function () {
          this.is_saving = false;
          this.emitChange();
          this._requestComplete();

          if (pushLiveUpdate) {
            this._liveSort(frameIds);
          }

          StoryboardActions.clearProjectCache.defer();
        }.bind(this),
        error: frameErrorHandler(
          {
            messageKey: 'frames.errors.genericSaving',
            rollbarMessage: '[GO API ERROR] POST storyboards/ID',
          },
          () => {
            this.is_saving = false;
            this.emitChange();
          },
        ),
      });
    }
  }

  handleDeleteFrames(frame_id_array: number | number[]) {
    this.ensureUndoItems();
    if (isNumber(frame_id_array)) frame_id_array = [frame_id_array];
    if (frame_id_array.length === 0) {
      RequestActions.error('No frames to delete');
      return;
    }

    var storyboard_id = this.frames[0].storyboard_id;
    each(
      frame_id_array,
      function (frame_id) {
        var frameInStore = findWhere(this.frames, {
          id: parseInt(frame_id, 10),
        });
        var originalIndex = this.frames.indexOf(frameInStore);
        // Remove the existing frame
        this.frames = without(this.frames, frameInStore);
        // Update all subsequent frames to be one less sort order
        this.updateFrameNumbers();
        try {
          this._spreadDurationAfterAction(
            'delete',
            originalIndex,
            frameInStore,
          );
        } catch (err) {
          frameErrorHandler({ messageKey: null })(err);
        }
      }.bind(this),
    );
    this._runSort(pluck(this.frames, 'id'), storyboard_id);
    this.addToFrameHistory(this.frames);
    AssetsActions.refresh.defer(this.storyboard_id);
    StoryboardAnalysisActions.scheduleAnalysis.defer();
    this.emitChange();
  }

  _frameAssetCleanup(frame_id_array: number[]) {
    ajax({
      beforeSend: this._setAuthHeader,
      method: 'delete',
      dataType: 'json',
      url: '/frames',
      data: {
        frames: frame_id_array,
      },
      error: function (response) {
        XhrErrorActions.show.defer({
          status_code: response.status,
          context: 'storyboard:frames:delete',
          response,
        });
      }.bind(this),
    });
  }

  updateFrameNumbers() {
    updateFrameNumbers(this.frames);
    // Create an object (keyed by group_id) with the id of the
    // frame in the middle of a group
    this.groupInfo = mapObject(groupBy(this.frames, 'group_id'), (v) => ({
      start: v[0].id,
      middle: v[Math.floor((v.length - 1) / 2)].id,
      end: last(v)!.id,
      frames: v,
    }));

    for (var f in this.frames) {
      const newOrder = parseInt(f, 10) + 1;
      this.frames[f].sort_order = newOrder;
    }
  }

  // Called when the AI script generator is used
  // to check if image generation is complete
  checkGeneratingFrameStatus(index: number) {
    const frame = this.frames[index];
    ajaxRuby({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-KEY': BoordsConfig.AuthenticationToken,
      },
      method: 'get',
      dataType: 'json',
      url: `/api/frames/${frame.id}?fields=background_image_url,generator_processing,thumbnail_image_url`,
    }).then(
      (response: GeneratorPollFrameResponse) => {
        const data = response.data.attributes;
        if (!data.generator_processing) {
          this.frames[index].background_image_url = data.background_image_url;
          this.frames[index].large_image_url = data.background_image_url;
          this.frames[index].thumbnail_image_url = data.thumbnail_image_url;
          this.frames[index].generator_processing = false;
          this.frames[index].image_status = ``;
          this.emitChange();
        } else {
          setTimeout(() => this.checkGeneratingFrameStatus(index), 5000);
        }
      },
      (err) => {
        this.frames[index].generator_processing = false;
        this.frames[index].image_status = ``;
        this.emitChange();
      },
    );
  }
  // Called when we receive a response from either the Fetch call, or
  // when the shareable store receives its data
  handleReceiveFrames(response) {
    this.storyboard_id = response.id;
    this.frames = response.frames.map((f) => deserializeJSONFields(f));
    this.updateFrameNumbers();
    this.emitChange();
    this.has_initial_content = true;

    if (response.context !== 'presentation') {
      this.frames.forEach((frame, index) => {
        if (frame.generator_processing) {
          frame.image_status = `generating_image`;
          this.checkGeneratingFrameStatus(index);
        }
      });
    }

    // We don't track the edit view load event here
    // because we can't get context on the storyboard, e.g.
    // aspectRatio, comments enabled, etc
    if (response.context === 'presentation') {
      const { preferences, password_protected } = response;
      Track.event.defer(
        `presentation_load`,
        {
          password: password_protected,
          isGuest: typeof BoordsConfig.Uid === 'undefined',
          grid: preferences.share_as_grid,
          animatic: preferences.share_as_animatic,
          category: 'Product',
          frames: this.frames.length,
        },
        1000,
      );
    } else {
      StoryboardActions.trackLoadEvent.defer({
        frames: this.frames,
        action: 'load',
      });
    }
  }

  // Initial fetch of frames
  handleFetchFrames(obj: {
    storyboard_id: number;
    cachebust?: boolean;
    callback?: () => void;
  }) {
    this.is_loading = true;
    this.storyboard_id = obj.storyboard_id;
    if (!this.pusher_loaded && typeof pusher !== 'undefined') {
      this.handlePusherLoaded();
    }

    let url = this._useGoAPI('storyboards/' + obj.storyboard_id);
    if (obj.cachebust) url += '?bust=' + Date.now().toFixed();

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'get',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      success: (response) => {
        this.is_loading = false;
        // use the action, because we want the receive action to fire
        FrameActions.receiveFrames.defer(response);

        if (obj.callback) setTimeout(() => obj.callback!(), 500);
      },
      error: function (response) {
        if (process.env.NODE_ENV !== 'development') {
          XhrErrorActions.show.defer({
            status_code: response.status,
            context: 'storyboard:frames:fetch',
            response,
          });
        }
      }.bind(this),
    });
  }

  // Sorting and deleting
  handleStartDragging(frame_id: number) {
    this.is_dragging_existing = frame_id;
  }

  handleEndDragging() {
    this.is_dragging_existing = null;
  }

  handleMouseEnterInsertAfter(frame_id: number) {
    this.frame_left = frame_id;
    this.frame_right = frame_id + 1;
  }

  handleMouseLeaveInsertAfter() {
    this.frame_left = 0;
    this.frame_right = 0;
  }

  handleMouseEnterInsertBefore(frame_id: number) {
    this.frame_left = frame_id - 1;
    this.frame_right = frame_id;
  }

  handleMouseLeaveInsertBefore() {
    this.frame_left = 0;
    this.frame_right = 0;
  }

  // When I last checked this, this functionality is accessed by clicking on
  // the Replace button in the frame toolbar
  handleClearFrame(frame_id: number) {
    this.ensureUndoItems();
    const frameIndex = findIndex(this.frames, (f) => f.id === frame_id);
    const frame = this.frames[frameIndex];

    frame.background_image_url = null;
    frame.seed = 0;
    frame.image_url = '/assets/missing.jpg';
    frame.thumbnail_image_url = '/assets/missing.jpg';
    frame.large_image_url = '/assets/missing.jpg';
    frame.layer_data = JSON.stringify({});
    this.addToFrameHistory();

    ajax({
      beforeSend: this._setAuthHeader,
      method: 'post',
      dataType: 'json',
      enctype: 'application/x-www-form-urlencoded',
      data: {
        frame: {
          storyboard_id: frame.storyboard_id,
        },
      },
      url: this._useGoAPI('frames/' + frame_id + '/remove_image'),
      success: function (response) {
        const changes = pick(response, [
          'thumbnail_image_url',
          'large_image_url',
          'background_image_url',
          'layer_data',
        ]);

        this.frames[frameIndex] = Object.assign(
          {},
          this.frames[frameIndex],
          changes,
        );

        this.emitChange();
        FrameEditorActions.updateFrame(frame_id);
        AssetsActions.refresh.defer(this.storyboard_id);
      }.bind(this),
      error: frameErrorHandler({
        messageKey: 'frames.errors.clear',
        rollbarMessage: '[GO API ERROR] POST frames/ID/remove_image',
      }),
    });
  }

  /** Update a single frame's duration */
  handleUpdateDuration(options: {
    frameId: number;
    duration: number;
    originalDuration: number;
  }) {
    if (!options.duration) return;

    const frameId = options.frameId;
    const originalDuration = options.originalDuration;
    const frameIndex = findIndex(this.frames, (f) => f.id === frameId);

    const duration = ensureNumber(
      clampDuration(options.duration),
      frameErrorHandler({ message: null }),
    );

    // If we don't have a duration, the frameErrorHandler will already have been called
    if (!duration) return;

    this.frames[frameIndex].duration = duration;
    this.frames[frameIndex].update_field_state = true;
    this.emitChange();

    StoryboardAnalysisActions.scheduleAnalysis.defer();

    debouncedAjax(frameId)({
      beforeSend: this._setAuthHeader,
      method: 'post',
      url: this._useGoAPI('frames/' + frameId + '/duration'),
      enctype: 'application/x-www-form-urlencoded',
      data: {
        frame: { duration },
      },
      complete: () => (this.frames[frameIndex].update_field_state = false),
      error: frameErrorHandler(
        {
          rollbarMessage: '[GO API ERROR] POST frames/ID/duration',
          messageKey: 'frames.errors.updateFrameTimings',
        },
        () => {
          this.frames[frameIndex].duration = originalDuration;
          StoryboardAnalysisActions.scheduleAnalysis.defer();
          this.emitChange();
        },
      ),
    });
  }

  /** Set only, don't save to the server. For example for resetting a value
   * after a failed request. Also notifies PlayerStore */
  handleBatchSet(updates: bulkUpdatesArray) {
    updates.forEach((u) => {
      const originalFrameIndex = this.frames.findIndex((f) => f.id === u.id);
      if (originalFrameIndex === -1) {
        throw new Error(
          `could not update frame with id ${u.id}, because it wasn't found`,
        );
      }

      // Update the original object, in case it's hanging around somewhere
      Object.assign(this.frames[originalFrameIndex], u);

      // Create a new object to satisfy any components relying on
      // immutability
      this.frames[originalFrameIndex] = {
        ...this.frames[originalFrameIndex],
      };
    });
  }

  /** Used for importing data from actual clients for local debugging purposes */
  batchImport(frames: DetailedFrame[]) {
    if (process.env.NODE_ENV !== 'development')
      throw new Error('this feature is ony available in  development');

    if (frames.length !== this.frames.length)
      throw new Error(
        `storyboard needs to have ${frames.length} frames to continue, currently only has ${this.frames.length}`,
      );

    const fields = [
      'group_id',
      'large_image_url',
      'thumbnail_image_url',
      'field_data',
      'background_image_url',
      // 'layer_data',
      'sort_order',
      'duration',
    ];

    const updates = frames.reduce<bulkUpdatesArray>((output, frame, i) => {
      const item: bulkUpdatesArray[number] = {
        id: this.frames[i].id,
      };

      fields.forEach(
        (fieldName) => (item[fieldName] = frame[fieldName] ?? null),
      );
      output.push(item);

      return output;
    }, []);

    this.handleBatchUpdates(updates, { commit: true }).then((response) => {
      this.handleFetchFrames({ storyboard_id: this.storyboard_id });

      RequestActions.success(
        'Batch import complete, you might need to reload to see all changes',
      );
    });
  }

  /** Updates frame values on the server without updating local objects (unless
   * `options.commit` is `true`) */
  handleBatchUpdates(
    updates: bulkUpdatesArray,
    options = {
      /** Also save the options to the commits using `this.handleBatchSet` */
      commit: false,
    },
  ) {
    return new Promise<DetailedFrame[]>((resolve, reject) => {
      /**
       * Some values might be undefined, which causes the occasional server
       * error. So we should discard values that are undefined. Please note
       * that we **should** keep values set to `null` here, as they are
       * used to set `group_id`s to an empty value (when ungrouping)
       */
      const filteredUpdates = updates.filter((u) => u.id > 0).map(pickDefined);

      if (filteredUpdates.length === 0)
        return reject('filteredUpdates.length === 0');

      if (some(filteredUpdates, (u) => Object.keys(u).length === 1)) {
        rollbar.info('not enough properties in updates', filteredUpdates);
        return resolve([]);
      }

      if (options.commit) this.handleBatchSet(filteredUpdates);

      const serverUpdates = filteredUpdates.map((update) => {
        const output = { ...update };
        if (output.large_image_url) {
          output.go_image_original_url = output.large_image_url;
          delete output.large_image_url;
        }
        if (output.thumbnail_image_url) {
          output.go_image_thumbnail_url = output.thumbnail_image_url;
          delete output.thumbnail_image_url;
        }

        return output;
      });

      ajaxRuby({
        beforeSend: this._setAuthHeader,
        method: 'post',
        dataType: 'json',
        url: '/frames',
        data: {
          storyboard_id: this.frames[0].storyboard_id,
          frames: JSON.stringify(serverUpdates),
        },
      }).then(resolve, reject);
    });
  }

  handleBatchUpdateDurations(
    updates: Array<{
      id: number;
      duration: number;
      originalDuration: number;
    }>,
  ) {
    if (updates.length === 0) return;
    const payload = updates.map((u) => ({
      id: u.id,
      duration: ensureNumber(
        clampDuration(u.duration),
        frameErrorHandler({ message: null }),
      ),
    }));

    this.handleBatchUpdates(payload, { commit: true }).catch(
      frameErrorHandler(
        {
          messageKey: 'frames.errors.updateFrameTimings',
        },
        () =>
          FrameActions.batchSet.defer(
            updates
              .filter((u) => u.originalDuration)
              .map((u) => ({
                id: u.id,
                duration: u.originalDuration,
              })),
          ),
      ),
    );
  }

  checkForAudio = (storyboardId: number) =>
    new Promise<boolean>((resolve) => {
      const playerStore: PlayerStoreType = PlayerStore.getState();

      if (
        playerStore.audio.data?.url &&
        isString(playerStore.audio.data?.url)
      ) {
        // This means we can confidently say we have an audio file for this storyboard
        resolve(true);
      } else if (playerStore.audio.state === 'unfetched') {
        logger.log(
          'Need to fetch audio info in order to know if we should change adjacent frame durations',
        );
        PlayerActions.fetchAudioFile.defer({
          storyboardId: storyboardId,
          callback: () => {
            this.checkForAudio(storyboardId).then(resolve);
          },
        });
      } else if (playerStore.audio.state === 'done') {
        resolve(false);
      }
    });

  // Adjust the frame duration of adjacent frames after deleting or inserting
  async _spreadDurationAfterAction(
    action: 'insert' | 'delete',
    index: number,
    frame: DetailedFrame,
  ) {
    const isFirstFrame = index === 0;
    if (this.frames.length < 1) return;

    const getPreviousFrame = () => {
      const o = this.frames[index - 1];
      if (!o) return;
      return o;
    };

    if (action === 'insert') {
      /**
       * The inserted frame should be assigned a duration of half that of
       * the frame before it. The frame before it should then have its
       * duration halved. If the new frame is the first frame, use the
       * next frame instead
       */
      const refFrame = isFirstFrame ? this.frames[1] : getPreviousFrame()!;
      const duration = clampDuration(refFrame.duration / 2);

      if (await this.checkForAudio(frame.storyboard_id)) {
        logger.log(
          "Halving the previous frame's duration to make room for the new one",
        );
        // Update the new frame and the reference frame
        this.handleUpdateDuration({
          frameId: frame.id,
          duration,
          originalDuration: refFrame.duration,
        });
        this.handleUpdateDuration({
          frameId: refFrame.id,
          duration,
          originalDuration: refFrame.duration,
        });
      } else {
        logger.log(
          "Using the default frame duration because no audio is present (not halving the previous frame's duration)",
        );
      }
    } else if (action === 'delete') {
      /**
       * the deleted frame's duration should be added to the duration
       * of the frame before it to maintain the structure of the animatic.
       * If this was the first frame, we can assume it has already been.
       * removed, so we just use the new first frame as a reference
       */
      const refFrame = isFirstFrame ? this.frames[0] : getPreviousFrame()!;
      const duration = refFrame.duration + frame.duration;

      if (await this.checkForAudio(frame.storyboard_id)) {
        logger.log(
          "Extending the previous frame's duration to fill up space after the deleted one",
        );

        this.handleUpdateDuration({
          frameId: refFrame.id,
          duration,
          originalDuration: refFrame.duration,
        });
      } else {
        logger.log(
          "Using the default frame duration because no audio is present (not extending the previous frame's duration)",
        );
      }
    }
  }

  handleSetFramesStatus(args: {
    frames: Array<Pick<IFrame, 'id'>>;
    status: string;
  }) {
    const payload = args.frames.map((f) => ({
      id: f.id,
      status: {
        type: args.status,
        updated_by: BoordsConfig.Name,
      },
    }));

    this.handleBatchUpdates(payload, { commit: true }).catch(
      frameErrorHandler(),
    );
  }

  handleSelectGroup(groupId) {
    if (isUndefined(groupId)) return;

    this.frames.forEach((f) => {
      f.is_selected = f.group_id === groupId;
    });
    this.updateSelected();
    this.emitChange();
  }

  handleGroupFrames(framesToGroup) {
    this.ensureUndoItems();
    group(framesToGroup);
    this.commitGroupStatus();
    // For some reason the frames inside framesToGroup are not the actual
    // frame objects as the ones in this store
    this.handleDeselectAll();
    this.addToFrameHistory();

    Track.event.defer('frame_group');
  }

  handleUngroupFrames(framesToUngroup) {
    this.ensureUndoItems();
    ungroup(framesToUngroup, this.frames);
    this.commitGroupStatus();
    this.addToFrameHistory();

    Track.event.defer('frame_ungroup');
  }

  commitGroupStatus() {
    this.frames = sort(this.frames);
    this.updateFrameNumbers();
    const updates = this.frames.map((f) => ({
      id: f.id,
      group_id: f.group_id || null,
      sort_order: f.sort_order,
    }));

    this.handleBatchUpdates(updates, { commit: true })
      .then(() => {
        this._runSort(
          pluck(this.frames, 'id'),
          this.frames[0].storyboard_id,
          true,
        );
      })
      .catch(
        frameErrorHandler({
          messageKey: 'frames.errors.group',
          rollbarMessage: 'Error while grouping/ungrouping',
        }),
      );
  }

  /** Collects the data for the undo information and saves it to history */
  addToFrameHistory(frames?: IFrame[]) {
    const f = frames || this.frames;
    const frameImages = f.map((frame) =>
      pick(frame, [
        'thumbnail_image_url',
        'large_image_url',
        'background_image_url',
        'layer_data',
      ]),
    );

    // TODO@FieldData: one more thing to change once we switch over to field_data
    const frameFields = f.map((frame) =>
      pick(frame, [...noteFieldKeys, 'field_data'] as any),
    );

    frameUndoManager.add({
      frames: [...f],
      groupIds: f.map((f) => f.group_id),
      frameFields,
      frameImages,
    });
  }

  /**
   * Because we're carefull, we don't actually save the current state to the
   * undo history until we execute the first action that is undoable.
   * This function can be called to create the initial/fallback item */
  ensureUndoItems() {
    if (frameUndoManager.getLength() === 0) this.addToFrameHistory(this.frames);
  }

  /** The callback that will be fired when undo/redo is used */
  handleUpdateFrameHistory({
    frames,
    groupIds = [],
    frameImages,
    frameFields,
  }: {
    frames: IFrame[];
    groupIds?: string[];
    frameImages: IFrameImageProps[];
    frameFields?: Array<IFrameNotesProps & { field_data: fieldData }>;
  }) {
    const uniqFrames: IFrame[] = uniq(frames);
    const hasOrderChanges =
      some(
        uniqFrames,
        (f, i) => !this.frames[i] || f.id !== this.frames[i].id,
      ) || uniqFrames.length !== this.frames.length;

    if (hasOrderChanges) this.frames = sort(uniqFrames);

    const hasGroupChanges =
      some(uniqFrames, (f, i) => f.group_id !== groupIds[i]) ||
      groupIds.length !== this.frames.length;

    if (hasGroupChanges) {
      this.frames.forEach((f, fi) => (f.group_id = groupIds[fi]));
      this.commitGroupStatus();
    }

    if (frameImages && frameImages.length === this.frames.length) {
      frameImages.forEach((changeSet, i) => {
        // If any of the values are different, apply the changeset
        if (some(changeSet, (v, k) => v !== this.frames[i][k])) {
          Object.assign(this.frames[i], changeSet);
          this.frames[i].update_field_state = true;
          this.handleUpdateFrameImageUrls({
            frame: this.frames[i],
            ...changeSet,
          });
        }
      });
    }

    if (frameFields && frameFields.length === this.frames.length) {
      const updates: bulkUpdatesArray = [];
      frameFields.forEach((changeSet, i) => {
        // If any of the values are different, apply the changeset
        // console.log(changeSet, this.frames[i]);
        if (some(changeSet, (v, k) => v !== this.frames[i][k])) {
          this.frames[i].update_field_state = true;
          Object.assign(this.frames[i], changeSet);
          updates.push({
            id: this.frames[i].id,
            ...changeSet,
            field_data: cleanFieldData(
              changeSet.field_data,
              getFrameFieldInfo(),
            ),
          });
        }
      });

      if (updates.length) {
        this.handleBatchUpdates(updates)
          .then(() =>
            this.frames.forEach((f) => (f.update_field_state = false)),
          )
          .catch(frameErrorHandler());
      }
    }

    this.updateFrameNumbers();
    if (hasOrderChanges) {
      this._runSort(pluck(this.frames, 'id'), this.storyboard_id);
    }

    this.emitChange();
  }

  handleUpdateFrameGeneratorPreferences({
    frameId,
    generatorPreferences,
  }: {
    frameId: number;
    generatorPreferences: GeneratorPreferences;
  }) {
    const frame = this._findFrame(frameId);
    if (!frame) throw new Error('Frame id not found');

    frame.generator_preferences = JSON.stringify(generatorPreferences);
    this.emitChange();
  }

  handleUpdateImageStatus({
    frameId,
    status,
    message,
  }: {
    frameId: number;
    status: frameStatus;
    message?: string;
  }) {
    const frame = this.frames.find((f) => f.id === frameId);
    if (!frame) throw new Error('Frame id not found');

    frame.image_status = status;
    frame.error_message = message;
    this.emitChange();
  }

  handlePermanentlyDeleteFrame(args: {
    frameId: number;
    storyboardHashid: string;
    callback?: (success: boolean) => void;
  }) {
    ajax({
      method: 'delete',
      dataType: 'json',
      beforeSend: api.setRailsApiAuthHeader,
      url: api.setRailsApiUrl(`frames/${args.frameId}`),
      data: {
        data: {
          attributes: {
            storyboard_hashid: args.storyboardHashid,
          },
        },
      },
      error: frameErrorHandler(
        {
          messageKey: 'frames.errors.delete',
        },
        () => args.callback?.(false),
      ),
      success: () => {
        args.callback?.(true);
      },
    });
  }
}

(window as any).FrameStore = alt.createStore(FrameStore, 'FrameStore');
