/** @prettier */
import { PDFToCanvasImage } from '../coverToPng';
import makeItFit from 'javascripts/helpers/makeItFit';
import { createPDFImage } from './PDFImage';
import type { DocInfo } from '../types';

const watermarkURL =
  require('file-loader!../../../../../../lib/templates/watermarks/A4-landscape.pdf').default;

export const applyPDFWatermark = (docInfo: DocInfo) =>
  new Promise<void>((resolve, reject) => {
    PDFToCanvasImage(watermarkURL, 1, 3)
      .then((imageInfo) => {
        const { x, y, width, height } = makeItFit(
          imageInfo,
          {
            width: docInfo.doc.internal.pageSize.width,
            height: docInfo.doc.internal.pageSize.height,
          },
          {
            cover: true,
          },
        );

        createPDFImage(docInfo, {
          x,
          y,
          width,
          height,
          imageInfo,
        });
        resolve();
      }, reject)
      .catch(reject);
  });
