/** @prettier */
import logger from 'javascripts/helpers/logger';
import React, { createContext, useState, useCallback } from 'react';
import i18n from 'i18next';
import { loadStripe } from '@stripe/stripe-js/pure';

export type CreditBundleCurrency = 'usd' | 'gbp' | 'eur';

export interface CreditBundlePrice {
  name: string;
  slug: string;
  currency: CreditBundleCurrency;
  interval: 'once';
  amount: number;
}

export interface CreditBundle {
  name: string;
  slug: string;
  description: string;
  quantity: number;
  isAvailable?: boolean;
  isFeatured?: boolean;
  prices: CreditBundlePrice[];
}

interface CreditsContextProps {
  selectedBundleId: string | undefined;
  isProcessing: boolean;
  errorMessage: string | undefined;
  creditBundles: CreditBundle[];
  handleBundleSelect: (bundleId: string) => void;
  handlePurchaseCredits: () => void;
  trackCreditEvent: (eventName: string, args?: object) => void;
  clearError: () => void;
}

const initialContext: CreditsContextProps = {
  selectedBundleId: undefined,
  isProcessing: false,
  errorMessage: undefined,
  creditBundles: [],
  handleBundleSelect: () => {},
  handlePurchaseCredits: () => {},
  trackCreditEvent: () => {},
  clearError: () => {},
};

export const CreditsContext =
  createContext<CreditsContextProps>(initialContext);

interface CreditsProviderProps {
  children: React.ReactElement;
  currency?: CreditBundleCurrency;
}

export const CreditsProvider: React.FC<CreditsProviderProps> = ({
  children,
  currency = BoordsConfig.Currency,
}) => {
  // Load bundles from i18n
  const initBundles = i18n.t('topups:products', {
    returnObjects: true,
  }) as CreditBundle[];

  const creditBundles = initBundles.filter((b) => b.isAvailable);

  const [selectedBundleId, setSelectedBundleId] = useState<string | undefined>(
    () => {
      // Default to medium bundle (second item)
      const defaultBundle = creditBundles[1];
      return defaultBundle?.slug;
    },
  );

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [stripe, setStripe] = useState<any>(undefined);

  const trackCreditEvent = useCallback((eventName: string, args?: object) => {
    Track.event.defer(eventName, {
      category: 'Checkout',
      isCredits: true,
      ...args,
    });
  }, []);

  const handleError = (error: string) => {
    setIsProcessing(false);
    setErrorMessage(error);
    trackCreditEvent('credit_purchase_error', { error });
  };

  const handleBundleSelect = useCallback((bundleId: string) => {
    setSelectedBundleId(bundleId);
  }, []);

  const clearError = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  const loadStripeAsync = async () => {
    try {
      const stripeInstance = await loadStripe(BoordsConfig.stripePubKey);
      if (stripeInstance) {
        setStripe(stripeInstance);
        return stripeInstance;
      }
    } catch (error) {
      handleError('Failed to load Stripe');
      return null;
    }
  };

  const createCheckoutSession = async (stripeInstance: any) => {
    try {
      const response = await fetch('/checkout/credits', {
        method: 'post',
        body: JSON.stringify({
          data: {
            attributes: {
              addon: selectedBundleId,
            },
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      const result = await response.json();

      if (response.ok && result.data?.attributes?.session_id) {
        await stripeInstance.redirectToCheckout({
          sessionId: result.data.attributes.session_id,
        });
      } else {
        const errorMessage =
          result.errors?.[0]?.detail || 'Failed to create checkout session';
        handleError(errorMessage);
      }
    } catch (error) {
      handleError(error.message);
    }
  };

  const handlePurchaseCredits = useCallback(async () => {
    if (!selectedBundleId) return;

    setIsProcessing(true);
    clearError();

    try {
      const selectedBundle = creditBundles.find(
        (bundle) => bundle.slug === selectedBundleId,
      );

      if (!selectedBundle) {
        throw new Error('Invalid bundle selected');
      }

      const selectedPrice = selectedBundle.prices.find(
        (price) => price.currency === currency,
      );

      if (!selectedPrice) {
        throw new Error('Price not found for selected currency');
      }

      trackCreditEvent('InitiateCheckout', {
        bundleId: selectedBundleId,
        amount: selectedPrice.amount,
        currency: selectedPrice.currency,
      });

      let stripeInstance = stripe;
      if (!stripeInstance) {
        stripeInstance = await loadStripeAsync();
        if (!stripeInstance) return;
      }

      await createCheckoutSession(stripeInstance);
    } catch (error) {
      handleError(error.message);
    }
  }, [selectedBundleId, currency, stripe]);

  const value: CreditsContextProps = {
    selectedBundleId,
    isProcessing,
    errorMessage,
    creditBundles,
    handleBundleSelect,
    handlePurchaseCredits,
    trackCreditEvent,
    clearError,
  };

  return (
    <CreditsContext.Provider value={value}>{children}</CreditsContext.Provider>
  );
};
