/** @prettier */
import { FakeAltStoreClass } from './AltStore';
import * as screenSizeMonitor from '../../components/shared/screenSizeMonitor';
import '../actions/storyboardZoom';
import './panelbar';
import '../actions/storyboard';
import {
  gridViewColumnsLocalState,
  presentationViewColumnsLocalState,
} from '../../helpers/local-state';
import { isNumber } from 'underscore';
import { StoryboardZoomActions } from '../actions/storyboardZoom';
import { clampNumber } from 'javascripts/helpers/clampNumber';
import { PanelbarActions } from '../actions/panelbar';

export interface IStoryboardZoomSettings {
  columns: number;
  min: number;
  max: number;
}

const PANEL_BAR_WIDTH = 400;

const getDefaultColumns = (max: number): number => {
  // For smaller screens where max might be 3-4, use a lower default
  if (max <= 4) return 3;

  // For medium screens, use about 60% of max
  if (max <= 8) return Math.floor(max * 0.6);

  // For larger screens, use about 50% of max to avoid too many columns by default
  return Math.floor(max * 0.5);
};

const getZoomSettings = (
  columns: number,
  screenWidth: number,
): IStoryboardZoomSettings => {
  const minWidth = 140;
  const averageGutter = 16;
  const lostInWhiteSpace = 50 - averageGutter; // one less
  const left = screenWidth - lostInWhiteSpace;
  const max = Math.floor(left / (minWidth + averageGutter));

  const zoomSettings = {
    min: 2,
    max,
  };

  return {
    ...zoomSettings,
    columns: clampNumber(columns, zoomSettings.min, zoomSettings.max),
  };
};

const context =
  BoordsConfig?.controller === 'shareable' ? 'shareable' : 'storyboard';

export class StoryboardZoomStore extends FakeAltStoreClass<StoryboardZoomStore> {
  zoomSettings: IStoryboardZoomSettings;
  frameRatio: number;
  panelbarIsOpen: boolean;
  localState =
    context === 'shareable'
      ? presentationViewColumnsLocalState
      : gridViewColumnsLocalState;

  constructor() {
    super();
    this.panelbarIsOpen = PanelbarStore.getState().isOpen;
    let width = window.innerWidth;
    if (this.panelbarIsOpen) width -= PANEL_BAR_WIDTH;

    // Calculate initial zoom settings with a temporary column count to get max
    const initialSettings = getZoomSettings(4, width);

    // Get the stored columns or calculate default based on screen size
    const columns =
      this.localState.getValue() ?? getDefaultColumns(initialSettings.max);

    // Now get the final settings with the proper column count
    this.zoomSettings = getZoomSettings(columns, width);

    this.bindListeners({
      handleUpdateZoomLevel: [StoryboardZoomActions.UPDATE_ZOOM_LEVEL],
      handlePanelbarChange: [
        PanelbarActions.TOGGLE,
        PanelbarActions.OPEN,
        PanelbarActions.CLOSE,
      ],
    });

    screenSizeMonitor.listen('all', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.handleUpdateZoomLevel(this.zoomSettings.columns);
    this.emitChange();
  };

  handlePanelbarChange = () => {
    const isOpening = PanelbarStore.getState().isOpen;
    if (isOpening === this.panelbarIsOpen) return;
    this.panelbarIsOpen = isOpening;
    let newColumns = this.zoomSettings.columns;
    if (isOpening) {
      newColumns -= 1;
    } else {
      newColumns += 1;
    }
    this.handleUpdateZoomLevel(newColumns);
    this.emitChange();
  };

  handleUpdateZoomLevel(newColumns: number) {
    if (!isNumber(newColumns)) throw new Error('newColumns should be a number');
    if (newColumns !== this.zoomSettings.columns) {
      // Commit the new value to localStorage, even if it doesn't fit for now
      this.localState.setValue(newColumns);
    }
    let width = window.innerWidth;
    if (PanelbarStore.getState().isOpen) width -= PANEL_BAR_WIDTH;
    this.zoomSettings = getZoomSettings(newColumns, width);
    this.emitChange();
  }
}

(window as any).StoryboardZoomStore = alt.createStore(
  StoryboardZoomStore,
  'StoryboardZoomStore',
);
